import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import CustomerMainLayout from "../CustomerMainLayout";
import ENVIRONMENT from "../../../../environments";
import FileConstants from "../../../../FileConstants";
import _ from "lodash"; // Import the entire lodash library
import * as Utilities from "../../Utilities";
import TwoDImagesContext from "../../ContextFiles/TwoDImagesContext";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TwoDImagesHeader from "./TwoDImagesHeader";
import TwoDImagesTabs from "./TwoDImagesTabs";
import "./TwoDImages.scss";
import {
  GET_IMAGES,
  PORTAL_LINK,
  getBaseURL,
} from "../../../../environments/env";
import { useQuery } from "react-query";
import pako from "pako";

const COMPANY_ID = localStorage.getItem("company_id");
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem(
  "managed_customer_username"
);
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_SHARED_ENTITIES = localStorage.getItem("managed_shared_entites");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");

const initializeStateFromQueryParams = () => {
  const query = new URLSearchParams(window.location.search);
  const searchTerm = query.get("searchTerm") || "";
  const project = query.get("project") || "";
  const startDate = query.get("startDate") || "";
  const endDate = query.get("endDate") || "";
  const types = JSON.parse(query.get("types") || "[]");
  const type = query.get("type") || "all";

  return {
    searchTerm,
    startDate,
    endDate,
    types,
    project,
    type,
  };
};

const initialResolutionState = () => {
  const query = new URLSearchParams(window.location.search);
  return query.get("resolution") || "all";
};
const TwoDImagesPage = () => {
  const [resolution, setResolution] = useState(initialResolutionState());
  const [projects, setProjects] = useState([]);

  const [formState, setFormState] = useState({
    ...initializeStateFromQueryParams(),
  });

  const [Type, setType] = useState(initializeStateFromQueryParams().type);

  useEffect(() => {
    setFormState({
      ...formState,
      type: Type,
    });
  }, [Type]);

  // FETCHER FUNCTION
  const twoDImagesFetcher = useCallback((payload) => {
    return axios
      .post(GET_IMAGES, payload, {
        responseType: "json",
        headers: {
          "Accept-Encoding": "gzip",
        },
      })
      .then((res) => {
        const encodedData = res.data["body"];
        const compressedData = new Uint8Array(
          atob(encodedData)
            .split("")
            .map((c) => c.charCodeAt(0))
        );
        const decompressedData = pako.inflate(compressedData);
        const jsonData = JSON.parse(
          new TextDecoder("utf-8").decode(decompressedData)
        );
        return jsonData;
      })
      .catch((error) => console.error(error));
  }, []);

  const favoritesFetcher = useCallback((payload) => {
    return axios
      .post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload)
      .then((res) => res.data);
  }, []);

  const aiGeneratedRenders = useCallback((payload) => {
    return axios.post(ENVIRONMENT.AI_GENERATED_IMAGES, payload).then((res) => {
      console.log(res.data);

      return res.data.map((img) => {
        return {
          ...img,
          image_type: "ai",
          is360: img.threesixty_frames !== undefined,
          entity_id: img.product_id,
          platform: img.platform,
          entity_type: "product",
          image_name: img.id.toString() + ".jpg",
          threesixty_frames: img.threesixty_frames !== undefined ?img.threesixty_frames.data : null,
        };
      });
    });
  }, []);

  const allImagesPayload = useMemo(
    () => ({
      username:
        IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME
          ? MANAGED_CUSTOMER_USERNAME
          : CUSTOMER_USERNAME,
      company_id:
        IS_MS_PROVIDER && MANAGED_COMPANY_ID ? MANAGED_COMPANY_ID : COMPANY_ID,
      shared_entities:
        IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME
          ? MANAGED_SHARED_ENTITIES
          : SHARED_ENTITIES,
      image_type: "all",
    }),
    [CUSTOMER_USERNAME, COMPANY_ID, IS_MS_PROVIDER, MANAGED_CUSTOMER_USERNAME]
  );

  const aiGeneratedImagesPayload = useMemo(
    () => ({
      customer_username: CUSTOMER_USERNAME,
    }),
    [CUSTOMER_USERNAME]
  );
  const payloadFavorites = {
    action: "get",
    favorited_by: CUSTOMER_USERNAME,
  };

  const {
    data: favoriteImages,
    refetch: favoriteImagesRefetch,
    isLoading: imagesFavoriteLoading,
  } = useQuery(
    [`${ENVIRONMENT.FAVOURITES_CONTROLLER}`, payloadFavorites],
    () => favoritesFetcher(payloadFavorites),
    {
      enabled: !!payloadFavorites,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: aiGeneratedImages,
    refetch: aiImagesRefetech,
    isLoading: aiImagesLoading,
  } = useQuery(
    [`${ENVIRONMENT.AI_GENERATED_IMAGES}`, aiGeneratedImagesPayload],
    () => aiGeneratedRenders(aiGeneratedImagesPayload),
    {
      enabled: !!aiGeneratedImagesPayload,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: twoDImagesAll,
    refetch: twoDAllImagesRefetch,
    isLoading: imagesAllLoading,
  } = useQuery(
    [`${GET_IMAGES}/initial`, allImagesPayload],
    () => twoDImagesFetcher(allImagesPayload),
    {
      enabled: !!allImagesPayload,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const refetch = () => {
    favoriteImagesRefetch();
    twoDAllImagesRefetch();
  };

  const twoDImages = useMemo(() => twoDImagesAll, [twoDImagesAll]);

  const twoDImagesWithFavoriteFlag = useMemo(() => {
    const favoriteImagesMapping = favoriteImages?.reduce(
      (acc, obj) => ({ ...acc, [`${obj?.entity_id}_${obj.image_name}`]: obj }),
      {}
    );

    console.log("FAV IMAGES MAPPING: ", favoriteImagesMapping);

    const favoriteImagesResult = twoDImages?.map((obj) => {
      let filename;

      if (obj?.image_type === "lifestyle") filename = obj?.filename;
      else if (obj?.image_type === "silo")
        filename = `${obj?.camera_name}.${obj.image_format}`;
      else if (obj.image_type === "spin_360") {
        filename = `${obj?.entity_id}_Frame1.jpg`;
      } else if (obj.image_type === "mp4") {
        filename = `${obj?.entity_id}.mp4`;
      }

      const key = `${obj.entity_id}_${filename}`;

      return {
        ...obj,
        favourite: favoriteImagesMapping[key],
        favouriteId: favoriteImagesMapping[key]?.id,
        isFavourite: favoriteImagesMapping[key] ? true : false,
        favoritedOn: favoriteImagesMapping[key]?.favorited_on,
      };
    });

    const favoriteImagesResultAi = aiGeneratedImages?.map((obj) => {
      const key = `${obj.entity_id}_${obj.image_name}`;

      return {
        ...obj,
        favourite: favoriteImagesMapping[key],
        favouriteId: favoriteImagesMapping[key]?.id,
        isFavourite: favoriteImagesMapping[key] ? true : false,
        favoritedOn: favoriteImagesMapping[key]?.favorited_on,
      };
    });

    return [...(favoriteImagesResult || []), ...(favoriteImagesResultAi || [])];
  }, [twoDImages, favoriteImages, aiGeneratedImages]);

  const displayableImages = useMemo(() => {
    return twoDImagesWithFavoriteFlag;
  }, [twoDImagesWithFavoriteFlag]);

  useEffect(() => {
    console.log(
      "ALL IMAGES LAODING: ",
      imagesAllLoading,
      "ALL IMAGES RESULT: ",
      twoDImagesAll
    );
  }, [imagesAllLoading, twoDImagesAll]);

  useEffect(() => {
    getUserProjects();
  }, []);

  const markFavorite = (obj) => {
    console.log("MARK OBJECT: ", obj);

    const payload = {
      action: "add_favorite",
      entity_id: obj.entity_id,
      entity_type: obj.entity_type,
      image_type: obj.image_type,
      favorited_by: CUSTOMER_USERNAME,
    };

    if (obj?.image_type === "silo") {
      payload["image_name"] = `${obj.camera_name}.${obj.image_format}`;
    } else if (obj?.image_type === "lifestyle") {
      payload["image_name"] = obj?.filename;
    } else if (obj?.image_type === "mp4") {
      payload["image_name"] = obj?.entity_id + ".mp4";
    } else if (obj?.image_type === "spin_360") {
      payload["image_name"] = obj?.entity_id + "_Frame1.jpg";
    } else if (obj?.image_type === "ai") {
      payload["image_name"] = obj?.image_name;
    }
    axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload).then((res) => {
      console.log("RES: ", res);

      favoriteImagesRefetch(); //commenting it to avoid rerender as we do local update, todo: on faliure update local isFav of image
    });
  };

  const unmarkFavorite = (obj) => {
    console.log("MARK OBJECT: ", obj);

    const payload = {
      action: "remove_favorite",
      favorite_id: obj?.favouriteId,
      favorited_by: CUSTOMER_USERNAME,
    };
    axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload).then((res) => {
      console.log("RES: ", res);
      favoriteImagesRefetch(); //commenting it to avoid rerender as we do local update, todo: on faliure update local isFav of image
    });
  };

  const GetImageURL = (item, thumbnail = false) => {
    if (item.image_type == "silo") {
      if (
        item.image_status == "completed" &&
        (!("is_hidden" in item) || item.is_hidden !== true)
      ) {
        let silo_base_uri = "";
        let unique_attributer = "username";
        if (item.company_id) {
          silo_base_uri = "company/" + item.company_id;
          unique_attributer = "company";
        } else {
          silo_base_uri = item.username;
        }

        let imageUrl =
          ENVIRONMENT.getBaseURL(item["platform"]) +
          (thumbnail
            ? ENVIRONMENT.LOW_RES_QA_IMG_URI
            : ENVIRONMENT.QA_IMG_URI) +
          item["entity_id"] +
          "/" +
          silo_base_uri +
          "/" +
          item.camera_name +
          (thumbnail ? "_512" : "") +
          "." +
          item.image_format.replace("tiff", "jpg");
        return imageUrl;
      }
    }

    if (item.image_type === "ai") {
      if (item.is360) {
        let baseUrl = "";
        if (item.company_id) {
          baseUrl = "company/" + item.company_id;
        } else {
          baseUrl = item.captured_by ? item.captured_by : item.username;
        }

        let imageUrl =
          ENVIRONMENT.getBaseURL() +
          "product_assets/ai_generated_spin/" +
          `${item.product_id}/frames/` + 
          item.uri;
        return imageUrl;
      }
      return ENVIRONMENT.getBaseURL(item.platform) + item.uri;
    }

    if (item.image_type == "lifestyle") {
      if (thumbnail) {
        const parts = item.filename.split(".");
        const updatedFilename =
          parts.slice(0, -1).join(".") + "_512." + parts.at(-1);
        return (
          ENVIRONMENT.getBaseURL(item.platform) +
          ENVIRONMENT.SCENE_THUMBNAIL_URI +
          item.entity_id +
          "/" +
          updatedFilename
        ).replace("tiff", "jpg");
      } else {
        return (
          ENVIRONMENT.getBaseURL(item.platform) +
          ENVIRONMENT.SCENE_THUMBNAIL_URI +
          item.entity_id +
          "/" +
          item.filename
        ).replace("tiff", "jpg");
      }
    }

    if (item.image_type == "spin_360") {
      let baseUrl = "";
      if (item.company_id) {
        baseUrl = "company/" + item.company_id;
      } else {
        baseUrl = item.captured_by ? item.captured_by : item.customer_username;
      }

      let imageUrl =
        ENVIRONMENT.getBaseURL() +
        "product_assets/threesixtys_frames/" +
        `${baseUrl}/` +
        item.spin_360_frames[0];
      return imageUrl;
    }

    if (item.image_type == "mp4") {
      let baseUrl = "";
      if (item.company_id) {
        baseUrl = "company/" + item.company_id;
      } else {
        baseUrl = item.captured_by ? item.captured_by : item.customer_username;
      }

      let imageUrl =
        ENVIRONMENT.getBaseURL() +
        "product_assets/video/" +
        `${baseUrl}/` +
        item.entity_id +
        ".mp4";
      return imageUrl;
    }
  };

  const setImageType = (e) => {
    setType(e);
    console.log(e);
  };

  const getResolutionCategory = (image) => {
    if (image.height && image.width) {
      const maxDimension = Math.max(image.height, image.width);

      if (maxDimension <= 1024) {
        return "1k";
      } else if (maxDimension <= 2048) {
        return "2k";
      } else if (maxDimension <= 4096) {
        return "4k";
      } else {
        return "custom";
      }
    } else if (image.resolution) {
      if (image.resolution <= 1024) {
        return "1k";
      } else if (image.resolution <= 2048) {
        return "2k";
      } else if (image.resolution <= 4096) {
        return "4k";
      } else {
        return "custom";
      }
    } else if (
      image.spin_360_details &&
      image.spin_360_details.frame_resolution
    ) {
      if (parseInt(image.spin_360_details.frame_resolution) == 1) {
        return "1k";
      } else if (parseInt(image.spin_360_details.frame_resolution) == 2) {
        return "2k";
      } else if (parseInt(image.spin_360_details.frame_resolution) == 4) {
        return "4k";
      } else {
        return "custom";
      }
    }
  };

  const onChangeResolution = (e) => {
    const newResolution = e.target.value;
    setResolution(newResolution);
    // Get current URL parameters
    const params = new URLSearchParams(window.location.search);

    // Set the new resolution in the query parameters
    params.set("resolution", newResolution);

    // Update the URL without reloading
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  const getUserProjects = () => {
    //console.log('fgsf')
    let payload = {
      order_by: "customer_username",
      required_fields: ["id", "name", "customer_username"],
      filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
    };

    axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
      let projects = [];
      if (MANAGED_CUSTOMER_USERNAME) {
        projects = res.data.map((item) => ({
          ...item,
          name: `[${item.customer_username}] ${item.name} `,
        }));

        setProjects(projects);
      } else {
        setProjects(res.data);
      }
    });
    //console.log(projects);
  };

  return (
    <CustomerMainLayout selected="25">
      <TwoDImagesContext.Provider
        value={{
          resolution: resolution,
          setResolution: setResolution,
          getResolutionCategory: getResolutionCategory,
          projects: projects,
          setProjects: setProjects,
          Type: Type,
          setImageType: setImageType,
          onChangeResolution: onChangeResolution,
          formState: formState,
          setFormState: setFormState,
          images: displayableImages,
          GetImageURL: GetImageURL,
          markFavorite: markFavorite,
          unmarkFavorite: unmarkFavorite,
          imagesInitialLoading: imagesAllLoading,
          refetch: refetch,
        }}
      >
        <TwoDImagesHeader />
        <TwoDImagesTabs />
      </TwoDImagesContext.Provider>
    </CustomerMainLayout>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwoDImagesPage)
);
