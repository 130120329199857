import React, { useState, useEffect } from "react";
import {
  HeartOutlined,
  HeartFilled,
  PlayCircleFilled,
} from "@ant-design/icons";
import { Dropdown, Tooltip, Menu } from "antd";
import {
  getCollaborateImageFilepath,
  getCollaborationStatusTooltip,
} from "../../Utilities";
import FileConstants from "../../../../FileConstants";
import {
  GET_COMPLAINT_BATCH,
  PORTAL_LINK,
  getBaseURL,
} from "../../../../environments/env";
import aiSparkleIcon from "../../../../assets/images/aiSparkleGradient.png";
import AiProductBadge from "../AiProduct/AiProductBadge";

import "./TwoDImages.scss";

const ImageOverlay = ({
  obj,
  unmarkFavorite,
  markFavorite,
  openSingleFlagImage,
  onCollaborate,
  handleDownload,
  handleDeleteClick,
  handleOpenHighResModal,
  checkIfFlagIsDisabled,
  checkIfComplaintInProgress,
  getTooltipTitle,
  imgIndex,
  favouriteImages,
}) => {
  const [isFav, setIsFav] = useState(obj.isFavourite);

  useEffect(() => {
    if (favouriteImages.length === 0) {
      return;
    }
    setIsFav(favouriteImages.includes(obj.src));
  }, [favouriteImages]);

  const handleFavorite = () => {
    if (isFav) {
      unmarkFavorite(obj);
    } else {
      markFavorite(obj);
    }
    setIsFav(!isFav);
  };

  const getImgDisplayName = (render) => {
    if (render.camera_display_name) {
      return render.camera_display_name;
    }
    else if(render.display_name){
      return render.display_name;
    }
    return `Untitled ${render.filename?.split(".")[0]?.split("_")[1]}`;
  };

  const handleCollaborate = () => {
    let displayName = "";
    const base_url = getBaseURL(obj.platform);

    let cameraName = null;
    let collaborationType = "lifestyle";
    let render_path = null;
    let entityId = "";

    if (obj.image_type === "lifestyle") {
      entityId = obj.entity_id;
      cameraName = obj.filename;
      displayName = obj.display_name;
    } else {
      collaborationType = "silo";
      render_path = obj.src.split(base_url)[1];
      entityId = obj.entity_id;
      cameraName = obj.camera_name + "." + obj?.image_data.img_format;
    }

    onCollaborate(
      cameraName,
      displayName,
      entityId,
      collaborationType,
      render_path
    );
  };

  return (
    <div className="custom-overlay">
      {obj.image_type === "spin_360" ||
        (obj.is360 && (
          <img
            src={require("../../../../assets/images/view-360-circle.png")}
            className="view-360-icon"
            alt="360 view"
          />
        ))}
      <div className="fav-and-more-icons">
        {obj.image_type !== "spin_360" &&
          obj.image_type !== "mp4" &&
          obj.image_type !== "ai" && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      handleCollaborate();
                    }}
                  >
                    Collaborate
                  </Menu.Item>

                  <Menu.Item
                    key="2"
                    onClick={() => {
                      if (obj.image_type === "lifestyle") {
                        const cameraId = obj.filename.split(".")[0];
                        window.open(
                          `${PORTAL_LINK}scene_builder/${obj.entity_id}?camera=${cameraId}&origin=images`,
                          "_blank"
                        );
                      } else {
                        window.open(
                          PORTAL_LINK +
                            "silo-tool/" +
                            obj.entity_id +
                            "?camera_name=" +
                            obj.camera_name.split(".")[0],
                          "_blank"
                        );
                      }
                    }}
                  >
                    {obj.image_type === "lifestyle"
                      ? "Open in Scene Creator"
                      : "Open in Silo Tool"}
                  </Menu.Item>
                  {obj.image_type === "lifestyle" && (
                    <Menu.Item
                      key="3"
                      disabled={
                        !(
                          (obj.collaboration_id &&
                            obj.collaboration_resolved) ||
                          obj.collaboration_id === null
                        )
                      }
                      onClick={() => {
                        handleOpenHighResModal(obj);
                      }}
                    >
                      <Tooltip
                        title={
                          !(
                            (obj.collaboration_id &&
                              obj.collaboration_resolved) ||
                            obj.collaboration_id === null
                          )
                            ? "Collaboration in progress."
                            : "Generate Any High Res Image"
                        }
                      >
                        Generate Any High-Res Image
                      </Tooltip>
                    </Menu.Item>
                  )}
                  <Menu.Item key="4">
                    <a
                      onClick={() => {
                        handleDownload(obj.src, obj);
                      }}
                    >
                      Download
                    </a>
                  </Menu.Item>
                  {obj.image_type === "silo" ? (
                    <Menu.Item key="5" onClick={() => openSingleFlagImage(obj)}>
                      Report
                    </Menu.Item>
                  ) : obj.image_type === "lifestyle" ? (
                    <Menu.Item
                      key="4"
                      disabled={
                        checkIfComplaintInProgress(obj) ||
                        checkIfFlagIsDisabled(obj)
                      }
                      onClick={
                        !checkIfFlagIsDisabled(obj) ||
                        FileConstants.isMSProvider
                          ? () =>
                              checkIfComplaintInProgress(obj)
                                ? ""
                                : checkIfFlagIsDisabled(obj)
                                ? () => {}
                                : openSingleFlagImage(obj)
                          : () => {}
                      }
                    >
                      <Tooltip title={getTooltipTitle(obj)}>
                        <span className="menu-item-text">Report</span>
                      </Tooltip>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  <Menu.Item key="6">
                    <a
                      onClick={() => {
                        handleDeleteClick(obj, imgIndex);
                      }}
                    >
                      Delete
                    </a>
                  </Menu.Item>
                </Menu>
              }
              trigger={["hover"]}
              className="pointer-event-auto"
            >
              <div
                className="twod-image-info-more"
                onClick={(e) => e.preventDefault()}
              >
                <img src={"/img/more.svg"} />
              </div>
            </Dropdown>
          )}

          <div className={`pointer-event-auto img-fav-icon white`}>
            {isFav ? (
              <Tooltip
                title={<span className="manrope f-12 white">Unfavorite</span>}
              >
                <HeartFilled
                  style={{ fontSize: "19px" }}
                  onClick={(e) => {
                    console.log("here :");
                    e.stopPropagation();
                    handleFavorite();
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={<span className="manrope f-12 white">Favorite</span>}
              >
                <HeartOutlined
                  style={{ fontSize: "19px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavorite();
                  }}
                />
              </Tooltip>
            )}
          </div>
      </div>
      {obj.image_type == "lifestyle" || obj.image_type == "silo" ? (
        <Tooltip
          title={
            <span
              className="manrope f-12 white"
              dangerouslySetInnerHTML={{
                __html: getCollaborationStatusTooltip(obj),
              }}
            ></span>
          }
        >
          <div
            style={{ cursor: "pointer" }}
            className={`pointer-event-auto collaborate-icon ${
              obj.white_balanced_image != undefined ? `` : `right`
            } ${
              obj.collaboration_resolved
                ? "green"
                : obj.collaboration_id == undefined
                ? "white"
                : ""
            } `}
            onClick={(e) => {
              e.stopPropagation();
              handleCollaborate();
            }}
          >
            <div className="collaborate-img thumbnail-lifestyle display-flex">
              <img src={getCollaborateImageFilepath(obj)} />
              {obj.annotation_count > 0 && (
                <div
                  className={`ml-4 manrope f-12 ${
                    obj.collaboration_resolved
                      ? "white"
                      : obj.collaboration_id == undefined
                      ? "black-14"
                      : "white"
                  }`}
                >
                  {obj.annotation_count}
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      ) : (
        ""
      )}
      <div className="hover-info">
        <div className="image-banner">
          <Tooltip
            title={
              <span className="manrope f-12 white">
                {getImgDisplayName(obj)}
              </span>
            }
          >
            <p className="manrope f-12 w-700 lh-140 clamp-text w-100 image-banner-text justify-in-start">
              {getImgDisplayName(obj)}
            </p>
          </Tooltip>
          <p className="manrope f-12 image-banner-text">
            {obj.width && obj.height ? (
              <>
                {obj.width}x{obj.height}{" "}
              </>
            ) : null}
          </p>
        </div>
      </div>

      {obj.image_type === "mp4" && (
        <PlayCircleFilled
          style={{
            fontSize: "60px",
            opacity: "50%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        />
      )}
      { false &&
        <div className="ai-overlay">
          <AiProductBadge transparent/>
        </div>
      }
    </div>
  );
};
export default ImageOverlay;
