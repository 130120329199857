import React, { useEffect, useContext, useState, useMemo } from "react";
import { Modal, Button, Row, Col, Tabs, message, Dropdown, Menu } from "antd";
import ENVIRONMENT from "../../../../environments";
import FileConstants from "../../../../FileConstants";
import {
  getCollaborateImageFilepath,
  getCollaborationStatusTooltip,
  generateAndDownloadWatermarkedImage
} from "../../Utilities";
import axios from "axios";

import TwoDImagesContext from "../../ContextFiles/TwoDImagesContext";
import "./TwoDImages.scss";
import ProjectActionContext from "../../ContextFiles/ProjectActionContext";
import FlagSiloModal from "../ProductComponents/FlagSiloModal";
import GenerateHighResModal from "../CustomerProject/GenerateHighResModal";
import ComplaintModal from "../CustomerProject/ComplaintModal";
import TwoDImagesModal from "./TwoDImagesModal";
import ImageTabs from "./TabComponent";
import ImageOverlay from "./ImageOverlay";
import FavouriteIcon from "./FavouriteIcon";
import { LoadingOutlined } from "@ant-design/icons";
import {
  DownOutlined,
  FlagOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import {
  GET_IMAGES,
  PORTAL_LINK,
  WALMART_COMPANY_ID,
  getBaseURL,
} from "../../../../environments/env";
import { saveAs } from "file-saver";

const CUSTOMER_USERNAME = localStorage.getItem("username");
const COMPANY_ID = localStorage.getItem('company_id')

const TwoDImagesTabs = () => {
  const {
    GetImageURL,
    Type,
    setImageType,
    images,
    resolution,
    formState,
    getResolutionCategory,
    markFavorite,
    unmarkFavorite,
    imagesInitialLoading
  } = useContext(TwoDImagesContext);
  const [singleImage, setSingleImage] = useState(true);
  const [selectedSiloImage, setSelectedSiloImage] = useState(null);
  const [imageFlagModal, setImageFlagModal] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [filteredImages, setFilteredImages] = useState([]);
  const [collaborationModal, setCollaborationModal] = useState();
  const [deleteModal, setDeleteModal] = useState();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isCollaborationButtonLoading, setCollaborationButtonLoading] =
    useState(false);
  const [allImages, setAllImages] = useState(images);
  const [highResModalVisible, setHighResModalVisible] = useState(false);
  const [selectedLifestyleRender, setSelectedLifestyleRender] = useState(null);
  const [selectedSceneRenders, setSelectedSceneRenders] = useState([]);
  const [confirmHighResModal, setConfirmHighResModal] = useState(false);
  const [selectedImgComplaint, setSelectedImgComplaint] = useState(null);
  const [complaintModal, setComplaintModal] = useState(false);
  const [imgIndex, setImgIndex] = useState(-1);
  const [imagesCount, setImagesCount] = useState({
    all: 0,
    silo: 0,
    lifestyle: 0,
    spin_360: 0,
    mp4: 0,
    favourites: 0,
    ai: 0,
  });

  const [favouriteImages, setFavouriteImages] = useState([]);

  // // Convert Set to Array when rendering
  // const favouriteImagesArray = Array.from(favouriteImagesSet);

  // Add an image to the Set
  const addFavouriteImage = (image) => {
    setFavouriteImages((prevSet) => {
      const newSet = [...prevSet, image];
      return newSet;
    });
  };

  // Remove an image from the Set
  const removeFavouriteImage = (image) => {
    setFavouriteImages((prevSet) => {
      const newSet = prevSet.filter((item) => item !== image);
      return newSet;
    });
  };

  useEffect(() => {
    setAllImages(
      images?.map((image, index) => ({
        ...image,
        orignalIndex: index,
        src: GetImageURL(image),
        thumbnail:
          image.image_type == "lifestyle" || image.image_type == "silo"
            ? GetImageURL(image, true)
            : null,
      }))
    );
  }, [images]);

  useEffect(() => {
    // Set the favourite images as a Set of unique image sources (src)
    const favImages =
      allImages
        ?.filter((image) => image.src && image.isFavourite)
        .map((image) => image.src) || [];
    console.log("object fav:", favImages);
    setFavouriteImages(favImages);
  }, [allImages]);

  useEffect(() => {
    console.log("favouriteImages :", favouriteImages);
  }, [favouriteImages]);

  const getCollaborationDisplayName = (camera_name, display_name) => {
    let collab_display_name = display_name;
    if (!display_name) {
      collab_display_name =
        "Untitled " + camera_name.split(".")[0].split("_")[1];
    }
    return collab_display_name;
  };

  const collaborateConfirmModal = (
    camera_name,
    display_name,
    entity_id,
    collaboration_type,
    render_path = null
  ) => {
    let collaborationModalState = {
      camera_name: camera_name,
      display_name: getCollaborationDisplayName(camera_name, display_name),
    };
    if (collaboration_type == "lifestyle") {
      collaborationModalState["scene_id"] = entity_id;
    } else {
      collaborationModalState["product_id"] = entity_id;
      collaborationModalState["render_path"] = render_path;
    }
    setCollaborationModal(collaborationModalState);
  };

  const collaborateCloseConfirmModal = () => {
    setCollaborationModal(undefined);
    setCollaborationButtonLoading(false);
  };

  const createCollaborateEntity = () => {
    let payload = {
      ...collaborationModal,
      created_by: localStorage.getItem("username"),
      last_modified_by: localStorage.getItem("username"),
      state: {},
    };
    payload["is_msprovider"] = FileConstants.isMSProvider;

    axios
      .post(ENVIRONMENT.CREATE_COLLABORATE_ENTITY, payload)
      .then((res) => {
        if (res.data && res.data.body) {
          collaborateCloseConfirmModal();
          let collaboration_id = res.data.body.collaboration_entity_id;
          let url = "/collaborate-tool/" + collaboration_id;
          // if (projectId) {
          //   url = `${url}?origin=/projects/${projectId}&&project_id=${projectId}`;
          // }
          let new_window = window.open(url, "_blank");
          if (new_window) {
            new_window.focus();
          } else {
            window.location.href = url;
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        collaborateCloseConfirmModal();
      });
  };

  const openCollaborateTool = () => {
    setCollaborationButtonLoading(true);

    let payload = {
      ...collaborationModal,
    };
    if (payload["render_path"]) delete payload["render_path"];
    axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload).then((res) => {
      console.log("res", res);
      if (res.data && res.data.length == 0) {
        createCollaborateEntity();
      } else {
        collaborateCloseConfirmModal();
        let collaboration_id = res.data.collaboration_entity_id;
        let url = "/collaborate-tool/" + collaboration_id;
        // if (projectId) {
        //   url = `${url}?origin=/projects/${projectId}&&project_id=${projectId}`;
        // }

        let new_window = window.open(url, "_blank");
        if (new_window) {
          new_window.focus();
        } else {
          window.location.href = url;
        }
      }
    });
  };

  const handleDeleteImage = (indexToDelete) => {
    // This removes a specific image at the given Index, from the allImages state
    setAllImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(indexToDelete, 1);
      return updatedImages;
    });
  };

  const deleteImage = () => {
    if (!deleteModal) return;

    setDeleteLoader(true);
    const companyId = localStorage.getItem("company_id");

    if (deleteModal.image_type === "lifestyle") {
      // delete lifestyle image
      let payload = {
        scene_id: deleteModal?.entity_id,
        username: localStorage.getItem("username"),
        camera_name: deleteModal?.filename,
      };

      // Conditionally add companyId if it exists
      if (companyId) {
        payload.company_id = companyId;
      }

      axios
        .post(ENVIRONMENT.DELETE_LIFESTYLE, payload)
        .then((response) => {
          message.info("Image Deleted.");
          handleCancel(); // close the Modal
          if (imgIndex >= 0) {
            handleDeleteImage(imgIndex);
          }
        })
        .catch((error) => {
          message.error("Error in deleting Image");
        })
        .finally(() => {
          setDeleteModal(undefined);
          setDeleteLoader(false);
          setImgIndex(-1);
        });
    } else if (deleteModal.image_type === "silo") {
      // delete silo image
      let payload = {
        product_id: deleteModal?.entity_id,
        username: localStorage.getItem("username"),
        action: "delete",
        camera_name: deleteModal?.camera_name,
      };

      // Conditionally add companyId if it exists
      if (companyId) {
        payload.company_id = companyId;
      }

      axios
        .post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
        .then((response) => {
          message.info("Image Deleted.");
          handleCancel();
          if (imgIndex >= 0) {
            handleDeleteImage(imgIndex);
          }
        })
        .catch((error) => {
          message.error("Error in deleting Image");
        })
        .finally(() => {
          setDeleteModal(undefined);
          setDeleteLoader(false);
          setImgIndex(-1);
        });
    }
  };

  const handleDeleteClick = (image, imgIndex) => {
    setDeleteModal(image);
    if (imgIndex) setImgIndex(imgIndex);
  };

  const renderDelete = (x) => {
    if (
      !x ||
      (!x.filename && !x.camera_name) ||
      x.image_type === "spin_360" ||
      x.image_type === "mp4"
    )
      return null;

    return (
      <Button
        onClick={() => handleDeleteClick(x)}
        className="red-icon-button-container mr-8"
      >
        <DeleteOutlined />
      </Button>
    );
  };

  const handleOpenHighResModal = (x) => {
    let render = { ...x };
    render["scene_id"] = x.entity_id;
    render["request_link"] = x.src;
    delete render.entity_id;
    if (x && x.filename) {
      setSelectedLifestyleRender(render);
      let final_renders = allImages
        .filter(
          (item) =>
            item.entity_id == x.entity_id && item.image_type == "lifestyle"
        )
        .map((item) => ({ ...item }));
      let flagged_images_camera_name = [];
      final_renders.map((render) => {
        if (render.complaint_id && render.complaint_status == "pending") {
          flagged_images_camera_name.push(render.filename);
        }
      });

      // remove the following keys from the final_renders dic, so that when updating the renders in DB we don't store useless info
      final_renders.forEach((obj) => {
        delete obj.collaboration_resolved;
        delete obj.collaboration_id;
        delete obj.annotation_count;
        delete obj.complaint_id;
        delete obj.complaint_status;
        delete obj.entity_id;
        delete obj.image_type;
        delete obj.entity_type;
      });
      setSelectedSceneRenders(final_renders);
      if (!flagged_images_camera_name.includes(x.filename)) {
        setHighResModalVisible(true);
      } else {
        // Complaint in progress, confirm if user want to generate a high Res.
        setConfirmHighResModal(true);
      }
    }
  };

  const renderGenerateHighResImage = (x) => {
    if (!x || !x.filename) return null;

    const isEditable =
      (x.collaboration_id && x.collaboration_resolved) ||
      x.collaboration_id === null;

    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        <Tooltip
          title={
            <span className="manrope f-12 white">
              {!isEditable
                ? "Can't Generate high res image as a collaboration is in progress."
                : "Generate Any High Res Image"}
            </span>
          }
        >
          <span>
            <Button
              className="modal-okay square font-16 pd-6-14 h-40"
              onClick={() => handleOpenHighResModal(x)}
              disabled={!isEditable}
              style={{
                cursor: isEditable ? "pointer" : "not-allowed",
                marginLeft: 15,
              }}
              data-tut="reactour__stil_images_button"
            >
              <img
                src="/img/carbon_cloud-download.png"
                style={{ marginRight: 5 }}
              ></img>
              Generate Any High-Res Image
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  };

  const openSingleFlagImage = (image) => {
    if (image.image_type === "silo") {
      setSingleImage(true);
      setSelectedSiloImage(image);
      setImageFlagModal(true);
    } else if (image.image_type === "lifestyle") {
      // open lifestyle image modal
      let selectedImg = { ...image };
      selectedImg["scene_id"] = image.entity_id;
      setSelectedImgComplaint(selectedImg);
      setComplaintModal(true);
      let lifestyle_renders = allImages.filter(
        (item) =>
          item.image_type === "lifestyle" && item.entity_id == image.entity_id
      );
      setSelectedSceneRenders(lifestyle_renders);
    }
  };

  const checkIfComplaintInProgress = (data) => {
    if (
      !data.complaint_status ||
      (data.complaint_status &&
        data.complaint_status != "rejected" &&
        data.complaint_status != "resolved")
    ) {
      return false;
    }
    return true;
  };

  const checkIfFlagIsDisabled = (x) => {
    if (FileConstants.isMSProvider) {
      if (x.flagged_by_user !== undefined) {
        return true;
      }
    } else if (x.flagged_by_user !== undefined) {
      if (x.generated_by_admin && x.captured_by != CUSTOMER_USERNAME) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleOk = () => {
    setisModalVisible(false);
  };

  const handleCancel = () => {
    setisModalVisible(false);
  };

  const renderReportButton = (x) => {
    if (x.image_type === "silo") {
      return (
        <span>
          <Button
            className="red-icon-button-container mr-8"
            onClick={() => openSingleFlagImage(x)}
          >
            <FlagOutlined />
          </Button>
        </span>
      );
    } else if (x.image_type === "lifestyle") {
      const isFlagDisabled =
        checkIfComplaintInProgress(x) || checkIfFlagIsDisabled(x);

      return (
        <Tooltip title={getTooltipTitle(x)}>
          <span>
            <Button
              className="red-icon-button-container mr-8"
              onClick={
                !checkIfFlagIsDisabled(x) || FileConstants.isMSProvider
                  ? () =>
                      checkIfComplaintInProgress(x)
                        ? ""
                        : checkIfFlagIsDisabled(x)
                        ? () => {}
                        : openSingleFlagImage(x)
                  : () => {}
              }
              disabled={isFlagDisabled}
            >
              <FlagOutlined />
            </Button>
          </span>
        </Tooltip>
      );
    }
  };

  const getTooltipTitle = (x) => {
    const isFlagDisabled = checkIfFlagIsDisabled(x);

    if (!isFlagDisabled || FileConstants.isMSProvider) {
      if (checkIfComplaintInProgress(x)) {
        return "You can't flag this image unless your complaint is resolved.";
      } else if (isFlagDisabled) {
        return "You cannot flag this image";
      }
    } else {
      return "You cannot flag more than once";
    }
  };

  const handleMarkFav = (obj) => {
    markFavorite(obj);
    addFavouriteImage(obj.src);
  };

  const handleUnmarkFav = (obj) => {
    unmarkFavorite(obj);
    removeFavouriteImage(obj.src);
  };

  function downloadFile(url, filename) {
    let loader = message.loading("Preparing to download..", 0);

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Cache-Control", "no-cache");
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status == 200) {
        setTimeout(loader);

        var myBlob = this.response;
        saveAs(myBlob, filename);
      }
    };
    xhr.send();
  }

  const handleDownload = (src, obj) => {
    let downloadLink = src;
    let img_format = obj.filename.split('.').pop()

    if(img_format == 'tiff'){
      downloadLink = downloadLink.replace('.jpg', '.tiff')
    }
    if (downloadLink.includes(".tiff")) {
      downloadLink = downloadLink.replace(".jpg", ".tiff");
    }

    let display_name = obj.camera_display_name
      ? obj.camera_display_name
      : obj.display_name
      ? obj.display_name
      : "Untitled";
    if (img_format) {
      display_name = display_name + "." + img_format;
    }
    downloadFile(downloadLink, display_name);
  };

  const calculateCount = (array) => {
    // Initialize counts for each category
    const counts = array.reduce(
      (counts, image) => {
        if (image.image_type === "silo") counts.silo++;
        if (image.image_type === "lifestyle") counts.lifestyle++;
        if (image.image_type === "spin_360") counts.spin_360++;
        if (image.image_type === "mp4") counts.mp4++;
        if (image.image_type === "ai") counts.ai++;
        if (image.isFavourite) counts.favourites++; // Count favourites if `isFavourite` is true
        counts.all++; // Increment total count

        return counts;
      },
      {
        all: 0,
        silo: 0,
        lifestyle: 0,
        spin_360: 0,
        mp4: 0,
        favourites: 0,
        ai: 0,
      }
    );

    // Set the state with the final counts
    setImagesCount(counts); // Assuming setImagesCount is already available in the scope
  };

  const renderFavouriteButton = (obj) => {
    if (obj.image_type === "ai") {
      return null;
    }
    return (
      <FavouriteIcon
        obj={obj}
        handleUnmarkFav={handleUnmarkFav}
        handleMarkFav={handleMarkFav}
        favouriteImages={favouriteImages}
      />
    );
  };

  const filterImages = () => {
    // console.log("formState :", formState);
    // console.log("images :", allImages);
    if (!allImages) return;

    const array = allImages.map((obj, index) => {
      // Set width and height based on image type and frame resolution
      if (obj.image_type !== "spin_360") {
        obj.width = obj.image_width;
        obj.height = obj.image_height;
      }

      if (obj.image_type === "mp4") {
        obj.mp4Preview =
          ENVIRONMENT.getBaseURL(obj.platform) +
          "product_assets/perspective_renders/" +
          obj.entity_id +
          "_HeadOn.jpg";
      }

      if (obj.image_type === "ai") {
        obj.width = 1024;
        obj.height = 1024;
      }

      // Set display name if not defined
      obj.display_name =
        obj.display_name ||
        (obj?.filename
          ? `Untitled ${obj?.filename?.split(".")[0]?.split("_")[1]}`
          : `Untitled`);

      // Construct custom overlay

      obj.customOverlay = (
        <ImageOverlay
          obj={obj}
          imgIndex={index}
          unmarkFavorite={handleUnmarkFav}
          markFavorite={handleMarkFav}
          openSingleFlagImage={openSingleFlagImage}
          onCollaborate={collaborateConfirmModal}
          handleDownload={handleDownload}
          handleDeleteClick={handleDeleteClick}
          handleOpenHighResModal={handleOpenHighResModal}
          checkIfComplaintInProgress={checkIfComplaintInProgress}
          checkIfFlagIsDisabled={checkIfFlagIsDisabled}
          getTooltipTitle={getTooltipTitle}
          favouriteImages={favouriteImages}
        />
      );

      return obj;
    });
    const { startDate, endDate, searchTerm, types, project } = formState;
    const selectedResolution = resolution;

    // Convert startDate and endDate to timestamps (milliseconds since epoch)
    const startTimestamp = startDate ? new Date(startDate).getTime() : null;
    const endTimestamp = endDate ? new Date(endDate).getTime() : null;

    const result = array.filter((image, index) => {
      if (index === 0) {
        console.log("image :", image.status === "pending");
      }
      const resolutionMatches =
        selectedResolution === "all" ||
        selectedResolution === getResolutionCategory(image);

      const nameMatches = image.display_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      // Check if time_updated lies within the selected date range
      const timeUpdated = image.time_updated;

      const dateMatches =
        startTimestamp !== null && endTimestamp !== null
          ? timeUpdated >= startTimestamp && timeUpdated <= endTimestamp
          : true; // Ignore date filtering if no start and end date are selected

      const typesMatch =
        types.length > 0
          ? types.includes(image.image_type) ||
            (types.includes("favourites") && image.isFavourite)
          : true;

      const isMp4VideoPending =
        image.image_type === "mp4" ? image.asset_status !== "pending" : true;

      const is360RenderComplete =
        image.image_type === "spin_360"
          ? image?.spin_360_details?.asset_status === "completed"
          : true;

      const projectInt =
        project && !Number.isInteger(project) ? parseInt(project, 10) : project;

      const projectFilter = projectInt
        ? image.project_ids?.includes(projectInt)
        : true;

      if (
        (image.image_type === "silo" || image.image_type === "lifestyle") &&
        image.is_hidden
      ) {
        return false;
      }

      const aiImageStatus =
        image.image_type === "ai" ? image.status === "completed" : true;

      const imageStatusCheck =
        image.image_type === "silo" || image.image_type === "lifestyle"
          ? (image.status &&
              image.status !== "pending" &&
              image.status !== "hidden") ||
            (image.image_status && image.image_status !== "pending")
          : true;

      const complaintStatusCheck =
        image.image_type === "lifestyle"
          ? image.complaint_status !== "resolved"
          : true;
      const templateRenderCheck =
        image.image_type === "lifestyle" ? !image.template_render : true;

      return (
        imageStatusCheck &&
        image.filename !== "360Camera.jpg" &&
        resolutionMatches &&
        nameMatches &&
        dateMatches &&
        typesMatch &&
        isMp4VideoPending &&
        is360RenderComplete &&
        projectFilter &&
        complaintStatusCheck &&
        templateRenderCheck &&
        aiImageStatus
      );
    });

    calculateCount(result);

    return result;
  };

  const imagesFiltered = useMemo(
    () => filterImages(),
    [Type, allImages, resolution, formState]
  );

  useEffect(() => {
    if (imagesFiltered) {
      const updatedWithFavFlag = imagesFiltered.map((image) => {
        return {
          ...image,
          isFavourite:
            favouriteImages.findIndex((favImage) => favImage === image.src) !==
            -1,
        };
      });
      calculateCount(updatedWithFavFlag);
    }
  }, [favouriteImages, imagesFiltered]);

  useEffect(() => {
    if (imagesFiltered) {
      console.log("here :", Type);
      const imagesWithIndex = imagesFiltered
        .map((image, index) => ({
          ...image,
          index,
        }))
        .filter((image) => {
          const isFav =
            Type === "favourites" &&
            (favouriteImages.length > 0
              ? favouriteImages.includes(image.src)
              : image.isFavourite);
          const typeFilter =
            Type === "all" || isFav ? true : image.image_type === Type;

          return typeFilter;
        });

      console.log("formState here :", imagesWithIndex);

      setFilteredImages([...imagesWithIndex]);
    }
  }, [imagesFiltered, Type]);

  const showModal = (src) => {
    setSelectedImage(
      filteredImages.find((image) =>
        image.image_type == "mp4"
          ? image?.mp4Preview === src
          : image.src === src
      )
    );
    setisModalVisible(true);
    let index = allImages.findIndex((image) =>
      image.image_type == "mp4" ? image?.mp4Preview === src : image.src === src
    );
    setImgIndex(index);
  };

  const handleCollaborate = (obj) => {
    let displayName = "";

    let cameraName = null;
    let entityId = "";
    let renderPath = "";
    const base_url = getBaseURL(obj.platform);

    if (obj.image_type === "lifestyle") {
      entityId = obj.entity_id;
      cameraName = obj.filename;
      displayName = obj.display_name;
    } else {
      entityId = obj.entity_id;
      cameraName = obj.camera_name + "." + obj?.image_data.img_format;
      displayName = obj.camera_display_name;
      renderPath = obj.src.split(base_url)[1];
    }

    collaborateConfirmModal(
      cameraName,
      displayName,
      entityId,
      obj.image_type,
      renderPath
    );
  };

  const renderCollaborationTooltip = (image) => {
    if (
      image.image_type === "spin_360" ||
      image.image_type === "mp4" ||
      image.image_type === "ai"
    ) {
      return null;
    }

    return (
      <Tooltip
        title={
          <span
            className="manrope f-12 white"
            dangerouslySetInnerHTML={{
              __html: getCollaborationStatusTooltip(image),
            }}
          ></span>
        }
      >
        <div
          className={`lifestyle-image-collaborate-icon ${
            image.white_balanced_image != undefined ? `` : `right`
          } ${
            image.collaboration_resolved
              ? "green"
              : image.collaboration_id == undefined
              ? "white border"
              : ""
          }`}
          onClick={() => handleCollaborate(image)}
        >
          <img
            className="collaborate-img"
            src={getCollaborateImageFilepath(image)}
            alt="Collaboration Icon"
          />
          <span
            className={`manrope f-14 w-500 ${
              image.collaboration_resolved ||
              image.collaboration_id != undefined
                ? "text-white"
                : ""
            }`}
          >
            Collaborate
          </span>
        </div>
      </Tooltip>
    );
  };

  const renderDownload = (src, obj) => {
    if (obj.image_type === "silo" || (COMPANY_ID != WALMART_COMPANY_ID && obj.image_type === "lifestyle")) {
      return (
        <span>
          <Button
            className="modal-okay square font-16 h-40 "
            data-tut="reactour__stil_images_button"
            onClick={() => handleDownload(src, obj)}
          >
            Download
          </Button>
        </span>
      );
    }
    else if(COMPANY_ID == WALMART_COMPANY_ID && obj.image_type === "lifestyle"){
      let downloadLink = src;
      
      let img_format = obj.filename.split('.').pop()
      if(img_format == 'tiff'){
        downloadLink = downloadLink.replace('.jpg', '.tiff')
      }

      let display_name = obj.camera_display_name
        ? obj.camera_display_name
        : obj.display_name
        ? obj.display_name
        : "Untitled";
      if(img_format){
        display_name = display_name + '.' + img_format
      }
      return (<Dropdown overlay={(<Menu style={{ width: "165px" }}>
        <Menu.Item key={1}>
            <a className='manrope f-12' target="_blank" onClick={() => handleDownload(src, obj)}> This Image </a>
        </Menu.Item>
        <Menu.Item key={2}>
            <a className='manrope f-12' target="_blank" onClick={() => generateAndDownloadWatermarkedImage(downloadLink, display_name)}>
              Watermarked Image 
            </a>
        </Menu.Item>

        </Menu>)} trigger={['click']}>
            <Button
                className="modal-okay square font-16 h-40 "
                data-tut="reactour__stil_images_button"
            >
                Download
                <DownOutlined />
            </Button>
        </Dropdown>)
    }
  };

  return (
    <Row>
      <Col span={24}>
        <ImageTabs
          filteredImages={filteredImages}
          imagesInitialLoading={imagesInitialLoading}
          showModal={showModal}
          setImageType={setImageType}
          Type={Type}
          imagesCount={imagesCount}
        />
        {selectedSiloImage && (
          <ProjectActionContext.Provider
            value={{
              context_type: "project",
              product_id: selectedSiloImage?.entity_id,
              companyId: selectedSiloImage.company_id,
              imageFlagModal: imageFlagModal,
              selectedImageForFlag: selectedSiloImage.src,
              setImageFlagModal: setImageFlagModal,
              cognito_username: selectedSiloImage.captured_by,
              setSingleImage: setSingleImage,
              singleImage: singleImage,
              silos: filteredImages?.filter(
                (image) =>
                  image.image_type === "silo" &&
                  image.entity_id == selectedSiloImage.entity_id
              ),
              selectedSiloImage: selectedSiloImage,
            }}
          >
            <FlagSiloModal context_type={"project"} />
          </ProjectActionContext.Provider>
        )}

        {
          <Modal
            className="modal-space"
            destroyOnClose={true}
            width={700}
            visible={collaborationModal !== undefined}
            closable={false}
            bodyStyle={{ padding: 0 }}
            footer={[
              <span className="justify-in-end">
                <Button
                  disabled={isCollaborationButtonLoading}
                  style={{ textAlign: "center" }}
                  className="modal-okay-gray square font-12"
                  onClick={collaborateCloseConfirmModal}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isCollaborationButtonLoading}
                  style={{ textAlign: "center" }}
                  className="modal-okay square font-12"
                  onClick={openCollaborateTool}
                >
                  {isCollaborationButtonLoading ? (
                    <span>
                      Please wait.. <LoadingOutlined spin />
                    </span>
                  ) : (
                    <span>Go to tool </span>
                  )}
                </Button>
              </span>,
            ]}
          >
            <div style={{ padding: 30, display: "flex" }}>
              <Col span={24}>
                <h2 className="modal-heading manrope f-14 black-00 w-600">
                  Go to collaborate tool?{" "}
                </h2>
                <p className="manrope f-12 black-33">
                  This tool will allow you to post any feedback you have for
                  this render.{" "}
                </p>
              </Col>
            </div>
          </Modal>
        }

        {
          <Modal
            destroyOnClose={true}
            className="modal-share-success"
            width={600}
            visible={deleteModal}
            closable={false}
            footer={[
              <span className="justify-in-center">
                <Button
                  style={{ textAlign: "center" }}
                  disabled={deleteLoader}
                  className="modal-okay-gray square font-14"
                  onClick={() => setDeleteModal(undefined)}
                >
                  Cancel
                </Button>
                <Button
                  style={{ textAlign: "center" }}
                  disabled={deleteLoader}
                  className="modal-okay square font-14"
                  onClick={() => deleteImage()}
                >
                  {deleteLoader ? (
                    <span>
                      Deleting <LoadingOutlined spin />
                    </span>
                  ) : (
                    <span> Okay </span>
                  )}
                </Button>
              </span>,
            ]}
            centered={true}
          >
            <div
              style={{
                margin: "0px auto 0px auto",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h2 className="manrope f-16 black-00 w-600">
                  You're about to delete a{" "}
                  {deleteModal?.image_type == "lifestyle"
                    ? "Lifestyle"
                    : "Silo"}{" "}
                  Image
                </h2>
                <p className="manrope f-14 black-55">
                  This will remove your{" "}
                  {deleteModal?.image_type == "lifestyle"
                    ? "lifestyle"
                    : "silo"}{" "}
                  image. Are you sure you want to delete?
                </p>
              </div>
            </div>
          </Modal>
        }

        {
          <GenerateHighResModal
            confirmHighResModal={confirmHighResModal}
            setConfirmHighResModal={setConfirmHighResModal}
            renders={selectedSceneRenders}
            setModalVisible={setHighResModalVisible}
            modalVisible={highResModalVisible}
            selectedRender={selectedLifestyleRender}
          />
        }
        {
          <ComplaintModal
            selectedImgComplaint={selectedImgComplaint}
            setSelectedImgComplaint={setSelectedImgComplaint}
            complaintModal={complaintModal}
            setComplaintModal={setComplaintModal}
            renders={selectedSceneRenders}
            checkIfComplaintInProgress={checkIfComplaintInProgress}
            checkIfFlagIsDisabled={checkIfFlagIsDisabled}
          />
        }

        {
          <TwoDImagesModal
            key={isModalVisible ? "open" : "closed"}
            isVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            currentfile={selectedImage}
            filteredImages={filteredImages}
            renderDelete={renderDelete}
            renderDownload={renderDownload}
            renderGenerateHighResImage={renderGenerateHighResImage}
            setType={setImageType}
            Type={Type}
            imagesCount={imagesCount}
            renderCollaborationTooltip={renderCollaborationTooltip}
            renderReportButton={renderReportButton}
            renderFavouriteButton={renderFavouriteButton}
          />
        }
      </Col>
    </Row>
  );
};
export default TwoDImagesTabs;
