import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  CheckOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Popover, Dropdown, Space, Input, Menu } from "antd";
import "./index.scss";
import FileUpload from "../UploadAiModel/Upload";
import aiSparkleIcon from "../../../assets/images/aiSparkleIcon.svg";
import FileConstants from "../../../FileConstants";
import ENVIRONMENT from "../../../environments";
import AiModelGenerationContext from "../ContextFiles/AiModelGenerationContext";

const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const InitialModelViewer = ({ productId, modelRef }) => {
  const [currentHorizontalAngle, setCurrentHorizontalAngle] = useState(0);
  const [currentVerticalAngle, setCurrentVerticalAngle] = useState(75);

  if (!productId) {
    return null;
  }

  const rotateLeft = () => {
    const viewer = modelRef.current;
    if (viewer) {
      const newAngle = currentHorizontalAngle + 30;
      setCurrentHorizontalAngle(newAngle);
      const orbit = viewer.getCameraOrbit();
      viewer.cameraOrbit = `${newAngle}deg ${currentVerticalAngle}deg ${orbit.radius}m`;
    }
  };

  const rotateRight = () => {
    const viewer = modelRef.current;
    if (viewer) {
      const newAngle = currentHorizontalAngle - 30;
      setCurrentHorizontalAngle(newAngle);
      const orbit = viewer.getCameraOrbit();
      viewer.cameraOrbit = `${newAngle}deg ${currentVerticalAngle}deg ${orbit.radius}m`;
    }
  };

  const rotateUp = () => {
    const viewer = modelRef.current;
    if (viewer) {
      if (currentVerticalAngle >= 165) {
        return;
      }
      const newAngle = currentVerticalAngle + 30;
      setCurrentVerticalAngle(newAngle);
      const orbit = viewer.getCameraOrbit();
      viewer.cameraOrbit = `${currentHorizontalAngle}deg ${newAngle}deg ${orbit.radius}m`;
    }
  };

  const rotateDown = () => {
    const viewer = modelRef.current;
    if (viewer) {
      if (currentVerticalAngle <= 15) {
        return;
      }
      const newAngle = currentVerticalAngle - 30;
      setCurrentVerticalAngle(newAngle);
      const orbit = viewer.getCameraOrbit();
      viewer.cameraOrbit = `${currentHorizontalAngle}deg ${newAngle}deg ${orbit.radius}m`;
    }
  };

  let policy = "always-allow";
  let loaderProps = {};
  loaderProps = {
    loading: "eager",
  };

  return (
    <React.Fragment>
      <div className="model-viewer-container">
        <model-viewer
          ref={modelRef}
          environment-image="neutral"
          class={"disablehover model-viewer-class "}
          {...loaderProps}
          ar
          data-js-focus-visible
          shadow-intensity={1}
          interaction-policy={policy}
          src={ ENVIRONMENT.getBaseURL('aws') + `product_assets/ai_generated_glb_low/${productId}.glb`}
          alt="Ai Model"
          camera-controls
          interaction-prompt="none"
          background-color="#FFFFFF"
        ></model-viewer>
        <div className="rotation-controls">
          <Button
            className="left"
            onClick={rotateLeft}
            icon={<ArrowLeftOutlined />}
          />
          <Button
            className="right"
            onClick={rotateRight}
            icon={<ArrowRightOutlined />}
          />
          <Button
            className="top"
            onClick={rotateUp}
            icon={<ArrowUpOutlined />}
          />
          <Button
            className="bottom"
            onClick={rotateDown}
            icon={<ArrowDownOutlined />}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const EnhanceModel = () => {
  const [savedViews, setSavedViews] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState("inches");
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [depth, setDepth] = useState(null);
  const [productName, setProductName] = useState("");
  const [uploadStep, setUploadStep] = useState(1);
  const glbModelRef = React.useRef();
  const {
    setIsLoading,
    setHeading,
    isMobile,
    productId,
    setStepValue,
    clearUrlParams,
    setProductIdValue
  } = useContext(AiModelGenerationContext);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSelectedUnit("inches")}>inches</Menu.Item>
      <Menu.Item onClick={() => setSelectedUnit("meters")}>meters</Menu.Item>
      <Menu.Item onClick={() => setSelectedUnit("centimeters")}> centimeters</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!productId) {
      return;
    }
    const modelViewer = document.querySelector("model-viewer");

    modelViewer.addEventListener("load", async () => {
      const scene = await modelViewer.scene; // Access the scene
      console.log("scene", scene, modelViewer);
      if (scene) {
        const rootNode = scene.root; // Get the root node

        // Function to get the position at any moment
        function getPosition(node) {
          const worldMatrix = node.getWorldMatrix(); // Get world transformation matrix
          return {
            x: worldMatrix[12],
            y: worldMatrix[13],
            z: worldMatrix[14],
          };
        }

        // Get the initial position of the model
        const initialPosition = getPosition(rootNode);
        console.log("Initial Position:", initialPosition);

        // Example: Update position dynamically
        modelViewer.addEventListener("frame-rendered", () => {
          const currentPosition = getPosition(rootNode);
          console.log("Current Position:", currentPosition);
        });
      }
    });
  }, [productId]);

  const requestRenderforModel = () => {
    const product_id = productId;
    if (!product_id) {
      return;
    }
    axios.post(ENVIRONMENT.AI_MODEL_HELPER, {
      username: CUSTOMER_USERNAME,
      action: "trigger_lifestyle_generation_job",
      final_glb_url: ENVIRONMENT.getBaseURL('aws') + `product_assets/ai_generated_glb/${productId}.glb`,
      product_id: parseInt(product_id),
    });
  };

  const saveView = (imageUrl, angle) => {
    setSavedViews([...savedViews, { image_url : imageUrl, rotation: getRotationMatrix() }]);
    setImage(null);
    setUploadStep(1);
  };

  const handleGenerateAssets = async () => {
    setIsLoading(true);
    setHeading("Generating your Assets");
    await updateProduct();
    handleFineTunedModel();
    requestRenderforModel();
    setTimeout(() => {
      setIsLoading(false);
      setStepValue(3);
    }, 5000);
  };

  const handleFineTunedModel = () => {
    axios.post(ENVIRONMENT.AI_MODEL_HELPER, {
      'action': 'trigger_fine_tuned_model_job',
      'product_id': productId,
      'username': CUSTOMER_USERNAME,
      'post_matched_images': savedViews,
      'height': parseFloat(height)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const updateProduct = async () => {
    let widthValue = width;
    let heightValue = height;
    let depthValue = depth;

    if (!widthValue && !heightValue && !depthValue && !productName) { 
      return;
    }

    const updatePayload = {};
    if (productName) {
      updatePayload.product_name = productName;
    }
    if (widthValue && heightValue && depthValue) {
      updatePayload.width = widthValue;
      updatePayload.height = heightValue;
      updatePayload.length = depthValue;

      if (selectedUnit == "meters") {
        updatePayload.width = parseFloat(width) * 39.3701;
        updatePayload.height = parseFloat(height) * 39.3701;
        updatePayload.length = parseFloat(depth) * 39.3701;
      } else if (selectedUnit == "centimeters") {
        updatePayload.width = parseFloat(width) * 0.393701;
        updatePayload.height = parseFloat(height) * 0.393701;
        updatePayload.length = parseFloat(depth) * 0.393701;
      }
    }
    
    axios.post(ENVIRONMENT.UPDATE_PRODUCT, {
      product_id: productId,
      ...updatePayload,
    });
  };

  const handleBack = () => {
    setStepValue(1);
    setProductIdValue(null);
    clearUrlParams();
  };

  const getRotationMatrix = () => {
    const viewer = glbModelRef.current;
    const cameraTarget = viewer.getCameraTarget();
    const finalOrbit = viewer.getCameraOrbit();
    const matrix = computeMatrix(finalOrbit, cameraTarget);
    return matrix;
  }
  
  const computeMatrix = (cameraOrbit, cameraTarget) => {
    const { theta, phi, radius } = cameraOrbit;
    const target = cameraTarget;

    const camX = radius * Math.sin(phi) * Math.cos(theta);
    const camY = radius * Math.sin(phi) * Math.sin(theta);
    const camZ = radius * Math.cos(phi);

    const cameraPos = [camX, camY, camZ];
    const forward = normalize([
      cameraPos[0] - target.x,
      cameraPos[1] - target.y,
      cameraPos[2] - target.z,
    ]);

    const right = normalize(cross(forward, [0, 1, 0]));
    const up = cross(right, forward);

    return [
      [right[0], up[0], forward[0]],
      [right[1], up[1], forward[1]],
      [right[2], up[2], forward[2]],
    ];
  }

  const normalize = (v) => {
    const len = Math.sqrt(v[0] ** 2 + v[1] ** 2 + v[2] ** 2);
    return v.map((val) => val / len);
  }

  const cross = (a, b) => {
    return [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0],
    ];
  }

  return (
    <div className="upload-model-image-container pb-100">
      <div className="upload-model-image-content">
        <div className="step-tiltle-container">
          <div className="step-number">2</div>
          <span className="step-title">Enhance your Model</span>
        </div>
        <span className="step-header">
          Upload and Match Multiple Product Views to Enhance Your Model to
          improve product accuracy.
        </span>
        <div>
          <div className="dimnesion-item">
            <div className="width-full">
              <span className="dimnesion-item-label">Product Name</span>
              <Input
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Enter your product name"
                className="dimnesion-item-input"
              />
            </div>
          </div>
          <div className="dimnesion-item width-full">
            <span className="dimnesion-item-label">Dimensions</span>
            <Dropdown
              overlay={menu}
              onSelect={(value) => setSelectedUnit(value)}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {selectedUnit}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="dimnesion-item">
            <div className="width-30">
              <Input
                placeholder="Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="dimnesion-item-input"
              />
            </div>
            <div className="width-30">
              <Input
                placeholder="Width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="dimnesion-item-input"
              />
            </div>
            <div className="width-30">
              <Input
                placeholder="Depth"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="dimnesion-item-input"
              />
            </div>
          </div>
        </div>
        <div className="model-enhance-container">
          <div className="side-by-side-container">
            {isMobile && uploadStep == 2 ? (
              ""
            ) : (
              <div className="model-section">
                <div className="flex-between">
                  <div className="flex-between">
                    <div className="step-number step-number-success">
                      <CheckOutlined />
                    </div>
                    <span className="step-header-text">
                      Position your 3D model
                    </span>
                    <Popover
                      placement="bottom"
                      content={
                        <div className="tips-popover">
                          <p className="tips-item">
                            We use untextured or lower-quality 3D models
                            initially for quicker previews. Your final assets
                            will show the photorealistic, textured 3D model.
                          </p>
                        </div>
                      }
                    >
                      <InfoCircleOutlined className="info-icon" />
                    </Popover>
                  </div>
                </div>
                <span className="section-subheading">
                  Spin model below to match view uploaded to left.
                </span>
                <div className="upload-model-image-preview-container h-300">
                  <InitialModelViewer productId={productId} modelRef = {glbModelRef} />
                </div>
              </div>
            )}
            {isMobile && uploadStep == 1 ? (
              ""
            ) : (
              <div className="model-section">
                <div className="flex-between">
                  <div className="flex-between">
                    <div
                      className={`step-number ${
                        image ? "step-number-success" : "step-number-greyed"
                      }`}
                    >
                      <CheckOutlined />
                    </div>
                    <span className="step-header-text">
                      Add matching product view
                    </span>
                    <Popover
                      placement="bottom"
                      content={
                        <div className="tips-popover">
                          <p>Tips for best results:</p>
                          <p className="tips-item">
                            <CheckOutlined className="tips-icon" /> Use an image
                            with a solid white or gray background
                          </p>
                          <p className="tips-item">
                            <CheckOutlined className="tips-icon" /> Use file
                            format jpg, png, or tiff
                          </p>
                          <p className="tips-item">
                            <CheckOutlined className="tips-icon" /> Make sure
                            your file is under XX MB
                          </p>
                        </div>
                      }
                    >
                      <InfoCircleOutlined className="info-icon" />
                    </Popover>
                  </div>
                </div>
                <span className="section-subheading">
                  Add helpful views like front, side, top down, etc.
                </span>
                <FileUpload
                  setImage={setImage}
                  className="h-300"
                  image={image}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          {isMobile && uploadStep == 1 ? (
            ""
          ) : (
            <Button
              disabled={!image}
              type="primary"
              onClick={() => saveView(image, "angle")}
              className="save-product-view-button"
            >
              Save this product view
            </Button>
          )}
          {isMobile && uploadStep == 1 && (
            <Button
              type="primary"
              onClick={() => setUploadStep(2)}
              className="save-product-view-button"
            >
              Next, add matching photo
            </Button>
          )}
          {isMobile && uploadStep == 2 && (
            <Button
              type="primary"
              onClick={() => setUploadStep(1)}
              className="save-product-view-button transparent"
            >
              Cancel
            </Button>
          )}
        </div>
        <div className="saved-views-container">
          <span className="step-header-text"> Saved Views </span>
          <div className="saved-views">
            {savedViews.map((view, index) => (
              <div className="saved-view-image">
                <img className="upload-model-image" src={view.image_url} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="upload-model-image-footer">
        <Button
          type="outline"
          onClick={() => handleBack()}
          className="footer-button outline"
        >
          Back
        </Button>
        <div className="hide-mobile footer-text">
          <span className="step-header-text">
            <img src={aiSparkleIcon} className="all3d-logo" />
            Ready to create more AI magic?{" "}
          </span>
          <span>See your 360 spin and photorealistic lifestyle images.</span>
        </div>
        <Button
          type="primary"
          onClick={handleGenerateAssets}
          className="footer-button"
        >
          Generate your assets
          <ArrowRightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default EnhanceModel;
