import React from 'react';
import { Row, Col, Input, Button, Upload, Message, message, Tooltip,Collapse, Card,Image } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import PreprocessingImages from '../PreprocessingImages/PreprocessingImages'
import ModelOBJViewer from '../../CustomerComponents/OBJViewer/OBJViewer';
import { DownloadOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants';
import VariationBaseModelDetails from '../VariationBaseModelDetails';
import { PRODUCT_FEEDBACK_URL, PRODUCT_FEEDBACK_URI } from '../../../../environments/env';

const imageStyle = { width: '100%' };
const { Panel } = Collapse;

const scans_base_url = "https://all3d-reconstruction.s3.amazonaws.com/temp_results/";

const availableModelLabels = FileConstants.availableModelLabels;
const allRequiredAssets = FileConstants.allRequiredAssets;
const allPBRRequiredAssets = FileConstants.allPBRRequiredAssets;
const highRequiredAssets = FileConstants.highRequiredAssets;
const highPBRRequiredAssets = FileConstants.highPBRRequiredAssets;
const lowRequiredAssets = FileConstants.lowRequiredAssets;

function getModelLabel(val){
    if (availableModelLabels[val] != undefined){
        return availableModelLabels[val];
    } else {
        return val;
    }
}

class ProductStaticGuidelines extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product_id: 0,
            final_photos: {},
            rejections_data: [],
            product_guidelines: {
                modeling_required: '',
                reference_urls: [],
                product_photos: {
                },
                product_manuals: [],
                raw_files: [],
                model_files: [],
                materials: [],
                model_type: '',
                need_to_model: '',
                components: [
                    {
                        componentName: '',
                        componentImages: []
                    }
                ],
                guidelines: "",
                scans: [],
                material_files: [],
                model_files: [],
                color_extracted_images: {},
                admin_text_guidelines: "",
                rig_file: {},
                is_ai_model: false
            },
            required_assets: '',
            selected_materials: [],
            loader: true
        }
    }

    componentDidMount() {
        var product_id = 0;
        if (this.props.match != undefined) {
            product_id = this.props.match.params.id;
        }
        else {
            product_id = this.props.product_id;
        }

        this.setState({ product_id: product_id });

        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                console.log(res.data)
                this.setState({ product_guidelines: res.data }, () => {
                    let model_use_cases = res.data.need_to_model;
                    let user_model_type = res.data.model_type;
                    if (user_model_type == "" && model_use_cases == "high_res") {
                        this.setState({required_assets:highRequiredAssets});
                    }
                    if (user_model_type == "" && model_use_cases == "high_pbr") {
                        this.setState({required_assets:highPBRRequiredAssets});
                    }
                    if (user_model_type == "" && model_use_cases == "ar"){
                        this.setState({required_assets:lowRequiredAssets});
                    }
                    if (user_model_type == "" && model_use_cases == "high_res_and_ar"){
                        this.setState({required_assets:allRequiredAssets});
                    }
                    if (user_model_type == "" && model_use_cases == "high_pbr_and_ar"){
                        this.setState({required_assets:allPBRRequiredAssets});
                    }
                    if (user_model_type == "high_pbr_and_ar" && model_use_cases == ""){
                        this.setState({required_assets:allPBRRequiredAssets});
                    }
                    if (user_model_type == "high_res" && model_use_cases == "ar"){
                        this.setState({required_assets:allRequiredAssets});
                    }
                    if (user_model_type == "ar" && model_use_cases == "high_res"){
                        this.setState({required_assets:allRequiredAssets});
                    }
                    if (user_model_type == "high_pbr" && model_use_cases == "ar"){
                        this.setState({required_assets:allPBRRequiredAssets});
                    }
                    if (user_model_type == "ar" && model_use_cases == "high_pbr"){
                        this.setState({required_assets:allPBRRequiredAssets});
                    }
                    if (user_model_type == "ar" && model_use_cases == ""){
                        this.setState({required_assets:lowRequiredAssets});
                    }
                    if (user_model_type == "high_res" && model_use_cases == ""){
                        this.setState({required_assets:highRequiredAssets});
                    }
                    if (user_model_type == "high_pbr" && model_use_cases == ""){
                        this.setState({required_assets:highPBRRequiredAssets});
                    }

                    axios.post(ENVIRONMENT.RENDER_PRODUCT, {
                        product_id: product_id,
                      })
                        .then(res => {
                            axios.post(ENVIRONMENT.GET_PRODUCT_LIGHTING_RIG, { product_id: product_id})
                            .then(res => {
                            console.log(res.data)
                                this.setState({
                                    rig_file: res.data
                                });
                            }).catch (err => {
                            console.log(err)
                            })
                        });
                        let payload = {
                            product_id: product_id
                          };
                          axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH,payload)
                          .then(res => {
                              let response = res.data;
                              if (response) {
                                  response = response.body;
                                  this.setState({
                                    selected_materials: response
                                  });
                                  console.log('Materials',response)
                              }
                              this.setState({
                                  loader: false
                              });
                          });
                });
            });

            axios.post(ENVIRONMENT.GET_PRODUCT_REJECTIONS, { product_id })
            .then(res => {
                this.setState({
                    rejections_data: res.data
                });

            })
    }

    render() {
        let product_manuals = ''
        if(this.state.product_guidelines.product_manuals != undefined){
                product_manuals = this.state.product_guidelines.product_manuals.map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((ele.originFileObj ? ele.originFileObj.uid : ele.uid) + '/' + ele.name);
                return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                <span className="manrope f-16 gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
                </div>
            });
        }

        let model_files = '';
        if(this.state.product_guidelines.model_files){

          model_files = this.state.product_guidelines.model_files.map((ele, index) => {
            let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((ele.originFileObj ? ele.originFileObj.uid : ele.uid) + '/' + ele.name);
            return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
            <span className="manrope f-16 gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
            </div>
          });
        }

        let raw_files = '';
        if(this.state.product_guidelines.raw_files != undefined){
          raw_files = this.state.product_guidelines.raw_files.map((ele, index) => {
            let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
            return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
            <span className="manrope f-16 gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
            </div>
          });
        }

        let scans_data = '';
        let scans_viewers = '';
        if(this.state.product_guidelines.scans != undefined){
          scans_data = this.state.product_guidelines.scans.map((ele, index) => {
            let file_url = scans_base_url + encodeURIComponent(ele);
            return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
            <span className="manrope f-16 gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
            </div>
          });


          scans_viewers = this.state.product_guidelines.scans.map((ele, index) => {
            ele = ele.replace('.zip', '/')
            let file_url = scans_base_url + ele;
              return <div style={{border: '1px solid black', margin: 12, position:'relative'}}>
                      <div id={`loading-container-${index}`} style={{textAlign: 'center',
                    position: 'absolute',
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    backgroundColor:'white'}}>Loading ...</div>
                      <ModelOBJViewer model_url={file_url} loaderId={`loading-container-${index}`}/>
                  </div>
          });
        }

        let rig_file_data = '';
        if(this.state.rig_file && this.state.rig_file.rig_file){
          rig_file_data = <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
            <span className="manrope f-16 gray urls"><a href={this.state.rig_file.rig_file} target='_blank' style={{color: "#276DD7"}}>{this.state.rig_file.rig_file}</a></span>
            </div>
        }

        let product_text_guidelines = '';
        if (this.state.product_guidelines.guidelines != undefined) {
          product_text_guidelines = this.state.product_guidelines.guidelines.split('\n').map((item, i) => {
            if (item != ""){
              return <li style={{margin: 2}}><div className="category-artist medium align-left" key={i}>{item}</div></li>;
            }
          });
        }

        let admin_text_guidelines = "";
        if(this.state.product_guidelines.admin_text_guidelines != undefined && this.state.product_guidelines.admin_text_guidelines != ""){
          admin_text_guidelines = <li style={{margin: 2}}><div className="category-artist medium align-left">{this.state.product_guidelines.admin_text_guidelines}</div></li>
        }

        let dimensions_text = "";
        if(this.state.product_guidelines.dimensions_text != undefined && this.state.product_guidelines.dimensions_text != "")
          dimensions_text = <li style={{margin: 2}}><div className="category-artist medium align-left">{this.state.product_guidelines.dimensions_text}</div></li>

        let product_photos = []
        if (this.state.product_guidelines.product_photos != undefined) {
          Object.keys(this.state.product_guidelines.product_photos).forEach((key) => {
            let photos_array = this.state.product_guidelines.product_photos[key];
            if(!Array.isArray(photos_array))
            {
                if(key in photos_array){
                    photos_array = this.state.product_guidelines.product_photos[key][key];
                }
                else{
                    photos_array = [];
                }
            }
            let product_photos_subset = (photos_array || []).map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                return <Col>
                    <Card className="product-artist-card">
                    {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                    <Image
                    className="product-photo-artist" src={file_url}
                    />
                      :
                    <a href={file_url} target='blank'>
                      <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                      <img
                      className="product-photo-artist" src={'/img/file-icon.png'}
                      />
                      </Tooltip>
                    </a>    }
                    </Card>
                </Col>
            });
            if (key == 'product_photos_all')
            {
                product_photos.unshift(product_photos_subset);
            }
            else{
                product_photos.push(product_photos_subset);
            }
          });
          if (Array.isArray(this.state.product_guidelines.product_photos) && product_photos && product_photos[0] && product_photos[0].length == 0) {
            product_photos = []
            this.state.product_guidelines.product_photos.map((ele) => {
                let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                product_photos.push(<Col>
                <Card className="product-artist-card">
                    {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                    <Image
                    className="product-photo-artist" src={file_url}
                    />
                      :
                    <a href={file_url} target='blank'>
                      <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                      <img
                      className="product-photo-artist" src={'/img/file-icon.png'}
                      />
                      </Tooltip>
                    </a>    }
                </Card>
            </Col>)
            });
        }
        }

        let category_attachment = []
        if (this.state.product_guidelines.category_attachment && this.state.product_guidelines.category_attachment.length > 0) {
          this.state.product_guidelines.category_attachment.map((image,index) => {
                let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                    category_attachment.push(<Col>
                    <Card className="product-artist-card">
                    {image.name.toLowerCase().includes('jpg') || image.name.toLowerCase().includes('jpeg') || image.name.toLowerCase().includes('png') || image.name.toLowerCase().includes('svg') ?
                    <Image
                    className="product-photo-artist" src={file_url}
                    />
                      :
                    <a href={file_url} target='blank'>
                      <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                      <img
                      className="product-photo-artist" src={'/img/file-icon.png'}
                      />
                      </Tooltip>
                    </a>    }
                    </Card>
                </Col>)
            })
        }

        let material_files_array = (this.state.product_guidelines.material_files || []);
          if(!Array.isArray(this.state.product_guidelines.material_files))
          {
              if(this.state.product_guidelines.material_files != undefined){
                  if('componentImages' in this.state.product_guidelines.material_files){
                      material_files_array = this.state.product_guidelines.material_files['componentImages'];
                  }
              }
          }

        let material_files = material_files_array.map((ele, index) => {
            let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
            return <Col>
              <Card className="product-artist-card">
                {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                <Image
                className="product-photo-artist" src={file_url}
                />
                  :
                <a href={file_url} target='_blank'>
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                    <img
                    className="product-photo-artist" src={'/img/file-icon.png'}
                    />
                  </Tooltip>
                </a>    }
              </Card>
            </Col>
        });

        let color_files_array = (this.state.product_guidelines.color_photos || []);
        let color_files = color_files_array.map((ele, index) => {
            let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
            return <Col>
              <Card className="product-artist-card">
                {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                <Image
                className="product-photo-artist" src={file_url}
                />
                  :
                <a href={file_url} target='blank'>
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                    <img
                    className="product-photo-artist" src={'/img/file-icon.png'}
                    />
                  </Tooltip>
                </a>    }
              </Card>
            </Col>
        });
        let material_names = '';
        if (this.state.product_guidelines.materials != undefined) {
          material_names = this.state.product_guidelines.materials.map((ele, index) => {
              return <span className="manrope f-16 black-55">{index> 0 ? `, ${ele}` : `${ele}`}</span>
          });
        }

        return (
            <span>
                {this.state.loader ?
                <DottedLoader/>
                :
                <div style={{padding: '0px'}}>
                    {!this.props.artist_page &&
                    <>
                    <Row style={{padding: '20px 0px 0px 20px', display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                        <Col style={{marginBottom: 10}}>
                            <span className="manrope f-32 black-55 capitalize">{this.state.product_guidelines.product_name}</span>
                        </Col>
                    </Row>
                    <Row style={{padding: '0px 0px 0px 20px'}}>
                        {(this.state.product_guidelines.parent_category || this.state.product_guidelines.category) &&
                        <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 5}}>
                            <span className="manrope f-16 gray-77" style={{marginRight: 8}}>Category</span>
                            <div className ="note-bg-artist gray">
                                <span className="manrope f-16 black-55">
                                    {this.state.product_guidelines.parent_category &&  this.state.product_guidelines.category ?
                                    <span>
                                    {this.state.product_guidelines.parent_category + ' / ' + this.state.product_guidelines.category}
                                    </span> :
                                    <span> {!this.state.product_guidelines.parent_category ?
                                    this.state.product_guidelines.category
                                    : !this.state.product_guidelines.category ? this.state.product_guidelines.parent_category : ''}
                                    </span>}
                                </span>
                            </div>
                        </Col>}
                        <Col style={{display:"flex",alignItems:"center",marginRight: 10,marginBottom: 5}}>
                            <span  className="manrope f-16 gray-77" style={{marginRight: 8}}>ID</span>
                            <div className ="note-bg-artist gray">
                                <span className="manrope f-16 black-55">{this.state.product_id}</span>
                            </div>
                        </Col>
                    </Row>
                    </>}

                    <Row>
                        <Col span={20} style={{padding: "30px", paddingLeft: 0}}>
                            <Collapse defaultActiveKey={['1','2', '3']} className="collapse-artist" ghost expandIconPosition={'right'}>
                            <Panel header={<span className="note-text gray">Product Model Information</span>} key="1">
                            <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'flex-start'}}>
                                <Col span={24}>
                                <Row>

                                    {!this.props.artist_page &&
                                    <VariationBaseModelDetails
                                    required_assets={this.state.required_assets}
                                    product_id={this.state.product_id}
                                    productDetails={this.state.product_guidelines}
                                    category_attachment={category_attachment}
                                    selectedMaterials={this.state.selected_materials}
                                    changeVisibility={this.changeVisibility} />}
                                    </Row>
                                    <Row div className="justify-in-start" style={{flexWrap: 'wrap',alignItems: 'flex-start'}}>
                                        {this.state.product_guidelines.variation_type ? <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                        <div className="manrope f-16 gray-77" style={{marginRight: 8}}>Change Type</div>
                                        <div className ="note-bg-artist gray">
                                            <span className="manrope f-16 black-55">{FileConstants.variationDict(this.state.product_guidelines.variation_type, this.state.product_guidelines.need_to_model)}</span>
                                        </div>
                                        </Col>: ''}
                                        <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                        <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        Height
                                        </div>
                                        <div className="note-bg-artist gray">
                                            <span className="manrope f-16 black-55">{this.state.product_guidelines.height || '-'} Inches</span>
                                        </div>
                                        </Col>
                                        <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                        <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        Width
                                        </div>
                                        <div className="note-bg-artist gray">
                                            <span className="manrope f-16 black-55">{this.state.product_guidelines.width || '-'} Inches</span>
                                        </div>
                                        </Col>
                                        <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                        <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        Depth
                                        </div>
                                        <div className="note-bg-artist gray">
                                            <span className="manrope f-16 black-55">{this.state.product_guidelines.length || '-'} Inches</span>
                                        </div>
                                        </Col>
                                       
                                        {(this.state.product_guidelines.is_ai_model) ?
                                        <Col style={{display:"flex",alignItems:"baseline",marginRight: 40}}>
                                        <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        AI Generated GLB
                                        </div>
                                        <div style={{display: "block"}}>
                                            <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                                                <span className="manrope f-16 gray urls">
                                                    <a href={`${ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform)}/product_assets/ai_generated_glb/${this.state.product_id}.glb`} target='_blank' style={{color: "#276DD7", wordWrap: "break-word", whiteSpace: "normal"}}>{`${ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform)}/product_assets/ai_generated_glb/${this.state.product_id}.glb`}</a>
                                                </span>
                                            </div>
                                        </div>
                                        </Col>: ''}
                                        {(this.state.product_guidelines.reference_urls != undefined && ((typeof (this.state.product_guidelines.reference_urls) == "object" && Object.keys(this.state.product_guidelines.reference_urls).length != 0)) ) ?
                                        <Col style={{display:"flex",alignItems:"baseline",marginRight: 40}}>
                                        <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        Reference URL(s)
                                        </div>
                                        <div style={{display: "block"}}>
                                        {this.state.product_guidelines.reference_urls.map((ele, index) => {
                                            return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                                            <span className="manrope f-16 gray urls"><a href={ele} target='_blank' style={{color: "#276DD7"}}>{ele}</a></span>
                                            </div>
                                        })}
                                        </div>
                                        </Col>: ''}
                                        {(this.state.product_guidelines.product_manuals != undefined && ((typeof (this.state.product_guidelines.product_manuals) == "object" && Object.keys(this.state.product_guidelines.product_manuals).length != 0))) ?
                                        <Col style={{isplay:"flex",alignItems:"baseline",marginRight: 40}}>
                                            <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                            Product Manuals
                                            </div>
                                            <div style={{display: "block"}}>
                                            {product_manuals}
                                            </div>
                                        </Col>: ""}
                                        {(this.state.product_guidelines.model_files != undefined && (typeof (this.state.product_guidelines.model_files) == "object" && Object.keys(this.state.product_guidelines.model_files).length != 0))||
                                        ((typeof (this.state.product_guidelines.raw_files) == "object" && Object.keys(this.state.product_guidelines.raw_files).length != 0)) ?
                                            <Col style={{display:"flex",alignItems:"baseline",marginRight: 40}}>
                                            <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                CADS/ Raw Files/ Other Information
                                            </div>
                                            <div style={{display: "block"}}>
                                            {model_files}
                                            {raw_files}
                                            </div>
                                            </Col> : ""}
                                            {(this.state.product_guidelines.scans != undefined && ((typeof (this.state.product_guidelines.scans) == "object" && Object.keys(this.state.product_guidelines.scans).length != 0))) ?
                                            <Col style={{display:"flex",alignItems:"baseline",marginRight: 40}}>
                                                <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                Scan(s) Mesh
                                                </div>
                                                <div style={{display: "block"}}>
                                                {scans_data}
                                                </div>
                                            </Col>: ""}
                                            {(this.state.rig_file && this.state.rig_file.rig_file) ?
                                                <Col style={{display:"flex",alignItems:"baseline",marginRight: 40}}>
                                                <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Lighting Rig&nbsp;<Tooltip title={<span className='manrope f-12 white'>Use provided light rig to test on your local machine before uploading to ALL3D to ensure colors are matching</span>}><InfoCircleOutlined/></Tooltip>
                                                </div>
                                                <div style={{display: "block"}}>
                                                    {rig_file_data}
                                                </div>
                                                </Col> : ""}
                                                {(((typeof (this.state.product_guidelines.raw_files) == "object" && Object.keys(this.state.product_guidelines.raw_files).length != 0)) || ((typeof (this.state.product_guidelines.model_files) == "object" && Object.keys(this.state.product_guidelines.model_files).length != 0))) && this.state.model_type != "" ?
                                                    <Col style={{display:"flex",alignItems:"baseline",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Reference Model
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                        <span className="manrope f-16 gray urls">{getModelLabel(this.state.product_guidelines.model_type)} Bundle</span>
                                                    </div>
                                                    </Col>
                                                : ""}
                                                {this.state.product_guidelines.segmented &&
                                                <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Segmented Model
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                    <span className="manrope f-16 black-55">Yes</span>
                                                    </div>
                                                </Col>}

                                                {this.state.product_guidelines.color_name &&
                                                <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Product Color
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                    <span className="manrope f-16 black-55">{this.state.product_guidelines.color_name}</span>
                                                    </div>
                                                </Col>}

                                                {this.state.product_guidelines.similar_color &&
                                                <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Closest Color Match
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                    <span className="manrope f-16 black-55">{this.state.product_guidelines.similar_color}</span>
                                                    </div>
                                                </Col>}
                                                {this.state.product_guidelines.hex_color &&
                                                <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Hex Color
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                    <span className="manrope f-16 black-55">{this.state.product_guidelines.hex_color}</span>
                                                    </div>
                                                </Col>}
                                                {this.state.product_guidelines.pantone_color &&
                                                <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                    Pantone Color
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                    <span className="manrope f-16 black-55">{this.state.product_guidelines.pantone_color}</span>
                                                    </div>
                                                </Col>}

                                                {this.state.product_guidelines.materials != undefined && ((typeof (this.state.product_guidelines.materials) == "object" && Object.keys(this.state.product_guidelines.materials).length != 0)) ?
                                                <Col style={{display:"flex",alignItems:"center",marginRight: 40,marginBottom: 5}}>
                                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                                        Product Material(s)
                                                    </div>
                                                    <div className="note-bg-artist gray">
                                                        {material_names}
                                                    </div>
                                                </Col> : ""}
                                    </Row>
                                </Col>
                            </Row>


                                {(this.state.product_guidelines.scans != undefined && this.state.product_guidelines.scans.length > 0 ) ?
                                <span>
                                <Row style={{marginBottom: 16}}>
                                    <Col style={{display:"flex",alignItems:"baseline",marginRight: 40}}>
                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        Scan(s) Preview
                                    </div>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 16}}>
                                        <Col style={{display:"flex",alignItems:"baseline"}}>
                                        <div style={{display: "block"}}>
                                            {scans_viewers}
                                        </div>
                                        </Col>
                                </Row>
                                </span>:""}
                                {this.state.product_guidelines.color_photos && this.state.product_guidelines.color_photos.length > 0 ?
                                <Row>
                                <Col>
                                    <div className="manrope f-16 gray-77" style={{marginBottom:16}}>Color Photos&nbsp;
                                    <Tooltip title={<span className='manrope f-12 white'>Use color to implement the change</span>}><InfoCircleOutlined/></Tooltip>
                                    </div>
                                    <Row style={{display:"flex"}}>
                                    {color_files}
                                    </Row>
                                </Col>
                                </Row> : ""}

                                {this.state.product_guidelines.material_files != undefined && ((typeof (this.state.product_guidelines.material_files) == "object" && Object.keys(this.state.product_guidelines.material_files).length != 0)) ?
                                <Row>
                                <Col>
                                    <div className="manrope f-16 gray-77" style={{marginBottom:16}}>Materials Uploaded by Customer&nbsp;
                                    <Tooltip title={<span className='manrope f-12 white'>Use this material to implement the change</span>}><InfoCircleOutlined/></Tooltip>
                                    </div>
                                    <Row style={{display:"flex"}}>
                                    {material_files}
                                    </Row>
                                </Col>
                                </Row> : ""}



                                {this.state.is_configurable && this.state.comp_arr && this.state.comp_arr.length > 0 &&
                                <Row style={{marginBottom: 16}}>
                                    <Col style={{display:"flex",alignItems:"baseline"}}>
                                    <div className="manrope f-16 gray-77" style={{marginRight: 8}}>
                                        Components
                                    </div>
                                    {this.state.comp_arr.map((component,index) => (
                                            <div className ="note-bg-artist gray" style={{marginRight: 5}}>
                                                <span className="note-text blue" >{component.name}</span>
                                            </div>
                                        ))}
                                    </Col>
                                </Row>}
                                {!this.state.is_configurable && this.state.comp_arr && this.state.comp_arr.length > 0 && this.state.comp_arr.map((component, index) => (
                                <Row style={{marginBottom:16}}>
                                    <Col>
                                    <div className="note-text gray" style={{marginBottom:16, textTransform: 'capitalize'}}>
                                        {component.using_material_library == "1" ?
                                        <span>
                                        Material for {component.name}
                                        </span> : component.using_material_library == "0" ?
                                        <span>
                                        Material Uploaded for {component.name}
                                        </span> : ''
                                        }
                                    </div>
                                    <Row>
                                        <Col style={{display:"flex",alignItems:"baseline",marginBottom: 8}}>
                                        <div className="category-artist medium" style={{marginRight: 8}}>
                                            Category
                                        </div>

                                        <div className ="note-bg-artist gray" style={{marginRight: 5}}>
                                            {component.using_material_library == "0" ?
                                            <span className="note-text blue" >{this.state.comp_mat_arr && this.state.comp_mat_arr[component.name] && this.state.comp_mat_arr[component.name].componentMaterial}</span>:
                                            <span className="note-text blue" >{this.state.comp_mat_arr && this.state.comp_mat_arr[component.name] && this.state.comp_mat_arr[component.name]['category']}</span>
                                            }
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row style={{display:"flex"}}>
                                    <Col>
                                        <Card className="product-artist-card">
                                        {this.state.comp_mat_arr && component.using_material_library == "1" && this.state.comp_mat_arr[component.name] && this.state.comp_mat_arr[component.name].renders && this.state.comp_mat_arr[component.name].renders.data && this.state.comp_mat_arr[component.name].renders.data && this.state.comp_mat_arr[component.name].renders.data.thumbnail ?
                                            <Image
                                            preview={{
                                            visible: this.state.image_visible[component.name],
                                            maskClassName: 'customize-mat-icon',
                                            mask: (
                                            <span>
                                                <DownloadOutlined  style={{fontSize: 24}} onClick={() => window.location.href = this.state.comp_mat_arr[component.name]['input_bundle']}/>
                                            </span>
                                            ),
                                            onVisibleChange: this.changeVisibility,
                                            }}
                                            className="product-photo-artist" src={ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.MATERIAL_FILE_URI + this.state.comp_mat_arr[component.name]['id'] + '/' + encodeURIComponent(this.state.comp_mat_arr[component.name].renders.data.thumbnail)}
                                        /> : (this.state.comp_mat_arr && component.using_material_library == "1" && this.state.comp_mat_arr[component.name] && this.state.comp_mat_arr[component.name].material_files) ?
                                        <Image
                                            preview={{
                                            visible: this.state.image_visible[component.name],
                                            maskClassName: 'customize-mat-icon',
                                            mask: (
                                            <span>
                                                <DownloadOutlined  style={{fontSize: 24}} onClick={() => window.location.href = this.state.comp_mat_arr[component.name]['input_bundle']}/>
                                            </span>
                                            ),
                                            onVisibleChange: this.changeVisibility,
                                            }}
                                            className="product-photo-artist" src={ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(this.state.comp_mat_arr[component.name].material_files[0].uid + '/' + this.state.comp_mat_arr[component.name].material_files[0].name)}
                                        />
                                        :
                                        (this.state.comp_mat_arr && component.using_material_library == "0" && this.state.comp_mat_arr[component.name]) ?
                                        <Image
                                            preview={{
                                            visible: this.state.image_visible[component.name],
                                            maskClassName: 'customize-mat-icon',
                                            mask: (
                                            <span>
                                                <EyeOutlined  style={{fontSize: 24}}/>
                                            </span>
                                            ),
                                            onVisibleChange: this.changeVisibility,
                                            }}
                                            className="product-photo-artist" src={ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.BASE_URI +  encodeURIComponent(this.state.comp_mat_arr[component.name].componentImages[0].uid + '/' + this.state.comp_mat_arr[component.name].componentImages[0].name)}
                                        /> :''}

                                        </Card>
                                    </Col>
                                    </Row>
                                    </Col>
                                </Row>
                                )) }

                                {this.state.selected_materials && this.state.selected_materials.length > 0 ?
                                    <div style={{marginBottom: 30}}>
                                        <div  className="manrope f-16 gray-77"  style={{textAlign:"left", marginBottom: 10}}>
                                            Material Image(s)&nbsp;
                                            <Tooltip title={<span className='manrope f-12 white'>Use this material to implement the change</span>}><InfoCircleOutlined/></Tooltip>
                                        </div>
                                        <Row>
                                        {this.state.selected_materials.map(mat => (
                                            mat.renders && Object.keys(mat.renders).length > 0 &&
                                            <Col>
                                                <Card className="product-artist-card">
                                                    <Image
                                                    preview={{
                                                    visible:false,
                                                    maskClassName: 'customize-mat-icon',
                                                    mask: (
                                                    <span>
                                                        <DownloadOutlined  style={{fontSize: 24}}/>
                                                    </span>
                                                    ),
                                                    onVisibleChange: this.changeVisibility,
                                                    }}
                                                    className="product-photo-artist"
                                                    onClick={() => window.location.href = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' +  mat.id + '.zip'}
                                                    src={ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' +  encodeURIComponent(mat.renders.data.thumbnail)}
                                                    />
                                                </Card>
                                            </Col>

                                        ))}
                                        </Row>
                                    </div>: ''}

                                {this.state.required_assets.length == 0 ? "" :
                                <Row style={{marginTop: 40}}>
                                <Col>
                                    <div className="manrope f-16 gray-77" style={{marginBottom:16}}>Required Outputs</div>
                                    <Row>
                                    {this.state.required_assets.map(x => (
                                    <Col style={{marginBottom: 5}}>
                                    <div className="note-bg-artist gray" style={{marginRight: 16}}>
                                        <span className="manrope f-16 black-55">{x}</span>
                                    </div>
                                    </Col>
                                    ))}
                                    </Row>
                                </Col>
                                </Row>}
                                {this.state.product_guidelines.category_type ?
                                <Row  style={{marginTop: 10}}>
                                    <Col span={24} style={{display:"flex",alignItems:"center"}}>
                                        <span className="category-artist" style={{marginRight:16}}>Bedding Type</span>
                                        {FileConstants.HIDDEN_CATEGORY[this.state.product_guidelines.category].map((cat, index) => (
                                            cat == this.state.product_guidelines.category_type ?
                                            <div className ="note-bg-artist gray">
                                                <span className="manrope f-16 black-55">
                                                    {cat}
                                                </span>
                                            </div> : ''
                                        ))}
                                    </Col>
                                    {this.state.product_guidelines.category_attachment && this.state.product_guidelines.category_attachment.length > 0 ?
                                    <Col span={24} style={{marginTop: 10, display: 'flex'}}>
                                    {category_attachment}
                                    </Col>
                                    : ''}
                                </Row>:
                                FileConstants.HIDDEN_CATEGORY[this.state.product_guidelines.category] ?
                                    <Row style={{marginTop: 10,marginLeft:'0px'}}>
                                        <Col span={24} style={{display:"flex",alignItems:"center"}}>
                                        <span className="manrope f-16 gray-77" style={{marginRight:16}}>Bedding Type</span>
                                            <div className ="note-bg-artist gray">
                                                <span className="manrope f-16 black-55">
                                                    White Mattress and Pillows
                                                </span>
                                            </div>
                                        </Col>
                                        <Col span={24} style={{marginTop: 10}}>
                                            <Col>
                                                <Card className="product-artist-card">
                                                    <Image
                                                    className="product-photo-artist" src='/img/simple_bedding.png'
                                                    />
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Row> :
                                    FileConstants.HIDDEN_CATEGORY[this.state.product_guidelines.parent_category] ?
                                    <Row style={{marginTop: 10,marginLeft:'0px'}}>
                                        <Col span={24} style={{display:"flex",alignItems:"center"}}>
                                        <span className="manrope f-16 gray-77" style={{marginRight:16}}>Bedding Type</span>
                                            <div className ="note-bg-artist gray">
                                                <span className="manrope f-16 black-55">
                                                    {this.state.product_guidelines.parent_category}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col span={24} style={{marginTop: 10}}>
                                            <Col>
                                                <Card className="product-artist-card">
                                                    <Image
                                                    className="product-photo-artist" src='/img/bedframe.jpg'
                                                    />
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Row>: ''}
                            </Panel>
                            {product_photos && product_photos.length > 0 &&
                            <Panel header={<span className="note-text gray">Reference Images</span>} key="2">
                                <Row style={{display:"flex"}}>
                                {product_photos}
                                </Row>
                            </Panel>}

                            <Panel header={<span className="note-text gray">RGB Color Palette of Reference Image</span>} key="3">
                                <Row style={{display:"flex"}}>
                                    <PreprocessingImages color_extracted_images={this.state.product_guidelines.color_extracted_images} product_id={this.state.product_id} platform={this.state.product_guidelines.platform}/>
                                </Row>
                            </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </div>}
            </span>
        );
    }
}

export default ProductStaticGuidelines;
