import React, { useEffect, useState, useContext, createRef } from "react";
import axios from "axios";
import { Button, Input, Menu, Carousel, message } from "antd";
import qrCodeImage from "../../../assets/images/qrCode.png";
import aiDemoImage from "../../../assets/images/aiLifestyleDemo.png";
import refineFurtherImage from "../../../assets/images/refineFurther.png";
import aiSparkleIcon from "../../../assets/images/aiSparkleIcon.svg";
import "./index.scss";
import ENVIRONMENT from "../../../environments";
import FileConstants from "../../../FileConstants";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined,
  LoadingOutlined,
  HomeOutlined
} from "@ant-design/icons";
import QRCode from "react-qr-code";
import AiModelGenerationContext from "../ContextFiles/AiModelGenerationContext";

const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const ViewAssets = () => {
  const [threeSixtyFrames, setThreeSixtyFrames] = useState(null);
  const [lifestyleImage, setLifestyleImage] = useState([]);
  const [glbModel, setGlbModel] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [promptValue, setPromptValue] = useState("");
  const [renderRequestLoader, setRenderRequestLoader] = useState(false);
  const [renderRequestPending, setRenderRequestPending] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const carouselRef =  createRef();
  let policy = "always-allow";
  let loaderProps = {};
  loaderProps = {
    loading: "eager",
  };
  const { 
    setCurrentStep, 
    setIsLoading, 
    setHeading, 
    isMobile, 
    productId,
    clearUrlParams,
    setStepValue
  } = useContext(AiModelGenerationContext);

  const handleNextImage = () => {
    setCurrentImageIndex(currentImageIndex + 1);
    if (currentImageIndex === lifestyleImage.length - 1) {
      setCurrentImageIndex(0);
    }
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(currentImageIndex - 1);
    if (currentImageIndex === 0) {
      setCurrentImageIndex(lifestyleImage.length - 1);
    }
  };

  useEffect(() => {
    fetchAssets(productId);
  }, [productId]);

  const handleNewModel = () => {
    window.location.href = "/ai-model-generation";
  };

  const fetchAssets = (product_id, isShowLoader = true) => {
    if (!product_id) {
      return;
    }
    if (isShowLoader) {
      setIsLoading(true);
      setHeading("Loading your assets");
    }
    axios
      .post(ENVIRONMENT.AI_MODEL_HELPER, {
        action: "get_ai_assets",
        product_id: product_id,
        username: CUSTOMER_USERNAME,
      })
      .then((res) => {
        if (res.data) {
          setThreeSixtyFrames(res.data.ai_threesixty_spin_details);
          setLifestyleImage(res.data.lifestyle_renders || []);
          setGlbModel(res.data.ai_generated_glb);
          setThumbnail(res.data.thumbnail);
          setIsLoading(false);
          window.CI360.init();
        } 
        if (!res.data.ai_threesixty_spin_details || !res.data.lifestyle_renders || !res.data.ai_generated_glb || checkInProgressImage(res.data.lifestyle_renders)) {
            setTimeout(() => fetchAssets(product_id, false), 20000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkInProgressImage = (images) => {
    let inProgress = false;
    images.forEach((image) => {
      if (image.status === 'inprogress') {
        inProgress = true;
      }
    });
    if (renderRequestPending) {
      inProgress = true;
    }
    return inProgress;
  }

  useEffect(() => {
    window.CI360.init();
  }, []);

  const handleGenerate = () => {
    setRenderRequestLoader(true);
    setRenderRequestPending(true);
    let payload = {
      action: "trigger_lifestyle_generation_job",
      product_id: parseInt(productId),
      prompt: promptValue,
      username: CUSTOMER_USERNAME
    }
    axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
      setRenderRequestPending(false);
    });
    
    setTimeout(() => {
      setRenderRequestLoader(false);
      setPromptValue("");
      message.success('Render Request Submitted.')
      fetchAssets(productId, false);
    }, 5000);
  };

  const handleDownload = (type) => {
    switch (type) {
      case "360_spin":
        console.log("360 spin");
        break;
      case "lifestyle_images":
        console.log("lifestyle images");
        break;
      case "glb":
        window.open(
          ENVIRONMENT.getBaseURL('aws') + `product_assets/ai_generated_glb/${productId}.glb`,
          "_blank"
        );
        break;
    }
  };

  const getPublicViewerUrl = () => {
    return ENVIRONMENT.PORTAL_LINK + 'product_public_viewer/product_3d_model/' + productId + '?ai_model=true';
  }

  const downloadMenu = (
    <Menu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleDownload("360_spin")}
        >
          Download 360 Spin
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleDownload("lifestyle_images")}
        >
          Download Lifestyle Images
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleDownload("glb")}
        >
          Download GLB
        </a>
      </Menu.Item>
    </Menu>
  );

  const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <div className="view-assets-container">
      <div style={{ padding: "0px 20px" }}>
        <div className="step-tiltle-container">
          <div className="step-number">3</div>
          <span className="step-title">View your Assets</span>
        </div>
        <div className="assets-grid">
          {/* Left Column: 360 Spin and QR */}
          <div className="column">
            {/* 360 Spin Section */}
            <div className="asset-card">
              <div
                className={`asset-label ${threeSixtyFrames ? "" : "blurred"}`}
              >
                360 Spin
              </div>
              {!threeSixtyFrames && (
                <span className="not-generated">
                  <LoadingOutlined />
                  <p>Generating</p>
                </span>
              )}
              {threeSixtyFrames && (
                <div className="asset-preview">
                  {threeSixtyFrames && (
                    <div
                      className="cloudimage-360"
                      id="image-360"
                      data-folder={
                        ENVIRONMENT.getBaseURL() +
                        "product_assets/ai_generated_spin/" +
                        productId +
                        "/frames/"
                      }
                      data-filename={"Frame_{index}.jpg"}
                      data-amount={22}
                    />
                  )}
                </div>
              )}
            </div>

            {/* AR View Section */}
            <div className="asset-card">
              {/* Content container */}
              <div className="asset-label">3D Preview</div>
              {!glbModel && (
                <span className="not-generated">
                  <LoadingOutlined />
                  <p>Generating</p>
                </span>
              )}
              {glbModel && (
                <model-viewer
                  environment-image="neutral"
                  class={"disablehover model-viewer-class "}
                  {...loaderProps}
                  ar
                  data-js-focus-visible
                  shadow-intensity={1}
                  interaction-policy={policy}
                  src={
                    ENVIRONMENT.getBaseURL("aws") +
                    `product_assets/ai_generated_glb/${productId}.glb`
                  }
                  alt="Velvet Hagen Dining Chair"
                  camera-controls
                  interaction-prompt="none"
                  background-color="#FFFFFF"
                ></model-viewer>
              )}
            </div>
          </div>

          {/* Right Column: Lifestyle Image and Refinement */}
          <div className="column">
            {/* Lifestyle Image Section */}
            <div className="asset-card h-70 h-400">
              <div className="asset-label">Lifestyle Image</div>
              {!lifestyleImage.length && (
                <span className="not-generated">
                  <LoadingOutlined />
                  <p>Generating</p>
                </span>
              )}
              {lifestyleImage.length > 0 && (
                <div className="life-style-card">
                  <Carousel ref={carouselRef} autoplay={false} className="lifestyle-carousel" afterChange={(index) => {
                    setCurrentImageIndex(index);
                  }}>
                    {lifestyleImage.map((image, index) => (
                      <div key={index} className="lifestyle-image-container">
                        {image.uri &&
                        image.status ==
                          "completed" ? (
                          <img
                            src={
                              ENVIRONMENT.getBaseURL("aws") +
                              image.uri
                            }
                            alt="Lifestyle view"
                          />
                        ) : (
                          <div className="requeste-lifestyle-container">
                            {
                              <span className="not-generated">
                                <LoadingOutlined />
                                <p>Generating</p>
                              </span>
                            }
                          </div>
                        )}
                      </div>
                    ))}
                  </Carousel>
                  {lifestyleImage?.length > 1 && (
                    <>
                      <Button
                        onClick={() => {carouselRef.current.prev()}}
                        className="ai-image-navigation-button left"
                      >
                        <ArrowLeftOutlined />
                      </Button>
                      <Button
                        onClick={() => {carouselRef.current.next()}}
                        className="ai-image-navigation-button right"
                      >
                        <ArrowRightOutlined />
                      </Button>
                    </>
                  )}
                  <div className="image-caption-container">
                    <p className="caption-text">
                      {" "}
                      {lifestyleImage?.length > 0 &&
                        lifestyleImage[currentImageIndex].prompt_text}{" "}
                    </p>
                    <div className="prompt-input wrap">
                      <Input
                        className="link-input"
                        placeholder="Enter a prompt..."
                        value={promptValue}
                        prefix={
                          <img
                            src={aiSparkleIcon}
                            alt=""
                            style={{ height: 16 }}
                          />
                        }
                        onChange={(e) => setPromptValue(e.target.value)}
                      />
                      <Button
                        className="button-black w-30 w-screen-full"
                        type="primary"
                        onClick={() => handleGenerate()}
                        disabled={renderRequestLoader}
                      >
                        Generate new image
                        {renderRequestLoader ? <LoadingOutlined /> : ""}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Refinement Card - Smaller height */}
            <div className="asset-card h-30 h-400">
              <div className="asset-preview bg-image">
                <img
                  src={
                    ENVIRONMENT.getBaseURL('aws') +
                    "product_assets/thumbnail/" +
                    thumbnail
                  }
                  alt="360 view of chair"
                />
              </div>
              <div className="ar-view-container">
                <div className="asset-label">AR View in Room</div>
                <div className="qr-container">
                  <QRCode className="qr-code" value={getPublicViewerUrl()} />
                  <p className="caption-text large">View in Your Room</p>
                  <p className="caption-text light">
                    Scan the QR code or copy the link below to view your 3D
                    model in your room.
                  </p>
                  <div className="prompt-input">
                    <Input
                      className="link-input"
                      readOnly
                      value={getPublicViewerUrl()}
                      prefix={
                        <img
                          src="/linkCopy.svg"
                          alt=""
                          style={{ height: 16 }}
                        />
                      }
                    />
                    <Button
                      className="button-black"
                      type="primary"
                      onClick={() =>
                        navigator.clipboard.writeText(getPublicViewerUrl())
                      }
                    >
                      Copy
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!window.location.pathname.includes('admin-ai-model-generation') &&
      <div className="upload-model-image-footer">
        <Button
          type="outline"
          onClick={() => setStepValue(2)}
          className="footer-button outline"
        >
          Back
        </Button>
        {/* <Dropdown placement="topLeft" overlay={downloadMenu}>
          <Button type="primary" className="footer-button ml-auto">
            <ArrowDownOutlined />
            Download
          </Button>
        </Dropdown> */}
        <Button
          type="primary"
          className="footer-button ml-auto"
          onClick={handleNewModel}
        >
          Create Another Model
        </Button>
        <Button
          type="primary"
          className="footer-button black"
          onClick={() => {
            window.location.href = "/home";
          }}
        >
          {isMobile ? <HomeOutlined /> : "Go to Dashboard"}
        </Button>
      </div>}
    </div>
  );
};

export default ViewAssets;
