import React, { useState, useEffect, useCallback } from 'react';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import * as Utilities from '../../Utilities';
import { QA_IMG_URI, getBaseURL, SCANS_BASE_URL, MATERIAL_FILE_URL, IMAGE_DIMENSIONAL_URI, BASE_URL } from '../../../../environments/env';
import FileConstants from '../../../../FileConstants';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import ProductPage from '../ProductComponents/ProductPage';
import ProductPageConstants from '../ProductComponents/ProductPageConstants';
import OpenVariationModal from '../ProductComponents/OpenVariationModal';
import AddCollection from '../CustomerProducts/AddCollection';
import AddItemToStore from '../../AddItemToStore/AddItemToStore';
import {
    Button,
    Modal,
    message,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getProductRender } from '../../../../redux/actions';
import CustomerMainLayout from '../CustomerMainLayout';
import AiProductRefineModal from '../AiProduct/AiProductRefineModal';
import AiLifestyleRequestModal from '../AiProduct/AiLifestyleRequestModal';
import RenderInProgressImage from '../../../../assets/images/scene_incomplete.svg'

const CUSTOMER_USERNAME = localStorage.getItem('username');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;

let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;

const ProductCustomerPage = (props) => {
    let carousel = React.createRef();

    const [heroShot, setHeroShot] = useState(null);
    const [showBottomBar, setShowBottomBar] = useState(false);
    const [secondaryShots, setSecondaryShots] = useState([]);
    const [allProductImages, setAllProductImages] = useState([]);
    const [productAssetData, setProductAssetData] = useState(null);
    const [productDataLoader, setProductDataLoader] = useState(true);
    const [productImageCount, setProductImageCount] = useState(0);
    const [productAssetViewer, setProductAssetViewer] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState("");
    const [selectedProductImage, setSelectedProductImage] = useState("");
    const [aspectRatio360, setAspectRatio360] = useState(1);
    const [imageWidth360, setImageWidth360] = useState(0);
    const [statusOf360Spin, setStatusOf360Spin] = useState("");
    const [statusOfMp4Video, setStatusOfMp4Video] = useState("");
    const [productData, setProductData] = useState(null);
    const [lowPolyVertexCount, setLowPolyVertexCount] = useState(0);
    const [highPolyVertexCount, setHighPolyVertexCount] = useState(0);
    const [lowPolyTriangleCount, setLowPolyTriangleCount] = useState(0);
    const [highPolyTriangleCount, setHighPolyTriangleCount] = useState(0);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [highPolyModelSize, setHighPolyModelSize] = useState(0);
    const [lowPolyModelSize, setLowPolyModelSize] = useState(0);
    const [highPolyTextureSize, setHighPolyTextureSize] = useState(0);
    const [lowPolyTextureSize, setLowPolyTextureSize] = useState(0);
    const [modelWidth, setModelWidth] = useState("N/A");
    const [modelDepth, setModelDepth] = useState("N/A");
    const [modelHeight, setModelHeight] = useState("N/A");
    const [archiveLength, setArchiveLength] = useState(0);
    const [glbLength, setGlbLength] = useState(0);
    const [archiveSize, setArchiveSize] = useState(0);
    const [glbSize, setGlbSize] = useState(0);
    const [referenceLinks, setReferenceLinks] = useState([]);
    const [productRefImages, setProductRefImages] = useState([]);
    const [imageFlagModal, setImageFlagModal] = useState(false);
    const [singleImage, setSingleImage] = useState(false);
    const [selectedImageForFlag, setSelectedImageForFlag] = useState('');
    const [sharingModal, setSharingModal] = useState(false);
    const [productEditModal, setProductEditModal] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);
    const [cryptoMatteExists, setCryptoMatteExists] = useState(false);
    const [dimensionCrypto, setDimensionCrypto] = useState(false);
    const [folderParentID, setFolderParentID] = useState(null);
    const [variationModal, setVariationModal] = useState(false);
    const [variationStep, setVariationStep] = useState(0)
    const [requestSaved, setRequestSaved] = useState(false);
    const [libraryMaterial, setLibraryMaterial] = useState([]);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [customerGroupIds, setCustomerGroupIds] = useState([]);
    const [arVariationType, setARVariationType] = useState('');
    const [savedID, setSavedID] = useState(-1);
    const [variationProductDetails, setVariationProductDetails] = useState({});
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [mp4DetailsModal, setMp4DetailsModal] = useState(false);
    const [mp4Price, setMp4Price] = useState(-1);
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [threesixtyDetailsModal, setThreesixtyDetailsModal] = useState(false);
    const [dimensionalDetailsModal, setDimensionalDetailsModal] = useState(false);
    const [dimensionalResolution, setDimensionalResolution] = useState('1k');
    const [dimensionalPrice, setDimensionalPrice] = useState(-1);
    const [res360, setRes360] = useState('2.0');
    const [threeSixtyPrice, setThreeSixtyPrice] = useState(-1);
    const [customerDefaultDimensionSettings, setCustomerDefaultDimensionSettings] = useState({});
    const [customerDefaultDimDPI, setCustomerDefaultDimDPI] = useState('300');
    const [customerDefaultDimResolution, setCustomerDefaultDimResolution] = useState('2k');
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const [showTransferButton, setShowTransferButton] = useState(false);
    const [complaints, setComplaints] = useState([]);
    const [complaintsView, setComplaintsView] = useState(false);
    const [sellVisible, setSellVisible] = useState(false);
    const [removeFromStore, setRemoveFromStore] = useState(false);
    const [variantModels, setVaraintModels] = useState([]);
    const [imgAccessedFromCarousel, setImgAccessedFromCarousel] = useState(false);
    const [dimensionalImageExists, setDimensionalImageExists] = useState(false);
    const [isStorePage, setIsStorePage] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [variationType, setVariationType] = useState('color');
    const [restrictedItem, setRestrictedItem] = useState(false);
    const [baseVariantPlatform, setBaseVariantPlatform] = useState('aws');
    const [prices, setPrices] = useState([]);
    const [ifIframeLoadedOnce, setIfIframeLoadedOnce] = useState(false);
    const [moreStoreModels, setMoreStoreModels] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [variantSelected, setVariantSelected] = useState(false);
    const [variationProductData, setVariationProductData] = useState(null);
    const [variationAssetData, setVariationAssetData] = useState(null);
    const [variationModelDepth, setVariationModelDepth] = useState(false);
    const [variationModelWidth, setVariationModelWidth] = useState(false);
    const [variationModelHeight, setVariationModelHeight] = useState(false);
    const [variationSavedID, setVariationSavedID] = useState(-1);
    const [savedVariationProductDetails, setSavedVariationProductDetails] = useState(null);
    const [selectedArVariationType, setSelectedArVariationType] = useState('');
    const [selectedVariationType, setSelectedVariationType] = useState('color')
    const [selectedProductIdForCollection, setSelectedProductIdForCollection] = useState(null);
    const [collectionModalVisible, setCollectionModalVisible] = useState(false);
    const [showProductDetails, setShowProductDetails] = useState(null);
    const [productID, setProductID] = useState(-1);
    const [currentId, setCurrentId] = useState(-1);
    const [currentCustomer, setCurrentCustomer] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [statusLoader, setStatusLoader] = useState(false);
    const [isALL3DEntity, setIsALL3DEntity] = useState(true);
    const [companyDomain, setCompanyDomain] = useState('');
    const [companyUsers, setCompanyUsers] = useState([])
    const [publicLinks, setPublicLinks] = useState({})
    const [selectedImgOriginalFormat,setSelectedImgOriginalFormat] = useState(null);
    const [selectedImgFilename, setSelectedImgFilename] = useState(null);
    const [userAccess, setUserAccess] = useState(null)
    const [siloData, setSiloData] = useState(null)
    const[selectedImageResolution, setSelectedImageResolution] = useState(null)
    const[selectedImageFormat, setSelectedImageFormat] = useState(null)
    const[selectedImageName, setSelectedImageName] = useState(null)
    const[selectedImageCapturedBy, setSelectedImageCapturedBy] = useState(null)
    const[selectedImageCreationDate, setSelectedImageCreationDate] = useState(null)
    const[favImages, setFavImages] = useState([])
    const [selectedImageType, setSelectedImageType] = useState(null) // silo, lifestyle or preview
    const [modelStatus, setModelStatus] = useState(null);
    const [isAiModel, setIsAiModel] = useState(false);
    const [aiHeroShot, setAiHeroShot] = useState(null);
    const [allAiImages, setAllAiImages] = useState([])
    const [aiRefineModalOpen, setAiRefineModalOpen] = useState(false);
    const [aiLifestyleModalOpen, setAiLifestyleModalOpen] = useState(false);
    const [productPlatform, setProductPlatform] = useState(null);
    const { match: { params } } = props;

    useEffect(() => {
        setSequenceIds();
        fetchProductInfo();
        fetchCustomerGroups();
        setProductPageType();
        getCompanyUsersEmails();
        getAllSilosOfProduct()
    }, [props.product_id]);

    useEffect(() => {
        if(siloData && productAssetData){
            let temp = productAssetData
            temp['silo_data'] = siloData
            setProductAssetData(temp)
        }
    }, [siloData, productAssetData])

    useEffect(() => {
        let payload = {
          action: 'get',
          favorited_by: CUSTOMER_USERNAME
        }
        axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload)
          .then((res) => {
            setFavImages(res.data)
          })
    }, [])

    useEffect(() => {
        if(favImages && favImages.length > 0 && allProductImages.length > 0 && !('isFavourite' in allProductImages[0])){
            let images = allProductImages.map(item => {
                let obj = {
                    ...item
                }
                if(item.main_type != 'lifestyle'){
                    obj.product_id = params.id
                }
                return obj
            })
            let favDetailsAdded = Utilities.addFavDetailsToImages(images, favImages, 'lifestyle')
            favDetailsAdded = Utilities.addFavDetailsToImages(favDetailsAdded, favImages, 'silo')
            setAllProductImages(favDetailsAdded)
        }
    }, [favImages, allProductImages])

    useEffect(() => {
        Utilities.getUserAccess('product', params.id, setUserAccess)

        let links = {
            'URL for Public Viewer': ENVIRONMENT.PORTAL_LINK + 'product-details/' + params.id + '/' + localStorage.getItem("username"),
            'URL for Product 360': ENVIRONMENT.PORTAL_LINK + 'product_public_viewer/product_360/' + params.id + '/' + localStorage.getItem("username") + (companyId && companyId != -1 ? ("?company_id=" + companyId) : ""),
            'URL for Product 3D Model': ENVIRONMENT.PORTAL_LINK + 'product_public_viewer/product_3d_model/' + params.id,
            'Embed in your site': '<iframe allow-popups style="width: 700px; height: 700px;" src="' + 'http://app.all3d.ai/product-details/' + params.id + "/" + localStorage.getItem("username") + '"></iframe>'
        }
        setPublicLinks(links)
    }, [companyId])

    useEffect(() => {
        getAllVariantsOfProduct();
    }, [params.id, productData]);

    useEffect(() => {
        getMoreStoreProducts();
    }, [productData]);


    const getAllSilosOfProduct = (project_ids = null) => {
        const urlParams = new URLSearchParams(window.location.search);
        if(!project_ids && urlParams.get('project_id')){
            project_ids = [urlParams.get('project_id')];
        }
        
        if(params.id && project_ids && project_ids.length > 0){
            axios.post(ENVIRONMENT.GET_PROJECTS_SILOS, {
                product_id: params.id,
                project_ids: project_ids
            }).then(res => {
                setSiloData(res.data)
            })
        }
    }


    const getProductData = (id, company_id = undefined, username = undefined, asset_required = false, product_data_required = false) => {
        if (product_data_required) {
            getSavedProduct(id);
            setStatusLoader(true);
            let payload = { product_id: id };
            axios.post(ENVIRONMENT.FETCH_PRODUCT, payload)
                .then(res => {
                    setVariationProductData(res.data);
                    if (res.data.variation_type) {
                        setSelectedVariationType(res.data.variation_type);
                        setStatusLoader(false);
                    }
                })
        }
        else {
            let data = { company_id: company_id };
            setVariationProductData(data);
        }
        if (asset_required) {
            let payload = {}
            if (IS_MS_PROVIDER) {
                payload = { product_id: params.id, username: MANAGED_CUSTOMER_USERNAME }
                if (MANAGED_COMPANY_ID){
                    payload.company_id = MANAGED_COMPANY_ID
                }
            } else {
                payload = { product_id: params.id, username: CUSTOMER_USERNAME }
                if (company_id != null) {
                    payload.company_id = company_id
                }
            }
            axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
                .then(res => {
                    setVariationAssetData(res.data);
                    getNewModelWidths(res.data);
                    setStatusLoader(false);
                });
        }
    }

    const getCompanyUsersEmails = () => {
        let emails = []
        if(MANAGED_CUSTOMER_USERNAME){
          emails.push({
              'email': MANAGED_CUSTOMER_EMAIL,
              'username': MANAGED_CUSTOMER_USERNAME
          })
          setCompanyUsers(emails)
        }

        if(COMPANY_ID){
            let payload = {
                "required_fields": ["email", "username"],
                "filter_string": `(company_id__exact=${COMPANY_ID})`,
                "order_by": "email asc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
            .then(res => {
                if(res && res.data){
                    console.log('Company User Emails', res.data);
                    emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                    setCompanyUsers(emails)
                  }
            })
        }
      }

    const getNewModelWidths = (product_asset_data) => {
        let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
        if (product_asset_data.model_info) {
            // If model_info has low poly info
            if (product_asset_data.model_info.low) {
                model_height = product_asset_data.model_info.low.height || "N/A";
                model_depth = product_asset_data.model_info.low.depth || "N/A";
                model_width = product_asset_data.model_info.low.width || "N/A";
            }
            else if (product_asset_data.model_info.high) {
                // if model_info has high poly info
                model_height = product_asset_data.model_info.high.height || "N/A";
                model_depth = product_asset_data.model_info.high.depth || "N/A";
                model_width = product_asset_data.model_info.high.width || "N/A";
            }
            else {
                // for backward compatibility, where model_info only contains model_dimenions and vertex count
                model_height = product_asset_data.model_info.height || "N/A";
                model_depth = product_asset_data.model_info.depth || "N/A";
                model_width = product_asset_data.model_info.width || "N/A";
            }
        } else if (model_depth == 'N/A' && model_height == 'N/A' && model_height == 'N/A' && product_asset_data.width && product_asset_data.height && product_asset_data.length) {
            model_depth = parseInt(product_asset_data.length);
            model_height = parseInt(product_asset_data.height);
            model_width = parseInt(product_asset_data.width);
        }

        setVariationModelDepth(model_depth);
        setVariationModelHeight(model_height);
        setVariationModelWidth(model_width);
    }


    const setSequenceIds = (sequence_ids = SEQUENCE_IDS) => {
        if (sequence_ids) {
            sequence_ids = JSON.parse(sequence_ids)
            sequence_ids.map((sequence_data) => {
                if (sequence_data['entity_type'] == 'owned_product') {
                    SEQUENCE_ID_OWNED = sequence_data['sequence_id']
                } else if (sequence_data['entity_type'] == 'other_product') {
                    SEQUENCE_ID_OTHER = sequence_data['sequence_id']
                }
            })
        }
    }

    const setProductPageType = () => {
        //load store page detaills if link is for product-assets, otherwise load all details
        if (props && props.match) {
            if (props.match.path.includes("products")) {
                setIsStorePage(false);
            }
            else if (props.match.path.includes("product-assets")) {
                setIsStorePage(true);
            }
        }
    }

    const redirectPage = () => {
        if (!isStorePage) {
            window.location.href = '/list-products?entity_id=' + params.id;
        } else {
            window.location.href = '/store';
        }
    }

    useEffect(() => {
        if (productData != null) {
            getAllLinks();
            getAllReferenceImages();
        }
    }, [productData]);

    useEffect(() => {
        if (productAssetData != null) {
            getGlbSize();
            getArchiveSize();
            checkCryptomatte();
            getCategoriesData();
        }
    }, [productAssetData]);

    useEffect(() => {
        if (productAssetData != null) {
            getDimensionalData();
            allSystemGeneratedProductImages();
            checkIf360Exists();
            checkIfMp4VideoExists();
        }
    }, [productAssetData, glbLength, archiveLength]);

    useEffect(() => {
        getComplaintsData();
    }, [params.id]);

    useEffect(() => {
        if (isAiModel) {
            fetchAiAssets(params.id)
        }
    },[isAiModel])

    const checkPendingAiRenders = (renders) => {
        if (renders.length > 0) {
            let pending = false;
            renders.map((image) => {
                if (image.status === 'pending') {
                    pending = true;
                }
            });
            return pending;
        }
    }

    const fetchAiAssets= (product_id, isCheckPendingRenders = false) => {
        if (!product_id) {
          return;
        }
        axios.post(ENVIRONMENT.AI_MODEL_HELPER, {
          'action': 'get_ai_assets',
          'product_id': product_id,
          'username': CUSTOMER_USERNAME,
        })
        .then((res) => {
          if (res.data) {
            const image_list = [];
            let isHeroShot = false;
            if (res.data.thumbnail && !isCheckPendingRenders) {
                setHeroShot(ENVIRONMENT.getBaseURL('aws') + "product_assets/thumbnail/" + res.data.thumbnail);
            }
            res.data.lifestyle_renders.map((lifestyle) => {
                image_list.push({
                    "format": 'jpg',
                    "url": lifestyle.uri ? ENVIRONMENT.getBaseURL('aws') + lifestyle.uri  : RenderInProgressImage,
                    "low_url": lifestyle.uri ? ENVIRONMENT.getBaseURL('aws') + lifestyle.uri : RenderInProgressImage, 
                    "type": 'ai_lifestyle',
                    "main_type": 'lifestyle',
                    "status": lifestyle.status === 'completed' ? 'rendered' : 'pending',
                    "filename": lifestyle.uri,
                    "display_name": lifestyle.prompt_text,
                    "resolution": '',
                    "created_at": '',
                    "id": lifestyle.id
                });
            });
            
            setAllAiImages(image_list);
            // if (isCheckPendingRenders) {
            //     checkAIRendersUpdate(image_list, res.data.thumbnail);
            // }
            
            // if (checkPendingAiRenders(image_list)) {
            //     setTimeout(() => fetchAiAssets(product_id, true), 40000);
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const checkAIRendersUpdate = (renders, thumbnail) => {
        let allImages = allProductImages.filter((image) => image.type !== 'ai_lifestyle');
        console.log('All Images after', allImages)
        let image_list = allImages.concat(renders);
        if (thumbnail) {
            setHeroShot(ENVIRONMENT.getBaseURL('aws') + "product_assets/thumbnail/" + thumbnail);
        }
        setProductImageCount(image_list.length);
        setAllProductImages(image_list);
        createClasses(image_list);
    };

    const fetchAIImages = (images) => {
        let image_list = [];
        image_list = images.concat(allAiImages);
        setProductImageCount(image_list.length);
        setAllProductImages(image_list);
        createClasses(image_list);
    }

    const getProductPayload = (store_products = false) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "thumbnail", "company_id",
                "price", "customer_username", "variant_of", "immediate_parent_variant", "last_modified_stamp",
                "is_store_item", "platform", "user_render_count"],
        }
        payload["order_by"] = "last_modified_stamp desc"

        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let asset_username = "asset_username__exact='" + CUSTOMER_USERNAME + "'||asset_username__isnull=true";
        let asset_company = "";
        let variation_condition = "(variant_of__exact=" + params.id + "||" + "immediate_parent_variant__exact=" + params.id + ")";
        let company_id = "";
        let model_status = "model_status__in=[5]";
        let is_hidden = "is_hidden__not=true";
        let is_store_item = "is_store_item__in=[1]"

        if (store_products) {
            company_id = "company_id__exact=" + productData.company_id;
            asset_company = "asset_company__exact=" + productData.company_id + "||asset_company__isnull=true";
            filter_string = "(" + company_id + ")&&(" + is_hidden + ")&&(" + is_store_item + ")";
            filter_string = filter_string + "&&(" + model_status + ")&&(" + asset_username + ")&&(" + asset_company + ")";
            payload['pagination_filters'] = {
                'limit': 8,
                'offset': 0
            }
        } else {
            if (COMPANY_ID) {
                payload['required_fields'].push('company_render_count');
                payload['required_fields'].push('company_lifestyle_render_count');
                asset_company = "&&(asset_company__exact=" + COMPANY_ID + "||asset_company__isnull=true)";
            }

            if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("product")) {
                company_id = "company_id__exact=" + COMPANY_ID;
                filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")&&(" + asset_username + ")" + asset_company;
            } else {
                if (FileConstants.isMSProvider) {
                    company_id = "company_id__isnull=true"
                    let requested_for = "requested_for__isnull=true"
                    filter_string = "(" + company_id + "&&" + customer_username + ")&&(" + is_hidden + ")&&(" + requested_for + ")&&(" + asset_username + ")"
                } else {
                    filter_string = "(" + customer_username + "&&" + is_hidden + ")&&(" + asset_username + ")" + asset_company
                }
            }
            filter_string = filter_string + "&&(" + model_status + ")&&" + variation_condition;

        }

        payload['filter_string'] = filter_string

        return payload;
    }

    const getAllVariantsOfProduct = () => {
        let payload = getProductPayload();
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then(res => {
                if (res && res.data) {
                    setVaraintModels(res.data);
                }
            });
    }

    const getMoreStoreProducts = () => {
        if (productData && productData.company_id) {
            let payload = getProductPayload(true);
            axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
                .then(res => {
                    if (res && res.data) {
                        setMoreStoreModels(res.data);
                    }
                });
        }
    }


    const getComplaintsData = () => {
        const query = new URLSearchParams(props.location.search);
        let viewComplaintPage = query.get('complaints')
        axios.post(ENVIRONMENT.GET_COMPLAINT_BATCH, {
            entity_id: params.id,
            complaint_against: "Product"
        })
            .then(res => {
                console.log('COMPLAINTS AGAINST SILO = ', res)
                let complaints = []
                for (let complaint of res.data) {
                    complaints.push({
                        'id': complaint['id'],
                        'status': complaint['status'],
                        'issue': complaint['issue'],
                        'submitted_on': complaint['submitted_on'],
                        'admin_message': complaint['admin_message'],
                        'attachment': complaint['attachment']
                    })
                }
                setComplaints(complaints)
                if (viewComplaintPage) {
                    setComplaintsView(true)
                }
            }).catch(err => {
                console.log(err, ' Error in getting complaints against this product');
            })
    }


    // get folder id of product from parameters and set it
    const getURLParamsAndSetFolderParentID = () => {
        const url = new URL(window.location);
        let id = new URLSearchParams(url.search).get("folder_id");
        setFolderParentID(id);
    };

    useEffect(() => {
        getURLParamsAndSetFolderParentID();
    }, [folderParentID]);

    const getCategoriesData = () => {
        const payload = {
            output: 'tree',
            disable_parents: 'true'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setCategoriesData(res.data);
            });
    }

    const checkCryptomatte = () => {
        if (productAssetData != null) {
            if (productAssetData['2d'] != undefined && productAssetData['2d'].length > 0) {
                productAssetData['2d'].map((url) => {
                    let splitted_url = url.split('/')
                    let last_token = splitted_url[splitted_url.length - 1];
                    let image_name = last_token.split('.');
                    let image_name_w_format = image_name[0] + '_cMatte.jpg'
                    let new_url = ENVIRONMENT.getBaseURL(productAssetData.platform) + ENVIRONMENT.QA_IMG_URI + image_name_w_format;
                    if (image_name[0].includes('_Dimensions')) {
                        new_url = ENVIRONMENT.getBaseURL(productAssetData.platform) + ENVIRONMENT.IMAGE_DIMENSIONAL_URI + image_name_w_format;
                    }
                    if (!cryptoMatteExists) {
                        axios.head(new_url, {})
                            .then(res => {
                                setCryptoMatteExists(true);

                                if (image_name[0].includes('_Dimensions')) {
                                    setDimensionCrypto(true);
                                }
                            })
                    }
                });
            }
        }
    }


    const checkIf360Exists = () => {
        if (productAssetData.threesixty_spin_details != undefined) {
            if (Object.entries(productAssetData.threesixty_spin_details).length !== 0) {
                setStatusOf360Spin(productAssetData['threesixty_spin_details'].asset_status);
            } else {
                setStatusOf360Spin('');
            }
        } else {
            setStatusOf360Spin('');
        }
    }

    const checkIfMp4VideoExists = () => {
        if (productAssetData.mp4_details != undefined) {
            if (Object.entries(productAssetData.mp4_details).length !== 0) {
                setStatusOfMp4Video(productAssetData['mp4_details'].asset_status);
            } else {
                setStatusOfMp4Video('');
            }
        } else {
            setStatusOfMp4Video('');
        }
    }


    const getGlbSize = () => {
        let link = getDownloadUrl('GLB');
        let size = 0;
        if (link) {
            axios.head(link).then(res => {
                if (res.headers['content-length']) {
                    size = parseInt(res.headers['content-length']);
                    setGlbLength(size);
                }
            });
        }
    }

    const getArchiveSize = () => {
        let link = getDownloadUrl('MAX');
        let size = 0;

        if (link) {
            axios.head(link).then(res => {
                if (res.headers['content-length']) {
                    size = parseInt(res.headers['content-length']);
                    setArchiveLength(size);
                }
            });
        }
    }


    const getUSDZUrl = () => {
        let link = getDownloadUrl('USDZ');
        if (link) {
            let shortened_link = '';
            if (productData['platform'] == 'aws') {
                shortened_link = window.location.origin + '/usdz/' + params.id;
            } else {
                shortened_link = window.location.origin + '/usdz/g/' + params.id;
            }
            return shortened_link;
        } else {
            return '';
        }
    }

    const getAndroidUrl = () => {
        let link = getDownloadUrl('GLB');

        if (link) {
            let shortened_link = window.location.origin + '/android/' + params.id;
            return shortened_link.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(productData['platform']))
        } else {
            return '';
        }
    }

    const convertByteSize = (size_in_mbs) => {
        let result = size_in_mbs.toFixed(2) + " MBs";
        if (size_in_mbs / 1024 > 1024) // means more then 1 GB
        {
            size_in_mbs = (size_in_mbs / (1024)).toFixed(1); // size in GBs
            result = size_in_mbs + " GBs";
        }
        return result;
    }


    const getDimensionalData = () => {
        let high_poly_model_size = 0;
        let high_poly_texture_size = 0;
        let low_poly_model_size = 0;
        let low_poly_texture_size = 0;
        let total_file_size = 0;
        let glb_size = 0;
        if (productAssetData.model_info != undefined) {
            high_poly_model_size = productAssetData.model_info.size_high_poly_model || 0;
            high_poly_texture_size = productAssetData.model_info.size_high_poly_textures || 0;
            low_poly_model_size = productAssetData.model_info.size_low_poly_model || 0;
            low_poly_texture_size = productAssetData.model_info.size_low_poly_textures || 0;
            glb_size = productAssetData.model_info.glb_size || 0;
            if (glb_size == 0) {
                glb_size = glbLength / (1024 * 1024);
            }
            total_file_size = high_poly_model_size + high_poly_texture_size + low_poly_model_size + low_poly_texture_size + glb_size;
        }

        if (productAssetData.model_info == undefined || total_file_size == 0 || total_file_size == glb_size) {

        }

        let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
        if (productAssetData.model_info) {
            // If model_info has low poly info
            if (productAssetData.model_info.low) {
                model_height = productAssetData.model_info.low.height || "N/A";
                model_depth = productAssetData.model_info.low.depth || "N/A";
                model_width = productAssetData.model_info.low.width || "N/A";
            }
            else if (productAssetData.model_info.high) {
                // if model_info has high poly info
                model_height = productAssetData.model_info.high.height || "N/A";
                model_depth = productAssetData.model_info.high.depth || "N/A";
                model_width = productAssetData.model_info.high.width || "N/A";
            }
            else {
                // for backward compatibility, where model_info only contains model_dimenions and vertex count
                model_height = productAssetData.model_info.height || "N/A";
                model_depth = productAssetData.model_info.depth || "N/A";
                model_width = productAssetData.model_info.width || "N/A";
            }
        } else if (model_depth == 'N/A' && model_height == 'N/A' && model_height == 'N/A' && productAssetData.width && productAssetData.height && productAssetData.length) {
            model_depth = parseInt(productAssetData.length);
            model_height = parseInt(productAssetData.height);
            model_width = parseInt(productAssetData.width);
        }

        if (isAiModel) {
            model_height = productAssetData.height || "N/A";
            model_depth = productAssetData.depth || "N/A";
            model_width = productAssetData.width || "N/A";
        }

        setModelDepth(model_depth);
        setModelHeight(model_height);
        setModelWidth(model_width);

        let high_poly_vertex = null, low_poly_vertex = null;
        if (productAssetData.model_info) {
            if (productAssetData.model_info.low) {
                low_poly_vertex = productAssetData.model_info.low.vertex_count;
            }
            else if (productAssetData.need_to_model == 'ar') {
                // for backward compatibility
                low_poly_vertex = productAssetData.model_info.vertex_count;
            }

            if (productAssetData.model_info.high) {
                high_poly_vertex = productAssetData.model_info.high.vertex_count;
            }
            else if (productAssetData.need_to_model != 'ar') {
                // for backward compatibility
                high_poly_vertex = productAssetData.model_info.vertex_count;
            }
        }

        if (total_file_size > 0 && (total_file_size != glb_size)) {
            setTotalFileSize(convertByteSize(total_file_size));
            if (high_poly_model_size) {
                setHighPolyModelSize(convertByteSize(high_poly_model_size));
            }
            if (high_poly_texture_size) {
                setHighPolyTextureSize(convertByteSize(high_poly_texture_size));
            }
            if (low_poly_model_size) {
                setLowPolyModelSize(convertByteSize(low_poly_model_size));
            }
            if (low_poly_texture_size) {
                setLowPolyTextureSize(convertByteSize(low_poly_texture_size));
            }
            if (glb_size > 0) {
                setGlbSize(convertByteSize(glb_size));
            }
        } else if (archiveLength > 0 || glbLength > 0) {
            setTotalFileSize(convertByteSize((archiveLength + glbLength) / (1024 * 1024)));
            if (archiveLength > 0) {
                setArchiveSize(convertByteSize(archiveLength / (1024 * 1024)));
            }
            if (glbLength > 0) {
                setGlbSize(convertByteSize(glbLength / (1024 * 1024)));
            }
        }

        if (high_poly_vertex) {
            setHighPolyVertexCount(parseInt(high_poly_vertex).toLocaleString());
        }
        if (low_poly_vertex) {
            setLowPolyVertexCount(parseInt(low_poly_vertex).toLocaleString())
        }
        
        let metadata;
        if (props.productReducer.product.metadata && !Array.isArray(props.productReducer.product.metadata)) { 
            metadata = JSON.parse(props.productReducer.product.metadata)       
            setLowPolyTriangleCount(metadata?.metadata?.low?.triangle_count);
            setHighPolyTriangleCount(metadata?.metadata?.high?.triangle_count);   
        }
    }

    const getAllLinks = () => {
        let links = [];
        if (productData.reference_urls && productData.reference_urls.length > 0) {
            productData.reference_urls.map((link) => {
                links.push(link);
            })
        }
        if (productData.product_manuals && productData.product_manuals.length > 0) {
            productData.product_manuals.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                links.push(new_link);
            })
        }
        if (productData.raw_files && productData.raw_files.length > 0) {
            productData.raw_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                links.push(new_link);
            })
        }
        if (productData.scans && productData.scans.length > 0) {
            productData.scans.map((link) => {
                let new_link = SCANS_BASE_URL + encodeURIComponent(link);
                links.push(new_link);
            })
        }
        if (productData.model_files && productData.model_files.length > 0) {
            productData.model_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                links.push(new_link);
            })
        }

        setReferenceLinks(links);
    }

    const getAllReferenceImages = () => {
        let reference_images = [];
        let product_photos = []
        if (productData.product_photos != undefined) {
            Object.keys(productData.product_photos).forEach((key) => {
                let photos_array = productData.product_photos[key];
                if (!Array.isArray(photos_array)) {
                    if (key in photos_array) {
                        photos_array = productData.product_photos[key][key];
                    }
                    else {
                        photos_array = [];
                    }
                }
                let product_photos_subset = (photos_array || []).map((photo) => {
                    let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name);
                    reference_images.push({
                        'link': file_url,
                        'type': 'reference_image',
                        'format': Utilities.getImageFormat(photo.name)
                    });
                });

                if (key == 'product_photos_all') {
                    product_photos.unshift(product_photos_subset);
                }
                else {
                    product_photos.push(product_photos_subset);
                }

                if (productData.product_photos && Array.isArray(productData.product_photos) && product_photos && product_photos[0] && product_photos[0].length == 0) {
                    product_photos = []
                    productData.product_photos.map((photo) => {
                        let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name);
                        reference_images.push({
                            'link': file_url,
                            'type': 'reference_image',
                            'format': Utilities.getImageFormat(photo.name)
                        });
                    });
                }
            });
        }

        if (productData.model_files && productData.model_files.length > 0) {
            productData.model_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                reference_images.push({
                    'link': new_link,
                    'type': 'model_file',
                    'format': Utilities.getImageFormat(link.name)
                });
            })
        }
        setProductRefImages(reference_images);
        getCategoryAttachments(reference_images);
    }

    const getCategoryAttachments = (images) => {
        let category_attachment_links = images;
        if (productData.category_attachment && productData.category_attachment.length > 0) {
            productData.category_attachment.map((image) => {
                let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                category_attachment_links.push({
                    'link': file_url,
                    'type': 'category_attachment',
                    'format': Utilities.getImageFormat(image.name)
                })
            })
        }
        setProductRefImages(category_attachment_links);
        getMaterialFiles(category_attachment_links);
    }

    const getMaterialFiles = (images) => {
        let material_files = images;
        if (productData.material_files && productData.material_files.length > 0) {
            productData.material_files.map((image) => {
                let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name)
                material_files.push({
                    'link': file_url,
                    'type': 'material_file',
                    'format': Utilities.getImageFormat(image.name)
                })
            })
        }
        setProductRefImages(material_files);
        getLibraryMaterial(material_files);
    }

    const getLibraryMaterial = (images) => {
        let library_materials = images;
        if (libraryMaterial && libraryMaterial.length > 0) {
            libraryMaterial.map(image => {
                if (image.renders.data && image.renders.data.thumbnail) {
                    let file_url = MATERIAL_FILE_URL + image.id + '/' + encodeURIComponent(image.renders.data.thumbnail);
                    library_materials.push({
                        'link': file_url,
                        'type': 'material_file',
                        'format': Utilities.getImageFormat(image.name)
                    })
                }
            });
        }
        setProductRefImages(library_materials);
        getColorPhotos(library_materials);
    }


    const getColorPhotos = (images) => {
        let other_photos = images;
        if (productData.color_photos && productData.color_photos.length > 0) {
            productData.color_photos.map(image => {
                let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                other_photos.push({
                    'link': file_url,
                    'type': 'color_photos',
                    'format': Utilities.getImageFormat(image.name)
                })

            });
        }
        setProductRefImages(other_photos);
    }


    const download360Frames = () => {
        let downloadData = [];
        if (productAssetData && productAssetData['360']) {
            let length360 = productAssetData['360']['data-amount']
            let i = 1
            for (i = 1; i <= length360; i++) {
                downloadData.push(productAssetData['360']['data-folder'] + params.id + '_Frame' + i + '.jpg');
            }
            Utilities.saveToZip(params.id + '_360 Frames.zip', downloadData, true);
        }
    }

    const downloadImagesArchive = () => {
        let downloadData = [];
        let customNames = [];
    
        if (productAssetData) {
            // Process '2d' images
            if (productAssetData['2d'] && productAssetData['2d'].length > 0) {
                productAssetData['2d'].map((url) => {
                    let new_url = url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productAssetData['platform']));
                    downloadData.push(new_url);
                    customNames.push(null); // No custom name for '2d' images
                });
            }
    
            // Process 'silo_data' images
            if (productAssetData['silo_data'] && productAssetData['silo_data'].length > 0) {
                productAssetData['silo_data'].map((url) => {
                    if (url.image_status == 'completed' && (!url.hasOwnProperty('is_hidden') || url.is_hidden !== true)) {
                        let downloadUrl = '';
                        if (url.company_id || url.username) {
                            downloadUrl = getBaseURL(productAssetData['platform']) + QA_IMG_URI + params.id + '/' + (url.company_id == null ? url.username : ('company/' + url.company_id)) + '/' + url.camera_name + "." + url.image_data.img_format;
                        } else {
                            downloadUrl = getBaseURL(productAssetData['platform']) + QA_IMG_URI + params.id + '/' + (companyId == null ? props.cognito.user.username : ('company/' + companyId)) + '/' + url.camera_name + "." + url.image_data.img_format;
                        }
    
                        downloadData.push(downloadUrl);
                        // Add custom name if exists, otherwise use the default naming convention
                        customNames.push(url.camera_display_name ? `${url.camera_display_name}.${url.image_data.img_format}` : null);
                    }
                });
            }
    
            // Call saveToZip with the custom names array
            Utilities.saveToZip(params.id + '_Product_Images.zip', downloadData, true, customNames);
        }
    }
    

    const downloadSiloImages = () => {
        let downloadData = [];
        let customNames = [];
        console.log('productAssetData = ', productAssetData['silo_data']);
        if (productAssetData && productAssetData['silo_data'] && productAssetData['silo_data'].length > 0) {
            productAssetData['silo_data'].map((url) => {
                if (url.image_status === 'completed' && (!url.hasOwnProperty('is_hidden') || url.is_hidden !== true)) {
                    let downloadUrl = '';
                    if (url.company_id || url.username) {
                        downloadUrl = getBaseURL(productAssetData['platform']) + QA_IMG_URI + params.id + '/' + (url.company_id == null ? url.username : ('company/' + url.company_id)) + '/' + url.camera_name + "." + url.image_data.img_format;
                    } else {
                        downloadUrl = getBaseURL(productAssetData['platform']) + QA_IMG_URI + params.id + '/' + (companyId == null ? props.cognito.user.username : ('company/' + companyId)) + '/' + url.camera_name + "." + url.image_data.img_format;
                    }
                    
                    downloadData.push(downloadUrl);
                    console.log("Checking", url)
                    // Add custom name if exists, otherwise use the default naming convention
                    customNames.push(url.camera_display_name ? `${url.camera_display_name}.${url.image_data.img_format}` : null);
                }
            });
            Utilities.saveToZip(params.id + '_Silos.zip', downloadData, true, customNames);
        }
    }
    

    const downloadCryptoImagesArchive = () => {
        if (cryptoMatteExists) {
            let downloadData = [];
            if (productAssetData) {
                if (productAssetData['2d'] && productAssetData['2d'].length > 0) {
                    productAssetData['2d'].map((url) => {
                        let splitted_url = url.split('/')
                        let last_token = splitted_url[splitted_url.length - 1];
                        let image_name = last_token.split('.');
                        let image_name_w_format = image_name[0] + '_cMatte.jpg';
                        let new_url = getBaseURL(productAssetData['platform']) + QA_IMG_URI + image_name_w_format;

                        if (!image_name[0].includes('_Debug') && !image_name[0].includes('_Dimensions')) {
                            downloadData.push(new_url);
                        }
                        if (image_name[0].includes('_Dimensions') && dimensionCrypto) {
                            new_url = getBaseURL(productAssetData['platform']) + IMAGE_DIMENSIONAL_URI + image_name_w_format;
                            downloadData.push(new_url);
                        }

                    });

                }
                if (productAssetData['silo_data'] && productAssetData['silo_data'].length > 0) {
                    productAssetData['silo_data'].map((url) => {
                        if (url.image_status == "completed" && (!url.hasOwnProperty('is_hidden') || url.is_hidden !== true)) {
                            let new_url = getBaseURL(productAssetData['platform']) + QA_IMG_URI + params.id + '/' + (companyId == null ? props.cognito.user.username : ('company/' + companyId)) + '/' + url.camera_name + "_cMatte.jpg"
                            downloadData.push(new_url);
                        }
                    });
                }
                Utilities.saveToZip(params.id + '_Cryptomatte.zip', downloadData, true);
            }
        } else {
            message.error("Cryptomatte for this product aren't available.");
        }
    }


    const downloadReferences = () => {
        let reference_files = [];
        if (productRefImages && productRefImages.length > 0) {
            productRefImages.map((img) => {
                reference_files.push(img.link);
            })
        }
        if (productData.model_files && productData.model_files.length > 0) {
            productData.model_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                reference_files.push(new_link);
            })
        }
        if (reference_files && reference_files.length > 0) {
            Utilities.saveToZip(params.id + '_References.zip', reference_files, true);
        } else {
            message.info('No references to download');
        }
    }


    useEffect(() => {
        //This function is responsible to set dimentional setting default values to customer default values if set on backend
        axios.post(ENVIRONMENT.GET_CUSTOMER_DEFAULT_SETTINGS, { customer_username: CUSTOMER_USERNAME })
            .then(res => {
                if (res.data.dimensional_settings != undefined) {
                    setCustomerDefaultDimensionSettings(res.data.dimensional_settings);
                    if (res.data.dimensional_settings.dpi != undefined) {
                        setCustomerDefaultDimDPI(res.data.dimensional_settings.dpi);
                    }
                    if (res.data.dimensional_settings.resolution != undefined) {
                        setCustomerDefaultDimResolution(res.data.dimensional_settings.resolution);
                    }
                }
            });
    }, []);

    const openProductAssetViewer = () => {
        setProductAssetViewer(true);
    }

    const closeProductAssetViewer = () => {
        setProductAssetViewer(false);
    }

    const allSystemGeneratedProductImages = (initial = productAssetData) => {
        console.log("checking initial", initial)
        let image_list = [];
        let secondary_shots = [];

        let hero_shot = '';
        if (initial['2d'] && initial['2d'].length > 0) {
            hero_shot = initial['2d'][0];
            const dimensionsImages = initial['2d'].filter(imgObj => {
                return imgObj.includes('_Dimensions') && (imgObj.includes(CUSTOMER_USERNAME) || imgObj.includes('company'))
            })
            if (dimensionsImages.length == 1) {
                hero_shot = dimensionsImages[0];
            }
        }
        if (initial['lifestyle_data'] && initial['lifestyle_data'].length > 0){
            let lifestyles = initial['lifestyle_data'].reverse().filter(img => img.image_status == "rendered");
            if(lifestyles.length > 0){
                let lifestyle = lifestyles[0]
                hero_shot = getBaseURL(lifestyle.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + lifestyle.scene_id + '/' + lifestyle.filename
                hero_shot = hero_shot.replace('tiff', 'jpg')
            }
        }
        if (initial['silo_data'] && initial['silo_data'].length > 0) {
            let silos = initial['silo_data'].reverse().filter(img => img.image_status == "completed" && (!img.hasOwnProperty('is_hidden') || img.is_hidden !== true));

            if (silos.length > 0) {
                let silo = silos[0];

                //silos has different base URI for company/MSP and individual users, following logic caters that
                let silo_base_uri = ''
                if(silo.username || silo.company_id){
                    // if silo img dict has username or company_id use that
                    // This will be the case when all silos for the project are retrieved
                    silo_base_uri = silo.username
                    if (silo.company_id) {
                        silo_base_uri = 'company/' + silo.company_id
                    }
                }
                else{
                    silo_base_uri = props.cognito.user.username
                    if (companyId) {
                        silo_base_uri = 'company/' + companyId
                    }
                }

                hero_shot = getBaseURL(initial['platform']) + QA_IMG_URI + params.id + '/' + silo_base_uri + '/' + silo.camera_name + '.' + silo.image_data.img_format;
                hero_shot = hero_shot.replace('tiff', 'jpg')
            }
        }
        
        setHeroShot(hero_shot);
        if (initial['silo_data'] && initial['silo_data'].length > 0) {
            let silo_image = [];
            initial['silo_data'].map((img) => {
                let image_link = '';
                
                //silos has different base URI for company/MSP and individual users, following logic caters that
                let silo_base_uri = ''
                if(img.username || img.company_id){
                    // if silo img dict has username or company_id use that
                    // This will be the case when all silos for the project are retrieved
                    silo_base_uri = img.username
                    if (img.company_id) {
                        silo_base_uri = 'company/' + img.company_id
                    }
                }
                else{
                    silo_base_uri = props.cognito.user.username
                    if (companyId) {
                        silo_base_uri = 'company/' + companyId
                    }
                }

                let image_type = 'silo';
                if (img.image_status == "pending") {
                    if (img.camera_name.includes('Mount') && img.camera_name.includes('inside')) {
                        image_link = require("../../../../assets/images/insideBlind.jpg");
                        image_type = 'blind';
                    } else if (img.camera_name.includes('Mount') && !img.camera_name.includes('inside')) {
                        image_link = require("../../../../assets/images/outsideBlind.jpg");
                        image_type = 'blind';
                    } else {
                        image_type = 'silo';
                        image_link = getBaseURL(initial['platform']) + QA_IMG_URI + params.id + '/' + silo_base_uri + '/' + img.camera_name + '_preview' + '.jpg';
                    }
                } else if (img.image_status == "completed") {
                    image_link = getBaseURL(initial['platform']) + QA_IMG_URI + params.id + '/' + silo_base_uri + '/' + img.camera_name + '.' + img.image_data.img_format;
                    image_link = image_link;
                    if (img.camera_name.includes('Mount')) {
                        image_type = 'blind'
                    } else if (img.camera_name.includes('swatch')) {
                        image_type = 'swatch'
                    } else {
                        image_type = "silo";
                    }
                }
                let filename = image_link.split('/').pop();
                let display_name = img.camera_display_name ? img.camera_display_name : img.camera_name.split('.')[0];

                if(!img.hasOwnProperty('is_hidden') || img.is_hidden !== true){
                silo_image.push({
                    "format": Utilities.getImageFormat(image_link),
                    "url": image_link.replace('tiff', 'jpg'),
                    "low_url": image_link.replace('tiff', 'jpg'),
                    "type": image_type,
                    "main_type": "silo",
                    "status": img.image_status,
                    "filename": filename,
                    "display_name": display_name,
                    "camera_name": img.camera_name,
                    "resolution":img.image_data.img_resolution,
                    "created_at": new Date(Number(img.time_updated)).toDateString(),
                    "captured_by": img.captured_by
                });
            }
            });
            image_list = image_list.concat(silo_image);
            silo_image.filter(img => img.status == "completed" && img.url != hero_shot).map((img, index) => {
                if (index < 3) {
                    secondary_shots.push(img.url.replace('tiff', 'jpg'));
                }
            });

            setSecondaryShots(secondary_shots);
        }

        if (initial['lifestyle_data'] && initial['lifestyle_data'].length > 0)
        {
            let lifestyle_image = [];
            initial['lifestyle_data'].map((img) => {
                let image_link = '';
                image_link = getBaseURL(img.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + img.scene_id + '/' + img.filename
                let filename = image_link.split('/').pop();
                let display_name = img.display_name ? img.display_name : (img.camera_name.split('.')[0] || '');

                lifestyle_image.push({
                    "format": Utilities.getImageFormat(image_link),
                    "url": image_link.replace('tiff', 'jpg'),
                    "low_url": image_link.replace('tiff', 'jpg'),
                    "type": 'lifestyle',
                    "main_type": "lifestyle",
                    "status": img.status == "rendered" ? "completed" : img.status,
                    "filename": filename,
                    "display_name": display_name,
                    "scene_id": img.scene_id,
                    "resolution": img.height + '×' + img.width,
                    "created_at": new Date(Number(img.time_updated)).toDateString(),
                    "captured_by": img.captured_by
                });
            })
            lifestyle_image.sort((a, b) => {
                // Split resolution into height and width
                let [aHeight, aWidth] = a.resolution.split('×').map(Number);
                let [bHeight, bWidth] = b.resolution.split('×').map(Number);
            
                // Find the maximum dimension (height or width) for each resolution
                let aMax = Math.max(aHeight, aWidth);
                let bMax = Math.max(bHeight, bWidth);

                // Sort by the largest dimension (descending)
                if (aMax !== bMax) {
                    return bMax - aMax; // Sort by the largest dimension in descending order
                } else {
                    // If the largest dimensions are equal, sort by the other dimension (descending)
                    let aMin = Math.min(aHeight, aWidth);
                    let bMin = Math.min(bHeight, bWidth);
                    return bMin - aMin;
                }
            });
            
            image_list = image_list.concat(lifestyle_image);

            lifestyle_image.filter(img => img.status == "rendered" && img.url != hero_shot).map((img, index) => {
                if (index < 3) {
                    secondary_shots.push(img.url.replace('tiff', 'jpg'));
                }
            });

            setSecondaryShots(secondary_shots);
        }

        if (initial['2d'] && initial['2d'].length > 0 && secondary_shots.length != 3) {
            // Check if custom DIM image exists
            const dimensionsImages = initial['2d'].filter(imgObj => {
                return imgObj.includes('_Dimensions') && (imgObj.includes(CUSTOMER_USERNAME) || imgObj.includes('company'))
            })
            if (dimensionsImages.length == 1) {
                if (secondary_shots.length < 3 && dimensionsImages[0] != hero_shot) {
                    secondary_shots.push(dimensionsImages[0]);
                }
            }
            initial['2d'].map((img) => {
                if (secondary_shots.length < 3 && img != hero_shot) {
                    secondary_shots.push(img);
                }
            });
        }
        setSecondaryShots(secondary_shots);

        const fetchData = async () => {
            if (!initial['2d'][0]) {
                return;
            }
            let name = initial['2d'][0].split('/').pop().split('.')[0];
            let idBeforeUnderscore = name.split('_')[0];
        
            try {
                const jsonDataUrl = `https://d48f7equ64qjl.cloudfront.net/product_assets/perspective_renders_info/${idBeforeUnderscore}.json`;
                const response = await axios.get(jsonDataUrl);
        
                initial['2d'].map((img) => {
                    console.log('Processing:', img);
                    let type = "low_res_preview";
                    let low_url = img.replace('/perspective_renders/', '/low_res_perspective_renders/')
                                     .replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(initial.platform));
        
                    if (img.includes('_Dimensions')) {
                        type = "low_res_preview";
                        if (img.includes(CUSTOMER_USERNAME) || img.includes('company')) {
                            type = 'dim';
                            setDimensionalImageExists(true);
                        }
                        low_url = img.replace('/dimensional_renders/', '/low_res_dimensional_renders/')
                                     .replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(initial.platform));
                    }
        
                    let filename = img.split('/').pop();
                    let display_name = img.split('/').pop().split('.')[0];
                    let img_data = response.data[filename];
        
                    let width = img_data?.width || '';
                    let height = img_data?.height || '';
                    let timeUpdated = img_data?.time_updated || '';
        
                    image_list.push({
                        "format": Utilities.getImageFormat(img),
                        "url": img,
                        "low_url": low_url,
                        "type": type,
                        "main_type": type,
                        "status": "rendered",
                        "filename": filename,
                        "display_name": display_name,
                        "resolution": height + '×' + width,
                        "created_at": timeUpdated ? new Date(Number(timeUpdated)).toDateString() : '',
                    });
        
                    console.log('Width:', width || 'N/A');
                    console.log('Height:', height || 'N/A');
                    console.log('Time Updated:', timeUpdated ? new Date(Number(timeUpdated)).toDateString() : 'N/A');
                });
            } catch (error) {
                console.error('Error fetching data:', error);
        
                // Ensure image_list is populated even if the API fails
                initial['2d'].map((img) => {
                    console.log('Fallback processing', img);
                    let type = "low_res_preview";
                    let low_url = img.replace('/perspective_renders/', '/low_res_perspective_renders/')
                                     .replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(initial.platform));
        
                    if (img.includes('_Dimensions')) {
                        type = "low_res_preview";
                        if (img.includes(CUSTOMER_USERNAME) || img.includes('company')) {
                            type = 'dim';
                            setDimensionalImageExists(true);
                        }
                        low_url = img.replace('/dimensional_renders/', '/low_res_dimensional_renders/')
                                     .replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(initial.platform));
                    }
        
                    let filename = img.split('/').pop();
                    let display_name = img.split('/').pop().split('.')[0];
                    image_list.push({
                        "format": Utilities.getImageFormat(img),
                        "url": img,
                        "low_url": low_url,
                        "type": type,
                        "main_type": type,
                        "status": "rendered",
                        "filename": filename,
                        "display_name": display_name,
                        "resolution": '',
                        "created_at": '',
                    });
        
                    console.log('Width:', 'N/A');
                    console.log('Height:', 'N/A');
                    console.log('Time Updated:', 'N/A');
                });
            }
        
            // Ensure all processing is done before setting the state and calling functions
            
            let images = image_list.concat(allAiImages)
            setProductImageCount(images.length);
            setAllProductImages(images);
            createClasses(images);
            console.log('Total image count:', images.length);
        };
        setProductImageCount(image_list.length);
        setAllProductImages(image_list);
        createClasses(image_list);
        // Call fetchData once at the start
        fetchData();
        if (isAiModel) {
            fetchAIImages(image_list)
        }
    }

    const createClasses = (image_list) => {
        image_list.map((img, index) => {
            if (img.type == "low_res_preview") {
                addClassToDoc(index);
            }
        })
    }

    const addClassToDoc = (index) => {
        // Create a new <style> element
        const styleElement = document.createElement('style');

        // Define CSS class and its styles
        const cssClass = `
        .product-carousel-image-${index} {
            width: auto !important;
            height: calc(100vh - 140px) !important;
        }
        `;
        // Set the content of the <style> element to the CSS class definition
        styleElement.innerHTML = cssClass;

        // Append the <style> element to the document's head
        document.head.appendChild(styleElement);
        // setNewClass(`product-carousel-image-${index}`);
    }

    const getMetadata = () => {
        if (props.productReducer.product["metadata"] && !Array.isArray(props.productReducer.product["metadata"])) {
            return JSON.parse(props.productReducer.product['metadata']);
        }
        return null;
    }

    const open3DModelPreview = (key) => {
        if (key == '3d_preview') {
            let viewer = document.getElementById('model-viewer-3d');
            let message = {
                key: ProductPageConstants.MODEL_VIEWER_KEY,
                glbURL: getDownloadUrl("GLB"),
                metadata: getMetadata(),
                debugMode : true
            }

            if (isAiModel) {
                message['glbURL'] = getDownloadUrl("AI GENERATED GLB");
            }


            if (viewer && viewer.contentWindow) {
                // display block once the viewer loads
                if (ifIframeLoadedOnce == false) {
                    viewer.onload = () => {
                        viewer.contentWindow.postMessage(message, "*");
                        if (document.getElementById('model-inspector-frame') != undefined) {
                            document.getElementById('model-inspector-frame').style.display = 'block';
                        }
                        setIfIframeLoadedOnce(true);
                    }
                } else {
                    if (document.getElementById('model-inspector-frame') != undefined) {
                        document.getElementById('model-inspector-frame').style.display = 'block';
                    }
                }
            }
        }
        else {
            if (document.getElementById('model-inspector-frame') != undefined) {
                document.getElementById('model-inspector-frame').style.display = 'none';
            }
        }
    }


    const open360View = (key) => {
        if (key == "360_view") {
            let element = document.getElementById('container-360');
            if (imageWidth360 == 0) {
                const img = new Image();
                img.onload = function () {
                    let aspect_ratio = this.height / this.width;
                    setAspectRatio360(aspect_ratio);
                    setImageWidth360(this.width);
                    setWidthOf360(element, aspect_ratio);
                }
                img.src = (productAssetData['360'] && productAssetData['360']["data-folder"]) + params.id + '_Frame1.jpg';
            }
            if (imageWidth360 != 0) {
                setWidthOf360(element);
            }
            if (isAiModel) {
                window.CI360.init();
            }
        }

    }

    const setWidthOf360 = (element, aspect_ratio = null) => {
        let aspect = aspectRatio360;
        if (aspect_ratio != null) {
            aspect = aspect_ratio;
        }
        if (element != null) {
            element.style.visibility = "hidden";
            element.style.width = ((document.documentElement.clientHeight - 130) / aspect) + "px";
            element.style.visibility = "visible";
            setTimeout(() => {
                window.CI360.destroy();
                window.CI360.init();
            }, 10);
            forceUpdate();
        }
    }

    useEffect(() => {
        if (productAssetViewer) {
            open3DModelPreview(selectedAsset);
            open360View(selectedAsset);
        }
    }, [selectedAsset, productAssetViewer, document.getElementById('container-360'), productAssetData && productAssetData['360']]);

    const getDownloadUrl = (type) => {
        let link = (productAssetData.download_links || []).find((link) => link.display_name == type);
        if (link) {
            return link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(productAssetData['platform']));
        } else {
            return '';
        }
    }

    const fetchCustomerGroups = () => {
        let payload = {
            customer_username: FileConstants.CUSTOMER_USERNAME
        }
        axios.post(ENVIRONMENT.GROUP_IDS_GET_BATCH, payload)
            .then(res => {
                let group_ids = []
                if (res['data']) {
                    res['data'].map(item => {
                        if (item['group_id']) {
                            group_ids.push({
                                key: item['group_id'],
                                value: item['group_id'],
                                title: item['group_id'],
                            })
                        }
                    })
                }
                setCustomerGroupIds(group_ids)
            })
    }



    const fetchProductInfo = () => {
        var product_id = 0;
        product_id = params.id;
        getSavedProduct(params.id);

        const payload = {
            'product_id': product_id,
            'username': CUSTOMER_USERNAME,
            'customer_company_id': COMPANY_ID
        }
        axios.post(ENVIRONMENT.FETCH_PRODUCT, payload)
        .then(res => {
            const urlParams = new URLSearchParams(window.location.search);
            let project_ids = [];
            setModelStatus(res.data.model_status);
            if (res.data.is_ai_model) {
                setIsAiModel(true);
            }
            if(urlParams.get('project_id')){
                project_ids = [urlParams.get('project_id')]
            }
            if(project_ids.length == 0 && res.data && res.data.project_ids){
                getAllSilosOfProduct(res.data.project_ids)
            }
            if (res.data.restricted && res.data.restricted == true) {
                setRestrictedItem(true);
            }
            if (res.data.variation_type) {
                setVariationType(res.data.variation_type);
            }
            let company_id = null;
            if (res.data['company_id'] != undefined) {
                let current_company = COMPANY_ID;
                if (current_company === res.data['company_id'].toString() || FileConstants.isMSProvider) {
                    company_id = res.data['company_id'].toString();
                    setCompanyId(company_id)
                }
            }

            loadProductAssets(company_id, res.data);

            let payload = {
                product_id: params.id
            };
            axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH, payload)
                .then(res => {
                    let response = res.data;
                    if (response) {
                        response = response.body;
                        setLibraryMaterial(response);
                    }
                });

            if (res.data.variant_of != null) {
                let product_id = res.data.variant_of;
                axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
                    .then(res => {
                        setBaseVariantPlatform(res.data.platform);
                    })
            }

            if (res.data['company_id'] != undefined) {
                axios.post(ENVIRONMENT.GET_CUSTOMER_AND_COMPANY, { company_id: res.data['company_id'] })
                    .then(res => {
                        setCompanyName(res.data["company_display_name"]);
                        setIsALL3DEntity(res.data["company_display_name"] == FileConstants.ALL3D_COMPANY);
                        let company_domain = res.data["company_domain"];
                        let company_domain_name = company_domain.split('.')[0];
                        setCompanyDomain(company_domain_name);
                    })
            }
        });
        loadUserRequestStatus();
    }

    const loadPayments = () => {
        let payload = {
            "request_type": 'product',
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
            .then(res => {
                if (res.data) {
                    setPrices(res.data);
                }
            })
    }


    const loadUserRequestStatus = (requestType = null) => {
        let payload = {
            "username": CUSTOMER_USERNAME,
            "action": "check",
            "request_type": "variation_product_model",
            "category": "color"
        }
        if (requestType != null) {
            payload = {
                "username": CUSTOMER_USERNAME,
                "action": "check",
                "request_type": requestType,
            }
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
                loadPayments();

            });
    }

    const loadProductAssets = (company_id, product_data) => {

        let payload ={}
        if (IS_MS_PROVIDER) {
            payload = { product_id: params.id, username: MANAGED_CUSTOMER_USERNAME }
            if (MANAGED_COMPANY_ID){
                payload.company_id = MANAGED_COMPANY_ID
            }
        } else {
            payload = { product_id: params.id, username: CUSTOMER_USERNAME }
            if (company_id != null) {
                payload.company_id = company_id
            }
        }
        props.getProduct(payload);
        axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
            .then(res => {
                console.log("product assets", res.data);
                setProductAssetData(res.data);
                setProductData(product_data);
                setProductPlatform(product_data['platform'])
                setProductDataLoader(false);
            });
    }

    const openSingleFlagImage = (image) => {
        setSingleImage(true);
        setSelectedImageForFlag(image);
        setImageFlagModal(true);
    }

    const openFlagModal = () => {
        setSingleImage(false);
        setImageFlagModal(true);
    }

    const openShareModal = () => {
        setSharingModal(true);
    }

    const openProductEditModal = () => {
        setProductEditModal(true);
    }

    const closeVariationModal = () => {
        setVariationProductData(null);
        setSavedVariationProductDetails(null);
        setVariationAssetData(null);
        setVariationModal(false);
        setVariationStep(0);
        setVariantSelected(false);
    }

    const openVariationModal = () => {
        setVariationModal(true);
    }

    const variationCreated = () => {
        setVariationStep(0);
        setVariationModal(false);
        setRequestSubmitted(true);
        setVariationProductData(null);
        setSavedVariationProductDetails(null);
        setVariationAssetData(null);
        setVariantSelected(false);
        setSavedID(-1);
        setVariationSavedID(-1);
    }

    const variationSaved = () => {
        setVariationStep(0);
        setVariationModal(false);
        setRequestSaved(true);
        setVariationProductData(null);
        setSavedVariationProductDetails(null);
        setVariationAssetData(null);
        setVariantSelected(false);
        setSavedID(-1);
        setVariationSavedID(-1);
    }

    const onChangeVariationType = (e) => {
        setVariationType(e.target.value);
        setSelectedVariationType(e.target.value);
        if (e.target.value == 'ar_conversion') {
            let modelTypes = productAssetData.need_to_model.split("_and_");
            if (!modelTypes.includes("ar")) {
                setARVariationType('ar');
                setSelectedArVariationType('ar');
            }
            else if (!modelTypes.includes("high_pbr")) {
                setARVariationType('high_pbr');
                setSelectedArVariationType('high_pbr');
            }
            else {
                setARVariationType('');
                setSelectedArVariationType('');
            }
        }
        if (e.target.value != 'ar_conversion') {
            setARVariationType('');
            setSelectedArVariationType('');
        }
    }

    const onChangeARVariationType = (e) => {
        setARVariationType(e.target.value);
        setSelectedArVariationType(e.target.value);
    }

    const getSavedProduct = (id) => {
        if (savedID == -1) {
            let payload = {
                variant_of: id,
                model_status: "1"
            }
            axios.post(ENVIRONMENT.GET_PRODUCT_VARIANTS, payload)
                .then(res => {
                    console.log(res.data, 'Saved');
                    if (res.data && res.data[0]) {

                        if (variantSelected) {
                            setVariationSavedID(res.data[0].id);
                        } else {
                            setSavedID(res.data[0].id);
                        }
                        fetchProduct(res.data[0].id);
                    }
                }).catch(err => {
                    console.log(err, 'An error occured in getting variants');
                });
        }
    }

    const fetchProduct = (id) => {

        let product_id = id;
        console.log('Progress Previosuly Saved')

        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                console.log(res.data);
                if (variantSelected) {
                    if (res.data.variation_type) {
                        setSelectedVariationType(res.data.variation_type);
                    }
                    setSavedVariationProductDetails(res.data);
                } else {
                    if (res.data.variation_type) {
                        setVariationType(res.data.variation_type);
                    }
                    setVariationProductDetails(res.data);
                }

            });
    }

    const openVideoModal = () => {
        let pricesmp4 = [];
        if (prices.length > 0) {
            pricesmp4 = prices.filter((request) => request.request_type == 'product_video' && request.category == "1k");
        }
        if (pricesmp4 && pricesmp4[0]) {
            setMp4Price(parseInt(pricesmp4[0].price));
            setSelectedPriceCategory(pricesmp4[0].category);
        }
        setMp4DetailsModal(true);
    }

    const openDimensionalModal = () => {
        let pricesdim = [];
        if (prices.length > 0) {
            pricesdim = prices.filter((request) => request.request_type == 'product_dimensional' && request.category == dimensionalResolution);
        }
        if (pricesdim && pricesdim[0]) {
            setDimensionalPrice(parseInt(pricesdim[0].price));
            setSelectedPriceCategory(pricesdim[0].category);
        }
        setDimensionalDetailsModal(true);
    }

    const openThreeSixtyModal = () => {
        let prices360 = [];
        if (prices.length > 0) {
            if (res360 == "2.0") {
                prices360 = prices.filter((request) => request.request_type == 'product_360_spin' && request.category == "2k");
            } else {
                prices360 = prices.filter((request) => request.request_type == 'product_360_spin' && request.category == "4k");
            }
        }
        if (prices360 && prices360[0]) {
            setThreeSixtyPrice(parseInt(prices360[0].price));
            setSelectedPriceCategory(prices360[0].category);
        }
        setThreesixtyDetailsModal(true);
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
                message.success('Payment Method Updated Successfully.');
                loadUserRequestStatus();
            }
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if (child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }


    const openTransferModal = () => {
        setIsTransferModalVisible(true);
    }

    const viewComplaintsListener = () => {
        setComplaintsView(true);
        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?complaints=true';
        window.history.pushState({ path: newUrl }, '', newUrl);
    }


    const performStoreAction = (id, data = null) => {
        //remove item from store if
        if (productData.is_store_item == 1) {
            setRemoveFromStore(true);
            setShowProductDetails(data);
            setProductID(id);
        } else {
            setSellVisible(true);
            setShowProductDetails(data);
            setProductID(id);
        }

        let product_id = id.toString();
        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                console.log(res.data);
                setShowProductDetails(res.data);
            });
    }


    // Function to handle the scroll event
    const handleScroll = () => {
        // Define the scroll threshold at which you want the bottom bar to appear
        let buttonElement = document.getElementById('product-button-container');
        if (buttonElement) {
            let rect = buttonElement.getBoundingClientRect();

            const scrollThreshold = rect.top; // Adjust this value as needed
            if (scrollThreshold < 0) {
                setShowBottomBar(true);
                const element = document.querySelector('.product-action-bottom-bar');
                if (element) {
                    element.classList.add('show');
                }
            } else {
                setShowBottomBar(false);
            }
        }
    };

    // Add a scroll event listener when the component mounts
    useEffect(() => {
        let element = document.getElementById('layout-section');
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [document.getElementById('layout-section')]);

    const onAddCollectionClick = (id) => {
        setSelectedProductIdForCollection(id);
        setCollectionModalVisible(true);
    }

    const onDelete = () => {
        setDeleteLoader(true);
        let api_end_point = ENVIRONMENT.UPDATE_PRODUCT;
        let username = CUSTOMER_USERNAME;

        let payload = {
            "product_id": currentId,
            "is_hidden": true,
            "username": username,
        };

        if (productData["customer_username"] == CUSTOMER_USERNAME || productData["company_id"] == COMPANY_ID)
            payload['sequence_id'] = SEQUENCE_ID_OWNED
        else
            payload['sequence_id'] = SEQUENCE_ID_OTHER

        let company_id = COMPANY_ID;
        if ((username != currentCustomer) && (company_id != companyId)) {
            //case of customer deleting the product added from store or shared by some other customer
            api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
            payload = {
                "product_id": currentId,
                "username": username,
            };
        }

        axios.post(api_end_point, payload)
            .then((response) => {

                message.info('Product Deleted.');
                setDeleteLoader(false);
                setDeleteModal(false);
                window.location.href = params.id;
            })
            .catch((error) => {
                console.log(error);
                message.error('Error in deleting product');
                setDeleteLoader(false);
                setDeleteModal(false);
            });
    };

    return (
        <CustomerMainLayout selected='3' remove_global_padding={true}>
            <CustomerProductContext.Provider
                value={{
                    context_type: 'product',
                    carousel: carousel,
                    favImages: favImages,
                    userAccessLevel: userAccess,
                    productAssetData: productAssetData,
                    setProductAssetData: setProductAssetData,
                    productData: productData,
                    productDataLoader: productDataLoader,
                    heroShot: heroShot ? heroShot : aiHeroShot,
                    allProductImages: allProductImages,
                    setAllProductImages: setAllProductImages,
                    secondaryShots: secondaryShots,
                    setSecondaryShots: setSecondaryShots,
                    productImageCount: productImageCount,
                    setProductImageCount: setProductImageCount,
                    productAssetViewer: productAssetViewer,
                    selectedAsset: selectedAsset,
                    setSelectedAsset: setSelectedAsset,
                    setSelectedProductImage: setSelectedProductImage,
                    selectedProductImage: selectedProductImage,
                    closeProductAssetViewer: closeProductAssetViewer,
                    openProductAssetViewer: openProductAssetViewer,
                    product_id: params.id,
                    open3DModelPreview: open3DModelPreview,
                    statusOf360Spin: statusOf360Spin,
                    statusOfMp4Video: statusOfMp4Video,
                    getDownloadUrl: getDownloadUrl,
                    totalFileSize: totalFileSize,
                    lowPolyModelSize: lowPolyModelSize,
                    lowPolyVertexCount: lowPolyVertexCount,
                    lowPolyTriangleCount: lowPolyTriangleCount,
                    lowPolyTextureSize: lowPolyTextureSize,
                    highPolyVertexCount: highPolyVertexCount,
                    highPolyModelSize: highPolyModelSize,
                    highPolyTextureSize: highPolyTextureSize,
                    highPolyTriangleCount: highPolyTriangleCount,
                    modelWidth: modelWidth,
                    modelDepth: modelDepth,
                    modelHeight: modelHeight,
                    archiveSize: archiveSize,
                    glbSize: glbSize,
                    referenceLinks: referenceLinks,
                    productRefImages: productRefImages,
                    getUSDZUrl: getUSDZUrl,
                    getAndroidUrl: getAndroidUrl,
                    download360Frames: download360Frames,
                    downloadCryptoImagesArchive: downloadCryptoImagesArchive,
                    downloadImagesArchive: downloadImagesArchive,
                    imageFlagModal: imageFlagModal,
                    setImageFlagModal: setImageFlagModal,
                    singleImage: singleImage,
                    setSingleImage: setSingleImage,
                    selectedImageForFlag: selectedImageForFlag,
                    openFlagModal: openFlagModal,
                    sharingModal: sharingModal,
                    setSharingModal: setSharingModal,
                    openShareModal: openShareModal,
                    productEditModal: productEditModal,
                    setProductEditModal: setProductEditModal,
                    openProductEditModal: openProductEditModal,
                    customerGroupIds: customerGroupIds,
                    setCustomerGroupIds: setCustomerGroupIds,
                    categoriesData: categoriesData,
                    setCategoriesData: setCategoriesData,
                    folderParentID: folderParentID,
                    requestStatus: requestStatus,
                    cardStatus: cardStatus,
                    variationModal: variationModal,
                    closeVariationModal: closeVariationModal,
                    openVariationModal: openVariationModal,
                    variationCreated: variationCreated,
                    variationSaved: variationSaved,
                    variationStep: variationStep,
                    variationType: variationType,
                    setVariationStep: setVariationStep,
                    onChangeVariationType: onChangeVariationType,
                    onChangeARVariationType: onChangeARVariationType,
                    savedID: savedID,
                    variationProductDetails: variationProductDetails,
                    requestSubmitted: requestSubmitted,
                    mp4DetailsModal: mp4DetailsModal,
                    setMp4DetailsModal: setMp4DetailsModal,
                    setStatusOfMp4Video: setStatusOfMp4Video,
                    setStatusOf360Spin: setStatusOf360Spin,
                    openVideoModal: openVideoModal,
                    mp4Price: mp4Price,
                    selectedPriceCategory: selectedPriceCategory,
                    setInAppLimitExceededModal: setInAppLimitExceededModal,
                    inAppLimitExceededModal: inAppLimitExceededModal,
                    updatePaymentDetailsOnFailure: updatePaymentDetailsOnFailure,
                    setPaymentFailureMessage: setPaymentFailureMessage,
                    paymentFailureMessage: paymentFailureMessage,
                    setPaymentFailureModal: setPaymentFailureMessage,
                    paymentFailureModal: paymentFailureModal,
                    setCardSaveFlowTriggered: setCardSaveFlowTriggered,
                    cardSaveFlowTriggered: cardSaveFlowTriggered,
                    threesixtyDetailsModal: threesixtyDetailsModal,
                    dimensionalDetailsModal: dimensionalDetailsModal,
                    openThreeSixtyModal: openThreeSixtyModal,
                    openDimensionalModal: openDimensionalModal,
                    dimensionalPrice: dimensionalPrice,
                    customerDefaultDimResolution: customerDefaultDimResolution,
                    customerDefaultDimensionSettings: customerDefaultDimensionSettings,
                    customerDefaultDimDPI: customerDefaultDimDPI,
                    setDimensionalDetailsModal: setDimensionalDetailsModal,
                    threeSixtyPrice: threeSixtyPrice,
                    setThreesixtyDetailsModal: setThreesixtyDetailsModal,
                    setIsTransferModalVisible: setIsTransferModalVisible,
                    isTransferModalVisible: isTransferModalVisible,
                    openTransferModal: openTransferModal,
                    showTransferButton: showTransferButton,
                    setShowTransferButton: setShowTransferButton,
                    setComplaintsView: setComplaintsView,
                    complaints: complaints,
                    companyId: companyId,
                    viewComplaintsListener: viewComplaintsListener,
                    complaintsView: complaintsView,
                    requestSaved: requestSaved,
                    setRequestSaved: setRequestSaved,
                    sellVisible: sellVisible,
                    setSellVisible: setSellVisible,
                    removeFromStore: removeFromStore,
                    setRemoveFromStore: setRemoveFromStore,
                    performStoreAction: performStoreAction,
                    downloadSiloImages: downloadSiloImages,
                    showBottomBar: showBottomBar,
                    variantModels: variantModels,
                    imgAccessedFromCarousel: imgAccessedFromCarousel,
                    setImgAccessedFromCarousel: setImgAccessedFromCarousel,
                    dimensionalImageExists: dimensionalImageExists,
                    downloadReferences: downloadReferences,
                    isStorePage: isStorePage,
                    cognito_username: props.cognito.user.username,
                    restrictedItem: restrictedItem,
                    baseVariantPlatform: baseVariantPlatform,
                    onAddCollectionClick: onAddCollectionClick,
                    setCurrentId: setCurrentId,
                    setCompanyID: setCompanyId,
                    setCurrentCustomer: setCurrentCustomer,
                    setDeleteModal: setDeleteModal,
                    companyName: companyName,
                    prices: prices,
                    statusLoader: false,
                    moreStoreModels: moreStoreModels,
                    variantSelected: variantSelected,
                    setVariantSelected: setVariantSelected,
                    getProductData: getProductData,
                    showProductDetails: showProductDetails,
                    productID: productID,
                    currentId: currentId,
                    variationProductData: variationProductData,
                    setVariationProductData: setVariationProductData,
                    variationAssetData: variationAssetData,
                    setVariationAssetData: setVariationAssetData,
                    setSavedVariationProductDetails: setSavedVariationProductDetails,
                    setStatusLoader: setStatusLoader,
                    statusLoader: statusLoader,
                    companyDomain: companyDomain,
                    companyUsers: companyUsers,
                    publicLinks: publicLinks,
                    selectedImgOriginalFormat: selectedImgOriginalFormat,
                    setSelectedImgOriginalFormat: setSelectedImgOriginalFormat,
                    selectedImgFilename: selectedImgFilename,
                    setSelectedImgFilename: setSelectedImgFilename,
                    allSystemGeneratedProductImages: allSystemGeneratedProductImages,
                    selectedImageResolution:selectedImageResolution,
                    setSelectedImageResolution:setSelectedImageResolution,
                    selectedImageFormat:selectedImageFormat,
                    setSelectedImageFormat:setSelectedImageFormat,
                    selectedImageName:selectedImageName,
                    setSelectedImageName:setSelectedImageName,
                    selectedImageCreationDate:selectedImageCreationDate,
                    setSelectedImageCreationDate:setSelectedImageCreationDate,
                    setSelectedImageType: setSelectedImageType,
                    selectedImageType: selectedImageType,
                    selectedImageCapturedBy: selectedImageCapturedBy,
                    setSelectedImageCapturedBy: setSelectedImageCapturedBy,
                    isAiModel: isAiModel,
                    modelStatus: modelStatus,
                    aiRefineModalOpen: aiRefineModalOpen,
                    setAiRefineModalOpen: setAiRefineModalOpen,
                    aiLifestyleModalOpen: aiLifestyleModalOpen,
                    setAiLifestyleModalOpen: setAiLifestyleModalOpen
                }}>
                <ProductPage />
            </CustomerProductContext.Provider>
            {props.productReducer != null && (productData || variationProductDetails || variationProductData || savedVariationProductDetails) &&
                <OpenVariationModal
                    // productReducer={productAssetData}
                    product_id={variantSelected ? currentId : params.id}
                    folderParentID={folderParentID}
                    productData={variantSelected ? variationProductData : productData}
                    modelDepth={variantSelected ? variationModelDepth : modelDepth}
                    modelWidth={variantSelected ? variationModelWidth : modelWidth}
                    modelHeight={variantSelected ? variationModelHeight : modelHeight}
                    requestStatus={requestStatus}
                    cardStatus={cardStatus}
                    requestSaved={requestSaved}
                    setRequestSaved={setRequestSaved}
                    variationModal={variationModal}
                    closeVariationModal={closeVariationModal}
                    variationStep={variationStep}
                    variationType={variantSelected ? selectedVariationType : variationType}
                    setRequestSubmitted={setRequestSubmitted}
                    setVariationStep={setVariationStep}
                    onChangeVariationType={onChangeVariationType}
                    variationSaved={variationSaved}
                    variationCreated={variationCreated}
                    onChangeARVariationType={onChangeARVariationType}
                    arVariationType={variantSelected ? selectedArVariationType : arVariationType}
                    savedID={variantSelected ? variationSavedID : savedID}
                    redirectPage={redirectPage}
                    variationProductDetails={variantSelected ? savedVariationProductDetails : variationProductDetails}
                    requestSubmitted={requestSubmitted} />}
            <AddCollection
                productId={selectedProductIdForCollection}
                visible={collectionModalVisible}
                addedToLibrary={isStorePage ? false : true}
                setCollectionModalVisible={setCollectionModalVisible}>
            </AddCollection>
            <Modal
                className="modal-share-success"
                width={600}
                centered={true}
                visible={deleteModal}
                closable={false}
                footer={[
                    <span className="justify-in-center">
                        <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => onDelete()}>
                            {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span>Yes, Delete Product </span>}
                        </Button>
                    </span>
                ]}>
                <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2 className="manrope f-16 black-00 w-600">You are about to delete this product</h2>
                    {companyId == COMPANY_ID ?
                        // another user from the company is deleting a product so display specific warning message 
                        <p className="manrope f-14 black-55">This product and its assets will be deleted for all the users in your company. Are you sure you want to proceed?</p>
                        :
                        <p className="manrope f-14 black-55">Deleting this product would delete all assets generated with it. Are you sure you want to delete this product? </p>
                    }
                </div>
            </Modal>
            <AiProductRefineModal open={aiRefineModalOpen} setOpen={setAiRefineModalOpen} platform={productPlatform} productId={params.id} />
            <AiLifestyleRequestModal open={aiLifestyleModalOpen} setOpen={setAiLifestyleModalOpen} productId={params.id} renderRequestCallback = {()=>{fetchAiAssets(params.id, true)}}/>
        </CustomerMainLayout>
    );

}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCustomerPage)
)
