import axios from "axios";
import React, {useEffect, useState, useContext} from "react";
import { CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Popover } from "antd";
import "./index.scss";
import FileUpload from "../UploadAiModel/Upload";
import FileConstants from "../../../FileConstants";
import ENVIRONMENT from "../../../environments";
import AiModelGenerationContext from "../ContextFiles/AiModelGenerationContext";

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const UploadModelImage = () => {
  const [image, setImage] = useState(null);
  const [imageObj, setImageObj] = useState(null);
  const {
    setIsLoading, 
    productId, 
    setStepValue,
    setProductIdValue
  } = useContext(AiModelGenerationContext);

  const fetchInitialModel = (product_id) => {
    axios.post(ENVIRONMENT.AI_MODEL_HELPER, {
      'action': 'get_ai_assets',
      'product_id': product_id,
      'username': CUSTOMER_USERNAME,
    })
    .then((res) => {
      if (res.data) {
        if (parseInt(res.data.model_status) >= 14 ) {
          setStepValue(2);
          setIsLoading(false);
        } else {
          setIsLoading(true);
          setTimeout(() => fetchInitialModel(product_id), 20000);
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    const handleLoading = () => {
      if (productId) {
       fetchInitialModel(productId);
      }
    }

    handleLoading();
  }, [productId])
  
  const createProduct = () => {
    setIsLoading(true);
    axios.post(ENVIRONMENT.CREATE_PRODUCT, {
      "product_name": "AI_MODEL",
      "brand_id": "",
      "category": "Chair",
      "product_model_type": "product",
      "dimensions": "inches",
      "height": 10,
      "width": 10,
      "length": 10,
      "similar_color": "Black",
      "materials": [],
      "product_photos": [],
      "model_status": 1,
      "group_id": "",
      "components": [],
      "user_type": "customer",
      "pantone_color": "",
      "hex_color": "",
      "is_configurable": false,
      "need_to_model": "low_res",
      "modeling_required": true,
      "uploaded_model": false,
      "designed_product": true,
      "licensing_options": {
        "license": "public",
        "public_option": "forCreativeCommon",
        "creative_common": "Public Domain"
      },
      "segmented": false,
      "user_usecase": [
        "low_res"
      ],
      "company_id": COMPANY_ID,
      "username": CUSTOMER_USERNAME,
      "is_ai_model": true,
      'product_photos': [imageObj]
    })
    .then((res) => {
      handleModelGenertate(res.data.product_id)
      setProductIdValue(res.data.product_id);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleModelGenertate = (product_id) => {
    setIsLoading(true);
    axios.post(ENVIRONMENT.AI_MODEL_CREATE, {
      'action': 'trigger_quick_model_job',
      'image_url': image,
      'product_id': product_id
    })
    .catch((err) => {
      console.log(err);
    })
    //fetchInitialModel(product_id);
  }

  return (
    <div className="upload-model-image-container">
      <div className="upload-model-image-content">
        <div className="step-tiltle-container">
            <div className="step-number">1</div> 
            <span className="step-title">Upload an Image</span>
        </div>
        <span className="step-header">
            Upload a 3/4 view of product to create your 3D model.
        </span>
        <div className="flex-between">
            <div className="flex-between">
              <div className={`step-number ${image ? 'step-number-success' : 'step-number-greyed'}`}><CheckOutlined/></div>
              <span className="step-header-text">Add 3/4 view image</span>
            </div>
            <Popover open={true} placement="bottom" content={
              <div className="tips-popover">
                <p>Tips for best results:</p>
                <p className="tips-item"><CheckOutlined className="tips-icon" /> Choose a 3/4 angle product shot</p>
                <p className="tips-item"><CheckOutlined className="tips-icon" /> Use an image with a solid white or gray background</p>
                <p className="tips-item"><CheckOutlined className="tips-icon" /> Use file format jpg, png, or tiff</p>
                <p className="tips-item"><CheckOutlined className="tips-icon" /> Make sure your file is under 5 MB</p>
              </div>}>
              <span className="link-text">See tips for best results</span>
            </Popover>
        </div>
        <FileUpload className='h-300' setImage={setImage} image={image} setImageObj = {setImageObj} />
      </div>
      <div className="upload-model-image-footer">
        <Button type="primary" onClick={createProduct} className="footer-button ml-auto">See Your 3D Model 
          <ArrowRightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default UploadModelImage;
