export const ENV = 'production';

var API_BASE_URL = 'https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/'

const BASE_URLS = {
    '34': 'https://all3d.s3.amazonaws.com/'
}

const COMPANY_ID = localStorage.getItem('company_id');

export const getBaseURL = () => {
    if (COMPANY_ID != undefined && BASE_URLS[COMPANY_ID] != undefined) {
        return BASE_URLS[COMPANY_ID]
    } else {
        return 'https://d48f7equ64qjl.cloudfront.net/'
    }
}

export const DEFAULT_BASE_URL = 'https://d48f7equ64qjl.cloudfront.net/'

export const BUCKET_NAME = (ENV == 'staging' || ENV == 'local') ? 'all3d-dev' : 'all3d';
//Cloudfront distribution id
export const CLOUDFRONT_ID = (ENV == 'staging' || ENV == 'local') ? 'EWXIJSKOEGJHL':'E1PCYBAKSUG4Z2';

export const PORTAL_LINK = (ENV == 'staging' || ENV == 'local') ? 'http://localhost:3000/' : 'https://app.all3d.ai/' ;
//urls that are different in staging and production
export const BASE_URL = (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/test_files/' : (getBaseURL() + 'test_files/');
export const ARTIST_TEST_BASE_URL = (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/artist_tests/' : (getBaseURL() + 'artist_tests/');
export const SCENE_BASE_URL =  (ENV == 'staging' || ENV == 'local') ? 'https://d3ckjemso196la.cloudfront.net/scene_renders/' : (getBaseURL() + 'scene_renders/');
export const TEMPLATE_BASE_URL =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/template_renders/' : (getBaseURL() + 'template_renders/');
export const COMPLAINT_URL =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/complaint_renders/' : (getBaseURL() + 'complaint_renders/');
export const THUMBNAIL_URL =  (ENV == 'staging' || ENV == 'local') ? "https://all3d-dev.s3.amazonaws.com/product_assets/thumbnail/" : (getBaseURL() + "product_assets/thumbnail/");
export const ASSET_GLB_URL =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/glb/' : (getBaseURL() + 'product_assets/glb/');
export const ROOM_ASSET_GLB =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/room_assets/glb/' : (getBaseURL() + 'room_assets/glb/');
export const QUICK_RENDERS_URLS =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/quick_renders/' : (getBaseURL() + 'product_assets/quick_renders/');
export const LOW_POLY_MAX =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/low_poly_max/' : (getBaseURL() + 'product_assets/low_poly_max/');
export const DEBUG_RENDERS =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/debug_renders/' : (getBaseURL() + 'product_assets/debug_renders/');
export const QA_IMG_URL =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/perspective_renders/' : (getBaseURL() + 'product_assets/perspective_renders/');
export const QA_IMG_URI = "product_assets/perspective_renders/";
export const LOW_RES_QA_IMG_URI = "product_assets/low_res_perspective_renders/";
export const IMAGE_DIMENSIONAL_URL =  (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/dimensional_renders/' : (getBaseURL() + 'product_assets/dimensional_renders/');
export const ROOM_PANORAMA_ASSET =  (ENV == 'staging' || ENV == 'local') ? "https://all3d-dev.s3.amazonaws.com/room_assets/panorama/" : (getBaseURL() + "room_assets/panorama/");
export const FLOOR_PLAN =  (ENV == 'staging' || ENV == 'local') ?  'https://all3d-dev.s3.amazonaws.com/room_assets/floorplan/' : (getBaseURL() + 'room_assets/floorplan/');
export const PREPROCESSING_URL = (ENV == 'staging' || ENV == 'local') ?  'https://all3d-dev.s3.amazonaws.com/product_assets/preprocessing_color_palletes/' : (getBaseURL() + 'product_assets/preprocessing_color_palletes/');
export const SPACE_LOW_POLY = (ENV == 'staging' || ENV == 'local') ?  'https://all3d-dev.s3.amazonaws.com/room_assets/low_poly_max/' : (getBaseURL() + 'room_assets/low_poly_max/');
export const USDZ_BASE_URL = (ENV == 'staging' || ENV == 'local') ?  'https://all3d-dev.s3.amazonaws.com/product_assets/usdz/' : (getBaseURL() + 'product_assets/usdz/');
export const MATERIAL_FILE_URL = (ENV == 'staging' || ENV == 'local') ?  'https://all3d-dev.s3.amazonaws.com/materials/' : (getBaseURL() + 'materials/');
export const VARIATION_RENDERS = (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/variations_data/' : (getBaseURL() + 'product_assets/variations_data/');
export const VIDEO_MP4 = (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/video/' : (getBaseURL() + 'product_assets/video/');
export const ANNOTATED_PRODUCT_IMAGE = (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/product_assets/annotated_product/' : (getBaseURL() + 'product_assets/annotated_product/');
export const COMPANY_LOGO = (ENV == 'staging' || ENV == 'local') ? 'https://all3d-dev.s3.amazonaws.com/static_files/company/' : (getBaseURL() + 'static_files/company/');
export const DEFAULT_LIGHTING_RIG =  (getBaseURL() + 'test_files/rc-upload-1622635901196-2/IBLShadowless.zip');
export const RIG_BASE_URL = (ENV == 'staging' || ENV == 'local') ? 'https://d3ckjemso196la.cloudfront.net/test_files/' : (getBaseURL() + 'test_files/')
export const SPACE_MATERIAL_FILE_URL = (ENV == 'staging' || ENV == 'local') ?  'https://d3ckjemso196la.cloudfront.net/space_materials/' : (getBaseURL() + 'space_materials/');
export const PRODUCT_CONFIG_RENDERS_URL = (ENV == 'staging' || ENV == 'local') ?  'https://d3ckjemso196la.cloudfront.net/product_assets/configurable_product_renders/' : (getBaseURL() + 'product_assets/configurable_product_renders/');

//urls for artist guidelinesß
export const SPACE_GUIDELINES = 'https://docs.google.com/document/d/1vu7GpeyR6vq1m83ym5Q0EotVFTZ3K9T0gd5vfeLkMSo';
export const PRODUCT_GUIDELINES = 'https://docs.google.com/document/d/172FTkwKl0g9yh2Bq7BoWHmCKGj6NGJiwdSqYkDrp52c';
export const FLAG_IF_UNHAPPY_GUIDELINES = 'https://docs.google.com/document/d/1wmpAhrJAqZQiUlBoTbCLswsz6gfy84g4ZGnDjpGzFK4/edit?usp=sharing'

//urls for material and configurable model info
export const TILEABLE_MATERIAL = 'https://docs.google.com/document/d/1CcUuL3i_VnQRi5KuL9OpoPlzesm5Y1I-x9APD18yiAc/';
export const CONFIGURABLE_MODEL = 'https://docs.google.com/presentation/u/5/d/1PT9z5zB0YYHPmgZ9AZRdY994d3L-HxGAgTkZt2-YM78/';

//common urls in staging and production
export const SCANS_BASE_URL = "https://all3d-reconstruction.s3.amazonaws.com/temp_results/";
export const WHITE_BG_DROPSHADOW_ON = (getBaseURL() + 'test_files/rc-upload-1634729140201-3/IBLContactShadow.zip');
export const WHITE_BG_SHADOW_OFF = (getBaseURL() + 'test_files/rc-upload-1622635901196-2/IBLShadowless.zip');
export const WHITE_BG_SHADOW_ON = (getBaseURL() + 'test_files/rc-upload-1613751650523-2/IBL.zip');
export const GREY_BG_SHADOW_OFF = (getBaseURL() + 'test_files/rc-upload-1613089887000-2/W_100_GreyBG.zip');
export const GREY_BG_SHADOW_ON = (getBaseURL() + 'test_files/rc-upload-1612361661672-3/W_100_GreyBG_Shadows.zip');
export const BLIND_MOUNT_RIG = (getBaseURL() + 'test_files/rc-upload-1630350772298-2/BlindsMountRig.zip');
export const SILO_CONFIG_JSON = (getBaseURL() + 'static_files/SiloConfig.json');
export const COUNTRY_SIDE_URL = (getBaseURL() + 'room_assets/low_poly_max/267.zip');
export const GARDEN_URL = (getBaseURL() + "room_assets/low_poly_max/264.zip");
export const STATIC_FILES_BASE_URL = (getBaseURL() + 'static_files/');
export const MATERIAL_EXPORT_AND_RENDER_PLUGIN = (getBaseURL() + 'test_files/material-export-plugin/MaterialProcessPlugin.zip')
export const SCENE_CREATOR_QA_CHECKLIST= (getBaseURL() + 'test_files/tutorials/TestSceneCreator.mp4')
export const PBR_MATERIAL_RENDERS_TOOL = 'https://d1gmu4ezl274zg.cloudfront.net/VisualTool/'
export const MATERIAL_GUIDELINES = 'https://docs.google.com/document/d/1DSutBS3jlE68PbT3KfR7N2u26Yz1bd_DtHl5BYvCCVQ/edit?usp=sharing'


export const FETCH_USERNAME = API_BASE_URL + 'get-username-from-email';

//artist upload flow APIs
export const GET_ARTIST_PROFILE = API_BASE_URL + 'artist-profile-get';
export const UPDATE_ARTIST_PROFILE = API_BASE_URL + 'artist-profile-update';

//scene APIs
export const GET_SCENE_DETAIL = API_BASE_URL + 'scene-get';
export const GET_SCENE_BATCH = API_BASE_URL + 'scene-get-batch';
export const UPDATE_SCENE = API_BASE_URL + 'scene-update';
export const AUTO_SAVE_SCENE = API_BASE_URL + 'auto-save-scene';
export const UPDATE_SCENE_METADATA = API_BASE_URL + 'update-scene-renders-metadata'

export const USER_GROUP = API_BASE_URL + 'user-group';

//room APIs
export const CREATE_ROOM = API_BASE_URL + 'room-create';

export const UPDATE_ROOM = API_BASE_URL + 'room-update';
export const FETCH_ROOM = API_BASE_URL + 'room-get';
export const RENDER_ROOM = API_BASE_URL + 'room-get-assets';
export const LIST_ROOMS = API_BASE_URL + 'room-get-batch';
export const GET_SCANNED_ROOMS = 'https://5ismf2vzf3.execute-api.us-west-2.amazonaws.com/staging/all3d-scanned-model-get-batch';
export const STORE_LIST = API_BASE_URL + 'get-store-items';

export const ROOM_MODEL_CREATE = API_BASE_URL + 'room-model-create';
export const MODEL_CREATE = API_BASE_URL + 'product-model-create';
export const SHARE_SCENE = API_BASE_URL + 'share-scene';
export const DASHBOARD_ANALYTICS = API_BASE_URL + 'dashboard-analytics';
export const REJECT_ROOM = API_BASE_URL + 'room-model-reject';
export const GET_ROOM_TEST_PRODUCTS = API_BASE_URL + 'space-test-products-get';
export const ROOM_VARIATION_REQUEST_CREATE = API_BASE_URL + "room-variation-request-create";

// product APIs
export const FETCH_PRODUCT = API_BASE_URL + 'product-get';
export const RENDER_PRODUCT = API_BASE_URL + 'product-get-assets';
export const LIST_PRODUCT = API_BASE_URL + 'product-get-batch';
export const CREATE_PRODUCT = API_BASE_URL + 'product-create';
export const UPDATE_PRODUCT = API_BASE_URL + 'product-update';
export const MSP_UPDATE_PRODUCT = API_BASE_URL + 'msp-product-update';
export const MSP_UPDATE_SPACE = API_BASE_URL + 'msp-room-update';
export const REJECT_PRODUCT = API_BASE_URL + 'model-reject';
export const GET_PRODUCT_REJECTIONS = API_BASE_URL + 'product-get-rejections';
export const GET_ROOM_REJECTIONS = API_BASE_URL + 'room-get-rejections';
export const GET_ROOM_FIXES_LIST = API_BASE_URL + 'room-get-fix-iteration';
export const GET_PRODUCT_FIXES_LIST = API_BASE_URL + 'product-get-fix-iteration';
export const UPDATE_PRODUCT_USER_ASSET = API_BASE_URL + 'product-asset-update';
export const ARTIST_JOB_PICK = API_BASE_URL + 'artist-job-pick';
export const ASSIGN_PRODUCT_MODEL = API_BASE_URL + 'assign-product-models';
export const ARTIST_JOB_DESK = API_BASE_URL + 'artist-job-desk';
export const PRODUCT_VARIATION_REQUEST_GET = API_BASE_URL + 'product-variation-request-get';
export const PRODUCT_VARIATION_REQUEST_UPDATE = API_BASE_URL + 'product-variation-request-update';
export const UPDATE_PRODUCT_METADATA = API_BASE_URL + 'update-product-metadata';

//collection APIS
export const COLLECTION_GET_ROOMS = API_BASE_URL + 'collection-get-rooms';
export const LIST_COLLECTION = API_BASE_URL + 'collection-get-batch';
export const CREATE_COLLECTION = 'https://lckqe08fwg.execute-api.us-west-2.amazonaws.com/staging/all3d-collection-create';
export const UPDATE_COLLECTION = API_BASE_URL + 'collection-update';
export const FETCH_COLLECTION_PRODUCTS = API_BASE_URL + 'collection-get-products';
export const COLLECTION_ADD_ROOM = API_BASE_URL + 'collection-add-room';
export const DELETE_COLLECTION_PRODUCT = API_BASE_URL + 'collection-delete-product';
export const GET_COLLECTION_NAMES = API_BASE_URL + 'get-collection-names';
export const GET_SCENE_NAMES = API_BASE_URL + 'get-scene-names';
export const GET_COLLECTION_DATA = API_BASE_URL + 'collection-get';
export const ADMIN_CATEGORY_BATCH = 'https://xnhluveva9.execute-api.us-west-2.amazonaws.com/staging/all3d-category-get-batch'
export const ADMIN_MATERIAL_BATCH = 'https://cvs5huxxi0.execute-api.us-west-2.amazonaws.com/staging/all3d-material-get-batch'
export const ADMIN_CATEGORY_GET_BATCH = API_BASE_URL + 'admin-category-get-batch';
export const DOWNLOAD_COLLECTION_USER_ASSETS = API_BASE_URL + 'init-collection-assets-archive-creation';
export const ADD_PRODUCTS_BATCH_TO_COLLECTION = API_BASE_URL + 'add-products-batch-to-collection';

//artist hours APIs
export const ARTIST_HOURS_BATCH = API_BASE_URL + 'artist-hours-get-batch';
export const ARTIST_HOURS_GET = API_BASE_URL + 'artist-hours-get';
export const ARTIST_PROFILE_LIST = API_BASE_URL + 'artist-profile-get-batch';
export const ARTIST_HOURS = API_BASE_URL + 'artist-hours';
export const ARTIST_PRODUCT_FIX = API_BASE_URL + 'artist-product-fixes';
export const ARTIST_ROOM_FIX = API_BASE_URL + 'artist-room-fixes';

export const ADD_TO_LIBRARY = API_BASE_URL + 'add-to-library';

export const HOME_SCREEN_DATA = API_BASE_URL + 'home-screen-get';
export const GET_SCANS_DATA = 'https://5ismf2vzf3.execute-api.us-west-2.amazonaws.com/staging/all3d-scanned-model-get-batch';
export const UPDATE_SCAN_DATE = 'https://6x5mtbqrv4.execute-api.us-west-2.amazonaws.com/staging/all3d-scanned-model-update';
export const UPDATE_COLLECTION_SPACE = API_BASE_URL + 'collection-space-update';
export const FETCH_COLLECTION_SPACE_ALLOCATION = API_BASE_URL + 'collection-get-space-allocation';

// export const CREATE_ITEM_RECORD = "https://wo6xc3c5rf.execute-api.us-west-2.amazonaws.com/development/createItemRecord"
// export const GET_ROOM_RECORDS = 'https://m98k1tmssj.execute-api.us-west-2.amazonaws.com/development/getRoomRecords'

//Batch share APIs
export const SHARE_PRODUCT_BATCH = API_BASE_URL + 'share-product-batch';
export const SHARE_ROOM_BATCH = API_BASE_URL + 'share-room-batch';
export const SHARE_SCENE_BATCH = API_BASE_URL + 'share-scene-batch';

//replica scene APIs
export const SCENE_REPLICA = API_BASE_URL + 'share-scene-replica';
export const SCENE_REPLICA_BATCH = API_BASE_URL + 'share-scene-replica-batch';

//replicate space APIs
export const SPACE_REPLICA = API_BASE_URL + "duplicate-room-wrapper";

//share APIs for product and space
export const SHARE_PRODUCT = API_BASE_URL + 'share-product';
export const SHARE_ROOM = API_BASE_URL + 'share-room';
export const SHARE_MATERIAL = API_BASE_URL + 'share-material-entity';

//reject material, room and product API
export const ENTITY_MODEL_REJECT = API_BASE_URL + 'entity-model-reject';

//invoice APIs
export const INVOICE_GET = API_BASE_URL + 'invoice-get';
export const INVOICE_PAY = API_BASE_URL + 'payment-backend';
export const INVOICE_CREATE = API_BASE_URL + 'invoice-create';
export const INVOICE_UPDATE = API_BASE_URL + 'invoice-update';
export const INVOICE_GET_BATCH = API_BASE_URL + 'invoice-get-batch';
export const STRIPE_PUBLISH_KEY = (ENV == 'staging' || ENV == 'local') ? 'pk_test_8A05nAOZJJjrjiuidhgKT42J00lp2p0qKQ' : 'pk_live_fz5N7NdWcUxHcaCrt2TcikOp00awSqsR5J';

// product files APIS
export const FETCH_PRODUCT_FILES = API_BASE_URL + 'get-products-detail';

//Customer Management APIs
export const CUSTOMER_GET_BATCH = API_BASE_URL + 'customer-get-batch';


//flag if unhappy APIs
export const FLAG_IF_UNHAPPY = API_BASE_URL + 'complaint-create';
export const GET_COMPLAINT_BATCH = API_BASE_URL + 'complaint-get-batch';
export const GET_COMPLAINT = API_BASE_URL + 'complaint-get';
export const UPDATE_FLAG_STATUS = API_BASE_URL + 'complaint-update';

//flag if unhappy override
export const REPLACE_LIFESTYLE_IMAGE = API_BASE_URL + 'flag-scene-render-replacement';
export const REPLACE_SILO_IMAGE = API_BASE_URL + 'flag-silo-replacement';
export const DOWNLOAD_COMPLETE_BUNDLE = API_BASE_URL + 'init-zip-creation';
export const ADD_TO_COMPLAINT_RENDERS = API_BASE_URL + 'complaint-renders-update';

// project APIs
export const GET_PROJECT_BATCH = API_BASE_URL + "project-get-batch";
export const PROJECT_THUMBNAIL_URI = "projects/thumbnail/";
export const GET_PROJECTS_ENTITY_BATCH = API_BASE_URL + "entity-get-batch";
export const UPDATE_PROJECT = API_BASE_URL + "project-update";
export const CREATE_PROJECT = API_BASE_URL + "project-create";
export const ADD_ENTITY_BATCH_TO_PROJECT =
  API_BASE_URL + "add-entity-batch-to-project";
export const DELETE_ENTITY_BATCH_TO_PROJECT =
  API_BASE_URL + "delete-entity-batch-from-project";
export const PROJECT_ACCESS_CONTROLLER =
  API_BASE_URL + "project-access-controller";
export const GET_PROJECTS_SILOS = API_BASE_URL + "get-project-silos";
export const GET_PROJECTS_LIFESTYLES = API_BASE_URL + "get-project-lifestyles";


//admin override APIs
export const PRODUCT_RENDERS_OVERRIDE = API_BASE_URL + 'product-renders-override';
export const ADMIN_MODEL_OVERRIDE = API_BASE_URL + 'admin-model-override';
export const LIGHTING_RIG_CREATE = API_BASE_URL + 'lighting-rig-create';
export const LIGHTING_RIG_GET_BATCH = API_BASE_URL + 'lighting-rig-get-batch';
export const SET_PRODUCT_CUSTOM_RIG = API_BASE_URL + 'product-set-custom-rig';
export const ROOM_RENNDERS_OVERRIDE = API_BASE_URL + 'room-renders-override';
export const CREATE_COMPLAINT_RENDER = API_BASE_URL + 'complaint-renders-create';
export const GET_COMPLAINT_RENDER = API_BASE_URL + 'complaint-renders-get';
export const ADD_COMPLAINT_RENDER = API_BASE_URL + 'flag-complaint-render-replacement';
export const TRIGGER_COMPLAINT_UPDATE = API_BASE_URL + 'trigger-complaint-resolver';

//render service apis
export const GET_BATCH_TEST_RENDERS = API_BASE_URL + 'direct-render-request-get-batch';
export const CREATE_RENDERS = API_BASE_URL + 'direct-render-request-create';
//automation request APIs
export const AUTOMATION_REQUEST_GET_BATCH = API_BASE_URL + 'automation-request-get-batch';
export const AUTOMATION_REQUEST_REGEN = API_BASE_URL + 'automation-request-regenerate';
export const AUTOMATION_REQUEST_UPDATE = API_BASE_URL + 'automation-request-update';

//APIs to manage scene configurations
export const SCENE_CONFIG_CREATE = API_BASE_URL + 'scene-configuration-create';
export const SCENE_CONFIG_UPDATE = API_BASE_URL + 'scene-configuration-update';
export const SCENE_CONFIG_GET = API_BASE_URL + 'scene-configuration-get';
export const SCENE_CONFIG_GET_BATCH = API_BASE_URL + 'scene-configuration-get-batch';
export const SCENE_CONFIG_UPDATE_BATCH = API_BASE_URL + 'scene-configuration-update-batch';
export const SCENE_CONFIG_GET_BY_CAMERA = API_BASE_URL + 'scene-configuration-get-by-camera';
export const SCENE_CONFIG_DELETE_BY_CAMERA = API_BASE_URL + 'scene-configuration-delete-by-camera';

// API to get action history for an item
export const GET_BATCH_ITEM_HISTORY = API_BASE_URL + 'item-history-get-batch';

//material and component APIs
export const CREATE_BATCH_MATERIAL = API_BASE_URL + 'material-entity-create-batch';
export const UPDATE_MATERIAL = API_BASE_URL + 'material-entity-update';
export const MATERIAL_GET_BATCH = API_BASE_URL + 'material-entity-get-batch';
export const MATERIAL_GET = API_BASE_URL + 'material-entity-get';
export const MATERIAL_REJECTIONS_GET_BATCH = API_BASE_URL + 'material-entity-rejection-get';
export const MATERIAL_REJECT_CREATE = API_BASE_URL + 'material-entity-rejection-create';
export const MATERIAL_ARTIST_REJECT = API_BASE_URL + 'material-entity-rejection-update';
export const MATERIAL_ASSET_UPDATE = API_BASE_URL + 'material-entity-asset-update';
export const REJECT_MATERIALS_BATCH = API_BASE_URL + 'material-entity-rejection-create-batch';
export const CREATE_MATERIAL = API_BASE_URL + 'material-entity-create';
export const UPDATE_MATERIALS_BATCH = API_BASE_URL + 'material-entity-batch-update';
export const PRODUCT_MATERIAL_CREATE = API_BASE_URL + 'product-material-create';
export const PRODUCT_MATERIAL_GET_BATCH = API_BASE_URL + 'product-material-get-batch';
export const PRODUCT_MATERIAL_DELETE_BATCH = API_BASE_URL + 'product-material-delete-batch';

export const PRODUCT_COMPONENT_CREATE = API_BASE_URL + 'product-component-create';
export const PRODUCT_COMPONENT_GET_BATCH = API_BASE_URL + 'product-component-get-batch';
export const PRODUCT_COMPONENT_MATERIAL_CREATE = API_BASE_URL + 'product-component-material-create';
export const PRODUCT_COMPONENT_GET = API_BASE_URL + 'product-component-material-get-batch';
export const CREATE_REQUEST_ID = API_BASE_URL + 'configurable-product-request-create';
export const ADD_MATERIALS_IN_REQUEST = API_BASE_URL + 'configurable-request-material-create-batch';
export const GET_CONFIGURATION_DATA = API_BASE_URL + 'configurable-materials-product-get-batch';
export const ADD_MATERIALS_IN_COMPONENT = API_BASE_URL + 'product-component-material-create-batch';
export const GET_VARIATIONS = API_BASE_URL + 'product-variations-get';
export const MATERIAL_ARTIST_HOUR_GET = API_BASE_URL + 'material-entity-artist-hours-get';
export const SPACE_MATERIAL_GET = API_BASE_URL + "space-material-entity-get"
export const SPACE_MATERIAL_GET_BATCH = API_BASE_URL + "space-material-entity-get-batch"
export const SPACE_MATERIAL_CREATE = API_BASE_URL + "space-material-entity-create"

//Material export info APIs
export const PRODUCT_MATERIAL_EXPORT_INFO_GET_BATCH = API_BASE_URL + 'product-material-export-info-get-batch';
export const PRODUCT_MATERIAL_EXPORT_INFO_UPDATE_BATCH = API_BASE_URL + 'product-material-export-info-update-batch';

//API to get customer default rig
export const GET_CUSTOMER_DEFAULT_SETTINGS = API_BASE_URL + 'customer-settings-get';

//API to hide any entity from library if it's shared by someone or added from store
export const DELETE_SHARED_ENTITY = API_BASE_URL + 'hide-shared-entity';

//invite_account
export const INVITE_USER = API_BASE_URL + 'user-send-invite';

//deactivate account
export const DEACTIVATE_USER = API_BASE_URL + 'deactivate-account';

//remove lighting renders for the product
export const REMOVE_PRODUCT_RENDERS = API_BASE_URL + 'product-renders-delete';
// API for CUSTOMER_SPECIFIC_SILO_SETTINGS_GET
export const CUSTOMER_SILO_CONFIG = API_BASE_URL + 'customer-silo-settings-get';

//delete incomplete request
export const DELETE_INCOMPLETE_REQUEST = API_BASE_URL + 'delete-incomplete-entity';

//Company APIs
export const COMPANY_GET_BATCH = API_BASE_URL + 'company-get-batch';
export const COMPANY_UPDATE = API_BASE_URL + 'company-update';
export const COMPANY_GET = API_BASE_URL + 'company-get';
export const COMPANY_ENTITY_GET_BATCH = API_BASE_URL + 'company-entity-get-batch';

//get product variants
export const GET_PRODUCT_VARIANTS = API_BASE_URL + 'get-product-variants';

//get room variants
export const GET_ROOM_VARIANTS = API_BASE_URL + 'get-room-variants';
export const GET_PRODUCT_LIGHTING_RIG = API_BASE_URL + 'product-lighting-rig-get';

export const CONFIG_FILE_KEY = 'static_files/config_product_test.json';


//usage_info
export const GET_INVOICES_DATA = API_BASE_URL + 'get-invoice-data';
export const GET_CUSTOMER_AND_COMPANY = API_BASE_URL + 'get-customer-and-company';
export const GET_ACTIVE_COMPANIES = API_BASE_URL + 'get-active-companies';
export const GET_SYSTEM_USAGE = API_BASE_URL + 'get-system-usage';
export const GET_PRODUCT_MANAGED_LIFESTYLES = API_BASE_URL + 'get-product-managed-lifestyle-reports';

//managed_service relation
export const GET_MANAGED_SERVICE_RELATION = API_BASE_URL + 'get-managed-customers';
export const GET_DESIGNER_DATA = API_BASE_URL + 'get-designer-data';
export const ASSIGN_CUSTOMERS = API_BASE_URL + 'assign-customer-to-msprovider';
export const REMOVE_ASSIGNED_CUSTOMERS = API_BASE_URL + 'remove-assigned-customers';

// Artist Invoivce
export const GET_ARTIST_INVOICE = API_BASE_URL + 'artist-invoice-get';

//Cost Get Automation Request
export const GET_COST_AUTOMATION_REQUEST = API_BASE_URL + 'cost-get';

export const CONFIG_PRODUCT_FILE_KEY = 'static_files/config_product.json';
export const CONFIG_SPACE_FILE_KEY = 'static_files/config_space_test.json';

// User Profile
export const USER_PROFILE_UPDATE = API_BASE_URL + 'user-profile-update';

//payment authorization gateway
export const PAYMENT_GATEWAY = API_BASE_URL + 'request-payment-authorization';
export const SAVE_CARD_FLOW = API_BASE_URL + 'payment-backend-save-card';

export const SAVE_CARD_CALLBACK = API_BASE_URL + 'payment-backend-save-card-callback';
export const SAVE_REQUEST_INTERMEDIATE = API_BASE_URL + 'save-intermediate-request';
export const GET_INTERMEDIATE_SAVED_REQUEST = API_BASE_URL + 'get-intermediate-saved-request';
export const UPDATE_CUSTOMER_DATA = API_BASE_URL + 'update-customer-data';

export const GET_CATEGORY_AND_PRICE = API_BASE_URL + 'get-category-and-price';
export const CHECK_SHARED_ENTITY_IN_LIBRARY = API_BASE_URL + 'check-entity-in-library';
export const CUSTOMER_USAGE_QUOTA = API_BASE_URL + 'get-subscription-usage';
export const UPGRADE_SUBSCRIPTION = API_BASE_URL + 'process-payment-and-activate-subscription';
export const GET_USER_PAYMENT_INFORMATION = API_BASE_URL + 'customer-stripe-payment-information';

export const GET_UPDATE_SUBSCRIPTION_DATA = API_BASE_URL + 'get-subscription-data';

export const GET_BILLING_DATA = API_BASE_URL + 'get-billing-history';
export const ADD_FREE_ITEMS_TO_LIBRARY = API_BASE_URL + 'add-free-items-to-library';
export const GET_PRICE_REQUESTS = API_BASE_URL + 'get-price-requests';
export const SET_STORE_PRICE = API_BASE_URL + 'store-action-controller';
export const UPDATE_CUSTOMERS_SETTINGS = API_BASE_URL + 'customer-settings-update';
export const CUSTOMER_INVOICE_RULES = "https://docs.google.com/document/d/1dXj9RPiq6NXxWO_lZfxF1EW4uewBk0gmTrjcJchuT1U/";

// Artist Review APIs
export const ARTIST_REVIEW_CREATE = API_BASE_URL + 'artist-review-create'
export const ARTIST_REVIEW_GET = API_BASE_URL + 'artist-review-get'
export const ARTIST_REVIEW_UPDATE = API_BASE_URL + 'artist-review-update'

// Group IDs API
export const GROUP_IDS_GET_BATCH = API_BASE_URL + 'group-id-get-batch';

//Template Actions API
export const TEMPLATE_ACTIONS = API_BASE_URL + 'template-action-controller';

// S3 API
export const GET_S3_FILE = API_BASE_URL + 'get-s3-file';

//Data Arrangement API
export const ENTITY_ORGANIZER = API_BASE_URL + "entity-organizer";
export const ENTITY_ORGANIZER_LEGACY = API_BASE_URL + 'entity-organizer-legacy';

export const ADMIN_STORE_HELPER = API_BASE_URL + 'admin-store-helper';

// Admin Analytics API
export const GET_SYSTEM_ANALYTICS = API_BASE_URL + 'get-system-analytics';
//Skill Matrix APIs
export const SKILL_GET_BATCH = API_BASE_URL + "skill-get-batch"


// New Artist APIs
export const TEST_ARTIST_CONTROLLER = API_BASE_URL + 'test-artist-controller';
export const GET_TEST_ARTIST_PRODUCT_DETAILS = API_BASE_URL + 'get-test-artist-product-details';
export const GET_ARTIST_TEST_BATCH = API_BASE_URL + 'get-artist-test-batch';
export const GET_ARTIST_TEST = API_BASE_URL + 'get-artist-test';
export const GET_TEST_ARTIST_JOB_DESK = API_BASE_URL + 'get-test-artist-job-desk';

// Artist invite APIs
export const ARTIST_INVITE_GET = API_BASE_URL + 'artist-invite-get'

// Agency APIs
export const AGENCY_GET_BATCH = API_BASE_URL + 'agency-get-batch'
export const AGENCY_ARTIST_GET_BATCH = API_BASE_URL + 'agency-artist-get-batch'

//Instance Health Status API
export const INSTANCE_HEALTH_STATUS_GET = API_BASE_URL + 'instance-health-get'

//Post collection update API
export const POST_COLLECTION_UPDATE = API_BASE_URL + 'post-collection-update'
// Skyline bulk renders APIs
export const SKYLINE_CUSTOMERS_LIST = API_BASE_URL + 'skyline-customers-get-batch'
export const SKYLINE_CSV_VALIDATOR = API_BASE_URL + 'skyline-csv-validator'
export const PRODUCT_CONFIG_RENDERS_LIST = API_BASE_URL + 'product-config-renders-get-batch'
export const CREATE_AUTOMATION_JOBS_FROM_CSV = API_BASE_URL + 'create-automation-jobs-from-csv'
export const BULK_REQUEST_LIST = API_BASE_URL + 'bulk-request-get-batch'
export const INIT_BULK_RENDERS_ARCHIVE = API_BASE_URL + 'init-bulk-renders-archive-creation'
export const CUSTOMER_PRESET_GET_BATCH = API_BASE_URL + 'customer-preset-get-batch'
export const LIST_CAMERA_PRESETS = API_BASE_URL + 'camera-preset-get-batch'
export const CUSTOMER_PRESET_UPDATE = API_BASE_URL + 'customer-configuration-preset-update'

// Product Presets APIs
export const PRODUCT_PRESET_CONTROLLER = API_BASE_URL + 'product-preset-controller'

export const PRODUCT_BULK_PRESET_CONTROLLER = API_BASE_URL + 'product-preset-bulk-controller'

// Generate Print Ready Images API
export const GENERATE_PRINT_READY_IMAGES = API_BASE_URL + 'generate-print-ready-images'

//collaboration tool APIs
export const CREATE_COLLABORATE_ENTITY = API_BASE_URL + 'collaboration-entity-create';
export const GET_COLLABORATE_ENTITY = API_BASE_URL + 'collaboration-entity-get';
export const GET_BATCH_COLLABORATE_ENTITY = API_BASE_URL + 'collaboration-entity-get-batch';
export const COLLABORATION_ENTITY_UPDATE = API_BASE_URL + 'collaboration-entity-update';
export const ANNOTATION_CREATE = API_BASE_URL + 'annotation-create';
export const ANNOTATION_GET_BATCH = API_BASE_URL + 'annotation-get-batch';
export const ANNOTATION_UPDATE = API_BASE_URL + 'annotation-update';
export const COMMENT_CREATE = API_BASE_URL + 'comment-create';
export const COMMENT_GET_BATCH = API_BASE_URL + 'comment-get-batch';
export const COLLABORATE_ACCESS_CONTROLLER = API_BASE_URL + 'collaboration-access-controller';
export const CREATE_COLLABORATE_DESIGN = API_BASE_URL + 'collaboration-design-create';
export const UPDATE_COLLABORATE_DESIGN = API_BASE_URL + 'collaboration-design-update';
export const GET_COLLABORATION_RENDERS_HISTORY = API_BASE_URL + 'collaboration-renders-history-get';
export const ACTIVE_COLLABORATORS = API_BASE_URL + 'active-collaborator-list-controller';
export const COLLABORATION_DESIGN_PROPAGATE = API_BASE_URL + 'collaboration-design-propagate';
export const COLLABORATION_RENDER_OVERRIDE = API_BASE_URL + 'collaboration-render-override';
export const SEND_COLLABORATION_NOTIFICATION = API_BASE_URL + 'send-collaboration-notification';

//collaborate-qa
export const COLLABORATE_QA_IMAGERY = API_BASE_URL + 'product-qa-imagery-get';

//Google Upload Controller
export const GOOGLE_UPLOAD_CONTROLLER = API_BASE_URL + 'google-upload-controller'
export const CLOUD_FILE_COPY = API_BASE_URL + 'cloud-file-copier'


//ownership transfer API
export const TRANSFER_OWNERSHIP = API_BASE_URL + 'transfer-model-ownership';

export const VERIFY_ADMIN_AND_GET_USER_DETAILS = API_BASE_URL + 'verify-admin-and-get-user-details'

// Custom group APIs
export const CUSTOM_GROUP_CONTROLLER = API_BASE_URL + 'custom-group-controller';
export const ENTITY_SHARE_LIST = API_BASE_URL + 'entity-share-list-get'
export const ENTITY_ACCESS_UPDATE = API_BASE_URL + 'entity-access-update'
export const VARIANT_DATA_MIGRATOR = API_BASE_URL + 'variant-data-migrator';

export const POST_PRODUCT_CREATION_HELPER = API_BASE_URL + 'product-post-creation-helper';

export const USER_PREFERENCE_CONTROLLER = API_BASE_URL + 'user-preference-controller';


//get customer 2d images
export const GET_IMAGES = API_BASE_URL + "get-all-images";
// Sales Funnel APIs
export const CUSTOMER_INVITED = API_BASE_URL + 'customer-invites-get';
export const ACCOUNT_MANAGER_LIST = "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/account-managers-controller";
export const FUNNEL_TABLES_DATA = "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/customer-get-batch";
export const COMPANY_LIST = "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/company-get-batch";
export const ACCOUNT_MANAGER_CONTROLLER = "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/account-managers-controller";
// OAUTH TOKEN APIs
export const AWS_AUTH_LAMBDA_HELPER = API_BASE_URL + 'aws-auth-lambda-helper';

export const TERMS_AGREEMENT_CONTROLLER = API_BASE_URL + 'terms-agreement-controller';

// AI Model Create APIs
export const AI_MODEL_CREATE = API_BASE_URL + 'ai-asset-generator-helper';
export const AI_MODEL_HELPER = API_BASE_URL + 'ai-asset-generator-helper';
export const AI_MODEL_CREATE_UPDATE = API_BASE_URL + 'ai-model-create-update';
export const AI_MODEL_CREATE_UPDATE_BATCH = API_BASE_URL + 'ai-model-create-update-batch';

export const FAVOURITES_CONTROLLER = API_BASE_URL + "favorites-controller";

export const DELETE_LIFESTYLE = API_BASE_URL + "delete-lifestyle-render";
export const LIFESTYLE_DESIGN_STATE_CONTROLLER = API_BASE_URL + "lifestyles-design-state-controller"
export const GENERATE_WATERMARKED_IMAGE = API_BASE_URL + "generate-watermarked-image"