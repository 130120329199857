import { UploadOutlined } from "@ant-design/icons";
import { message, Upload, Button } from "antd";
import * as Constants from "../CustomerComponents/Constants";
import ENVIRONMENT from "../../../environments"
import React from "react";
import "./index.scss";

const { Dragger } = Upload;

const FileUpload = ({ className, setImage, image, setImageData, setImageObj }) => {

  const handleButtonClick = (e) => {
    setImage(null);
  }

  const validateFile = (file) => {
    const isAllowedFormat = ["image/png", "image/jpeg", "image/webp", "image/heic"].includes(file.type);
    const isBelow5MB = file.size / 1024 / 1024 < 5;

    if (!isAllowedFormat) {
      message.error("Only .png, .jpg, .webp, and .heic formats are allowed.");
      return false;
    }

    if (!isBelow5MB) {
      message.error("File size must be below 5MB.");
      return false;
    }

    return true;
  };

  const props = {
    ...Constants.upload_props,
    multiple: false,
    beforeUpload: (file) => {
      return validateFile(file);
    },
    onChange(info) {
      const { status } = info.file;
      console.log(info)
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setImage(ENVIRONMENT.getBaseURL() + "test_files" + "/" + info.file.uid + "/" + info.file.name);
        if (setImageData) {
          setImageData(info.file);
        }
        if (setImageObj) {
          setImageObj(info.file);
        }
      } else if (status === "error") {
        setImage("none");
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className={`upload-container ${className}`}>
      { !image && <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined className="upload-icon" />
        </p>
        <p className="ant-upload-text">Upload Image</p>
        <p className="ant-upload-hint">Chose a file or drag & drop it here</p>
        <Button
          className="browse-files-button"
          type="primary"
          ghost
        >
          Browse Files
        </Button>
      </Dragger>}
      { image && <>
        <img className="upload-model-image" src={image} />
        <Button onClick={handleButtonClick} type="primary" ghost className="replace-image-button">Replace Image</Button>
        </>
        }
    </div>
  );
};

export default FileUpload;
