import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Button, Row, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './CustomerMainLayout.scss';
import FileConstants from '../../../../FileConstants';
import homeBlack from './blackHome.svg';
import homeRed from './redHome.svg';
import redbar from './redbar.svg'

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ selected, history, location }) => {
  const [selectedKey, setSelectedKey] = useState(selected);
  const [hoverKey, setHoverKey] = useState(null);

  const handleMouseEnter = key => {
    setHoverKey(key);
  };

  const handleMouseLeave = () => {
    setHoverKey(null);
  };


  useEffect(() => {
    setSelectedKey(selected);
  }, [selected]);

  const handleMenuItemChange = (e) => {
    const routes = {
      1: '/add_product',
      2: '/create_room',
      3: '/list-products',
      5: '/rooms',
      6: '/my_scenes',
      7: '/tags?selected=7',
      8: '/modeling?selected=8',
      9: '/home',
      11: '/store?selected=11',
      12: '/about?selected=12',
      13: '/list-collections',
      14: '/library-projects?tab=approved',
      15: '/help',
      16: '/material-library',
      17: '/account-usage',
      18: '/customer_model_report',
      19: '/bulk-configurations',
      20: '/collaborations',
      21: '/projects',
      25: '/images'
    };
    if (routes[e.key]) {
      // history.push(routes[e.key]);
      // setSelectedKey(e.key);
      window.location.href = routes[e.key];
    }
  };

  const handleCreateNewFormChange = (e) => {
    const current_page = location.pathname;
    const routes = {
      1: '/upload-product-model?origin=',
      2: '/add-new-room?origin=',
      3: '/create-empty-scene?origin=',
      4: '/create-scene-from-template?origin=',
      5: '/create-new-material?origin=',
      6: '/upload-scanned-material?origin=',
      7: '/bulk-product-config-create?origin=',
      8: '/create-existing-physical-product?origin=',
      9: '/add-new-product?origin=',
      10: '/new_project?origin=',
      11: '/ai-model-generation?origin='
    };

    if (e.key === '11') {
      localStorage.removeItem("ai_product_id");
      localStorage.removeItem("ai_generation_step");
    }

    if (routes[e.key]) {
      window.location.href = routes[e.key] + current_page;
    }
  };

  const menu = (
    <Menu triggerSubMenuAction="hover">
      <SubMenu title={<span>Product</span>} popupOffset={[0, 0]}>
        <SubMenu title={<span>Create New Model</span>} popupOffset={[0, 0]}>
          <Menu.Item key="8" onClick={handleCreateNewFormChange}>
            <span>Existing Physical Product</span>
          </Menu.Item>
          <Menu.Item key="9" onClick={handleCreateNewFormChange}>
            <span>Design A New Product</span>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="1" onClick={handleCreateNewFormChange}>
          <span>Upload Existing Model</span>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="2" onClick={handleCreateNewFormChange}>
        <span>Space</span>
      </Menu.Item>
      <SubMenu title={<span>Scene</span>} popupOffset={[0, 0]}>
        <Menu.Item key="3" onClick={handleCreateNewFormChange}>
          <span>From Empty Space</span>
        </Menu.Item>
        <Menu.Item key="4" onClick={handleCreateNewFormChange}>
          <span>From Template</span>
        </Menu.Item>
      </SubMenu>
      <SubMenu title={<span>Material</span>} popupOffset={[0, 0]}>
        <Menu.Item key="5" onClick={handleCreateNewFormChange}>
          <span>Create New Material</span>
        </Menu.Item>
        <Menu.Item key="6" onClick={handleCreateNewFormChange}>
          <span>Upload Scanned Material</span>
        </Menu.Item>
      </SubMenu>
      {FileConstants.BULK_REQUEST_ELIGIBLE_COMPANIES.includes(localStorage.getItem('company_id')) && (
        <Menu.Item key="7" onClick={handleCreateNewFormChange}>
          <span>Bulk Request</span>
        </Menu.Item>
      )}
      <Menu.Item key="10" onClick={handleCreateNewFormChange}>
        <span>Project</span>
      </Menu.Item>
      <Menu.Item key="11" onClick={handleCreateNewFormChange}>
        <span>Use AI Creator</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Sider className='antSider'id='uniqueSidebar'>
      <Row className="create-new-button-container">
        <Dropdown overlay={menu} trigger={['hover']}>
          <Button
            className="create-new-button justify-in-center modal-okay-cblack square font-14"
            shape="circle"
            size="default"
          >
            <PlusOutlined />
            Create New
          </Button>
        </Dropdown>
      </Row>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedKey]}
        style={{ marginBottom: '5%' }}
      >
      <Menu.Item
        key="9"
        className='home-menu-item'
        onClick={handleMenuItemChange}
        onMouseEnter={() => handleMouseEnter('9')}
        onMouseLeave={handleMouseLeave}
        style={{ marginTop: '24px', marginBottom: '24px', padding: 0 }} // Consistent padding
      >
        {selectedKey === '9' && (
          <img
            src={redbar}
            alt="Red Bar"
            style={{
              position: 'absolute',
              left: 6,
              top: 0,
              bottom: 0,
              width: '5px',
              height: '90%',
              objectFit: 'contain',
            }}
          />
        )}
          <img
          src={selectedKey === '9' || hoverKey === '9' ? homeRed : homeBlack}
          alt="Home Icon"
          style={{ 
            marginRight: 2, 
            marginLeft:-5,
            width: '14px',   
            height: '14px',  
            objectFit: 'contain',
          }}
        />

        <span
          className="manrope f-14 w-600"
          style={{
            marginLeft: '2px', lineHeight: "10px"
          }}
        >
          Home
        </span>
      </Menu.Item>

        <div style={{ marginBottom: '0px', paddingLeft: 20, marginTop: '20px' }}>
          <span style={{ color: 'grey', justifyContent: 'center', alignItems: 'center' }} className="manrope f-12 w-400">
            Workflow
          </span>
        </div>
        <Menu.Item key="21" onClick={handleMenuItemChange} style={{ marginBottom: '0px',marginTop:10 }}>
        {selectedKey === '21' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain',
          }}

            />
          )}
          <span className="manrope f-14 w-600" style={{marginLeft:'-5px'}}>Active Projects</span>
        </Menu.Item>
        <Menu.Item key="6" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
        {selectedKey === '6' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain',
          }}

            />
          )}
          <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Scenes</span>
        </Menu.Item>
        <Menu.Item key="25" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
        {selectedKey === '25' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%',
            objectFit: 'contain',
          }}
            />
          )}
          <span  style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span className="manrope f-14 w-600" style={{marginLeft:'-5px'}}>Images</span>
            <span className="manrope f-14 w-600 new-tag">New!</span>
          </span>
        </Menu.Item>
        <Menu.Item key="20" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
        {selectedKey === '20' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain',
          }}

            />
          )}
          <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Collaborations</span>
        </Menu.Item>
        {FileConstants.BULK_REQUEST_ELIGIBLE_COMPANIES.includes(localStorage.getItem('company_id')) &&
          <Menu.Item key="19" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
            {selectedKey === '19' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain',
          }}

            />
          )}
            <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Bulk Requests</span>
          </Menu.Item>
        }
        <div style={{ marginBottom: '0px', paddingLeft: 20, marginTop: '32px' }}>
          <span style={{ color: 'grey', justifyContent: 'center', alignItems: 'center' }} className="manrope f-12 w-400">
            Libraries
          </span>
        </div>
        <Menu.Item key="3" onClick={handleMenuItemChange} style={{ marginBottom: '0px',marginTop:'10px' }}>
        {selectedKey === '3' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '3px',
            height: '90%', 
            objectFit: 'contain',
          }}
            />
          )}
          <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Products</span>
        </Menu.Item>
        <Menu.Item key="5" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
        {selectedKey === '5' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%',
            objectFit: 'contain',
          }}
            />
          )}
          <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Spaces</span>
        </Menu.Item>
        <Menu.Item key="16" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
        {selectedKey === '16' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain',
          }}
            />
          )}
          <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Materials</span>
        </Menu.Item>
        <Menu.Item key="14" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
        {selectedKey === '14' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%',
            objectFit: 'contain',
          }}
            />
          )}<span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Projects</span>
        </Menu.Item>
        {localStorage.getItem("is_msprovider") === 'true' && 
          <Menu.Item key="13" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
           {selectedKey === '13' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain', 
          }}
            />
          )}
            <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Collections</span>
          </Menu.Item>
        }
        <div style={{ marginBottom: '0px', paddingLeft: 20, marginTop: '32px' }}>
          <span style={{ color: 'grey', justifyContent: 'center', alignItems: 'center' }} className="manrope f-12 w-400">
            Account
          </span>
        </div>
        <Menu.Item key="17" onClick={handleMenuItemChange} style={{ marginBottom: '0px',marginTop:10 }}>
        {selectedKey === '17' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain',
          }}
            />
          )}
          <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Usage History</span>
        </Menu.Item>
        {localStorage.getItem("is_msprovider") === 'true' && 
          <Menu.Item key="18" onClick={handleMenuItemChange} style={{ marginBottom: '0px', marginTop: '5px' }}>
           {selectedKey === '18' && (
        <img
          src={redbar}
          alt="Red Bar"
          style={{
            position: 'absolute',
            left: 6,
            top: 0,
            bottom: 0,
            width: '5px',
            height: '90%', 
            objectFit: 'contain', 
          }}
            />
          )}
            <span className="manrope f-14 w-600"style={{marginLeft:'-5px'}}>Customer Reports</span>
          </Menu.Item>
        }
      </Menu>
      
    </Sider>
  );
};

export default withRouter(Sidebar);
