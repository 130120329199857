import React from 'react';

const ProductPageConstants = new Object();

ProductPageConstants.PRODUCT_ACTIONS = [
  {
    "icon": "/img/3d.jpg",
    "tooltip": "3D Model",
    "type": "3d_preview",
  },
  {
    "icon": "/img/360-tn.jpg",
    "tooltip": "360 Spin",
    "type": "360_view",
  },
  {
    "icon": "/img/mp4.svg",
    "tooltip": "MP4 Video",
    "type": "mp4_video",
  },
]


ProductPageConstants.IMAGE_TYPES = {
  'low_res_preview': 'Preview',
  'silo': 'Silo',
  'swatch': 'Swatch',
  'blind': 'Blind',
  'dim': 'DIM',
  'ai_lifestyle': 'Lifestyle'
}

ProductPageConstants.MODEL_VIEWER_KEY = 'JKQ76HB9AB4HLNH8FK4'



ProductPageConstants.PRODUCT_VIEWER_TABS = [
  {
    "name": "Images",
    "type": "images",
  },
  {
    "name": "3D Preview",
    "type": "3d_preview",
  },
  {
    "name": "360 Spin",
    "type": "360_view",
  },
  {
    "name": "MP4 Video",
    "type": "mp4_video",
  },
]


ProductPageConstants.variant_grid_break_points = {
  screen_6: {
    breakpoint: { max: 4000, min: 1920 },
    items: 6
  },
  screen_7: {
    breakpoint: { max: 1920, min: 1700 },
    items: 6
  },
  screen_8: {
    breakpoint: { max: 1700, min: 1525 },
    items: 5
  },
  screen_9: {
    breakpoint: { max: 1475, min: 1360 },
    items: 4
  },
  screen_1: {
    breakpoint: { max: 1525, min: 1475 },
    items: 5
  },
  screen_5: {
    breakpoint: { max: 1360, min: 1280 },
    items: 4
  },
  screen_2: {
    breakpoint: { max: 1280, min: 1024 },
    items: 4
  },
  screen_3: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  screen_4: {
    breakpoint: { max: 464, min: 0 },
    items: 4
  }
};

export default ProductPageConstants;