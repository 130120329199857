import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Col } from 'antd';
import AssetButtons from './AssetButtons.jsx';

const ActionButtons = () => {
    const { productAssetData, statusOf360Spin, statusOfMp4Video, userAccessLevel,isAiModel, modelStatus } = useContext(CustomerProductContext);
    const productDescritionMessage = modelStatus == 16 || true ? 
        'Our AI model is still learning! Let us refine your model details to make it more accurate.' 
        : 'Generate images and 3D assets of this product';

    return (
        <>
        {userAccessLevel === 'edit' ? 
        <Col className="product-action-button-container">
            <p className="manrope f-16 black-33 mb-0">{productDescritionMessage}</p>
            <div id="product-button-container">
                <AssetButtons />
            </div>
            {(productAssetData['silo_data'] && productAssetData['silo_data'].filter(img => img.image_status == "pending").length > 0
                || statusOf360Spin == "pending" || statusOfMp4Video == "pending")
                && <p className="manrope f-12 grey-8c mb-0">Requested assets will be available on this page within 24 hours.</p>}
        </Col> : ''}
        </>
    );
};

export default ActionButtons;